import React from "react";
import { Box, Typography, Radio, Divider, Dialog } from "@material-ui/core";
import clsx from "clsx";
import {
  SEAT_SELECTION_TITLE,
  SEAT_SELECTION_SUBTITLE,
  SELECT_SEATS_CTA_TEXT,
  SKIP_SEAT_SELECTION_SUBTITLE,
  UNAVAIL_SEAT_SELECTION_WARNING,
  GORDIAN_SEAT_MAP_OPTIONS,
  HOPPER_SEAT_MAP_OPTIONS,
  getTotalSeatPricingString,
  getCheapestSeatString,
  SEAT_MAP_MODAL_TITLE,
  HOPPER_SEAT_MAP_STYLE,
} from "./textConstants";
import {
  ActionButton,
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  CloseButtonIcon,
  SelectedSeatsConfirmation,
  ActionLink,
} from "halifax";
import "./styles.scss";
import { SeatSelectionConnectorProps } from "./container";
import {
  SeatInfo,
  SeatMapAvailable,
  SeatMapResponseEnum,
  SeatSegment,
  TripSegment,
  TripSlice,
  IPerson,
  GordianSeat,
  SelectedSeatsSegment,
  SKIPPED_SEAT_SELECTION,
  REQUESTED_SEATMAP,
  VIEWED_SEATMAP,
  CHOSE_FROM_SEATMAP,
  SeatSlice,
  HopperSeat,
} from "redmond";
import {
  getSeatNotSelected,
  getSeatSelectionNotAvail,
} from "../../reducer/selectors/textConstants";
import { convertUsdToAllRewards } from "../../../../api/v0/rewards/convertUsdToAllRewards";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { SeatSelectionErrorModal } from "../SeatSelectionErrorModal";
import { HopperSeats } from "@capone/checkout";

export interface ISeatSelectionProps extends SeatSelectionConnectorProps {
  className?: string;
  isMobile?: boolean;
  title: string;
  disabled?: boolean;
  mobileHeaderElement?: JSX.Element;
  fullScreenWithBanner?: boolean;
  hasFreeSeats?: boolean;
}

export const getSelectedSeatsSegments = (
  selectedSeatsInfo: SeatSegment[],
  slice: TripSlice,
  passengers: IPerson[],
  outgoingSegmentCount: number,
  sliceInfo: SeatSlice
) => {
  const sliceSelectedSeats: SelectedSeatsSegment[] = [];
  // create seat info map - origin-destination as key -> segment as value
  const seatInfoMap: { [key: string]: SeatSegment } = {};
  // create seat passenger map - origin-destination-passId as key -> seat as value
  const seatPassengerMap: { [key: string]: SeatInfo } = {};
  selectedSeatsInfo.forEach((seat: SeatSegment) => {
    seatInfoMap[`${seat.originCode}-${seat.destinationCode}`] = seat;
    seat.seats.forEach((seatInfo: SeatInfo) => {
      seatPassengerMap[
        `${seat.originCode}-${seat.destinationCode}-${seatInfo.person_id}`
      ] = seatInfo;
    });
  });

  let seats: SeatSegment;
  slice.segmentDetails.forEach((segment: TripSegment, idx: number) => {
    const selectedSeatsSegment =
      seatInfoMap[`${segment.originCode}-${segment.destinationCode}`];
    if (selectedSeatsSegment) {
      if (selectedSeatsSegment.seats.length !== passengers.length) {
        // determine missing passengers - see if the seatPassengerMap has a key that matches org/dest/id
        passengers.forEach((passenger: IPerson) => {
          const seatInfo: SeatInfo =
            seatPassengerMap[
              `${segment.originCode}-${segment.destinationCode}-${passenger.id}`
            ];
          if (!seatInfo) {
            selectedSeatsSegment.seats.push(getSeatNotSelected(passenger));
          }
        });
      }
      seats = selectedSeatsSegment;
    } else if (
      sliceInfo.segments.find(
        (seg) =>
          seg.origin === segment.originCode &&
          seg.destination === segment.destinationCode &&
          !seg.seatsAvailable
      )
    ) {
      seats = {
        departureDate: segment.departureTime,
        destinationCode: segment.destinationCode,
        originCode: segment.originCode,
        seats: passengers.map((p: IPerson) => getSeatSelectionNotAvail(p)),
      };
    } else {
      seats = {
        departureDate: segment.departureTime,
        destinationCode: segment.destinationCode,
        originCode: segment.originCode,
        seats: passengers.map((p: IPerson) => getSeatNotSelected(p)),
      };
    }
    sliceSelectedSeats.push({
      originCode: segment.originCode,
      flightNumber: segment.flightNumber,
      seats,
      isOutgoing: slice.outgoing,
      idx: slice.outgoing ? idx : outgoingSegmentCount + idx,
    });
  });
  return sliceSelectedSeats;
};

export const SeatSelection = ({
  title,
  className,
  disabled,
  seatMapAvailability,
  fetchSeatMap,
  priceQuote,
  seatSelectAvailability,
  seatMapHtml,
  seatMapLoading,
  setSelectedSeats,
  selectedSeats,
  tripDetails,
  passengers,
  airports,
  cheapestSeat,
  selectedRewardsAccountId,
  skipSeatSelection,
  setSkipSeatSelection,
  totalSeatRewards,
  totalSeatPricing,
  setSeatMap,
  selectedPaymentMethodId,
  selectedRewardsPaymentAccountId,
  setSelectedRewardsPaymentTotal,
  resetPaymentCardSelectedAccounts,
}: ISeatSelectionProps) => {
  const [convertingRewards, setConvertingRewards] =
    React.useState<boolean>(false);
  const [openSeatMapModal, setOpenSeatMapModal] =
    React.useState<boolean>(false);
  const [selectedSeatsInfo, setSelectedSeatsInfo] = React.useState<
    SeatSegment[]
  >([]);
  const [outboundSegmentsSeats, setOutboundSegmentsSeats] = React.useState<
    SelectedSeatsSegment[]
  >([]);
  const [returnSegmentsSeats, setReturnSegmentsSeats] = React.useState<
    SelectedSeatsSegment[]
  >([]);
  const [segmentIndex, setSegmentIndex] = React.useState<number>(0);
  const [hasSeatError, setHasSeatError] = React.useState<boolean>(false);
  const [onEditMode, setOnEditMode] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!priceQuote) {
      setSelectedSeats([]);
      setSeatMap(null);
    }
  }, [priceQuote]);

  React.useEffect(() => {
    if (seatSelectAvailability === SeatMapResponseEnum.SeatMapAvailable) {
      fetchSeatMap((priceQuote?.seatMap as SeatMapAvailable).callback);
    }
  }, [seatSelectAvailability]);

  React.useEffect(() => {
    if (!seatMapAvailability) {
      setSkipSeatSelection(true);
    }
  }, [seatMapAvailability]);

  React.useEffect(() => {
    if (selectedSeats.length === 0) {
      setSelectedSeatsInfo([]);
    }
    if (selectedPaymentMethodId || selectedRewardsPaymentAccountId) {
      resetPaymentCardSelectedAccounts();
      setSelectedRewardsPaymentTotal("", null, null);
    }
  }, [selectedSeats]);

  React.useEffect(() => {
    if (selectedSeatsInfo.length > 0) {
      const outboundSegmentSelectedSeats = getSelectedSeatsSegments(
        selectedSeatsInfo,
        tripDetails.slices[0],
        passengers,
        tripDetails.slices[0].segmentDetails.length,
        (priceQuote?.seatMap as SeatMapAvailable).seatsInfo.slices[0]
      );
      setOutboundSegmentsSeats(outboundSegmentSelectedSeats);
      if (tripDetails.slices.length > 1) {
        const returnSegmentSelectedSeats = getSelectedSeatsSegments(
          selectedSeatsInfo,
          tripDetails.slices[1],
          passengers,
          tripDetails.slices[0].segmentDetails.length,
          (priceQuote?.seatMap as SeatMapAvailable).seatsInfo.slices[1]
        );
        setReturnSegmentsSeats(returnSegmentSelectedSeats);
      }
    }
  }, [selectedSeatsInfo]);

  React.useEffect(() => {
    if (skipSeatSelection) {
      setSelectedSeats([]);
      setSelectedSeatsInfo([]);
    }
  }, [skipSeatSelection]);

  React.useEffect(() => {
    // removes medallia from the fixed bottom that was covering pricing
    if (document && document.getElementById("nebula_div_btn")) {
      if (openSeatMapModal) {
        document!.getElementById("nebula_div_btn")!.style.display = "none";
      } else {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    }
  }, [openSeatMapModal]);

  const onFrameLoad = (_: any) => {
    const iframe = document.getElementById(
      "iframe-id"
    ) as HTMLIFrameElement as any;

    iframe.contentWindow.HopperSeats != null
      ? initHopperSeats(iframe)
      : initGordianSeats(iframe);
  };

  const initGordianSeats = (iframe: any) => {
    trackEvent({
      eventName: VIEWED_SEATMAP,
      properties: {},
    });
    let styles = `@font-face { font-family: "Optimist"; font-style: normal; font-weight: normal; src: url(${window.location.origin}/email-assets/fonts/Optimist_Rg.ttf) format("truetype");} .gordian-modal {height: 100vh;}`;
    var styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    iframe.contentWindow.document.head.appendChild(styleSheet);
    iframe.contentDocument.body.children[0].style.height = "100vh";
    const addPaxIdAndName = iframe.contentWindow.addPaxIdAndName;
    const getAppDisplayInfo = iframe.contentWindow.getAppDisplayInfo;
    const seatsSelected = iframe.contentWindow.hopper_seats.seatsSelected;
    iframe.contentWindow.gordianResponse = (products: any) => {
      const productsWithPaxId = addPaxIdAndName(products);
      const appDisplayInfo = getAppDisplayInfo(products);
      seatsSelected(productsWithPaxId, appDisplayInfo);
      if (productsWithPaxId.length > 0) {
        trackEvent({
          eventName: CHOSE_FROM_SEATMAP,
          properties: {},
        });
      }
      try {
        setConvertingRewards(true);
        const prods: Promise<GordianSeat>[] = productsWithPaxId.map(
          async (seat: Omit<GordianSeat, "rewards">) => {
            const rewards = await convertUsdToAllRewards({
              amount:
                seat.price_object.usd_total /
                10 ** seat.price_object.decimal_places,
            });
            return { ...seat, rewards };
          }
        );
        setOpenSeatMapModal(false);
        Promise.all(prods).then((seats) => {
          if (
            seats.every(
              (seat) => seat.rewards && Object.keys(seat.rewards).length > 0
            )
          ) {
            setConvertingRewards(false);
            setSelectedSeats(seats);
            setSelectedSeatsInfo(appDisplayInfo.segments);
          } else {
            setOpenSeatMapModal(false);
            setConvertingRewards(false);
            setHasSeatError(true);
          }
        });
      } catch {
        setOpenSeatMapModal(false);
        setConvertingRewards(false);
        setSelectedSeats([]);
        setSelectedSeatsInfo([]);
      }
    };
    const passengers = iframe.contentWindow.PASSENGERS;
    const container = document.getElementById("seatmap");
    const options = GORDIAN_SEAT_MAP_OPTIONS;
    iframe.contentWindow.GordianDisplay.showSeatMap({
      ...options,
      passengers,
      container,
      segmentIndex,
      editMode: onEditMode,
    });
  };

  const initHopperSeats = (iframe: any) => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = HOPPER_SEAT_MAP_STYLE;
    iframe.contentWindow.document.head.appendChild(styleSheet);
    iframe.contentDocument.body.children[0].style.height = "100vh";

    iframe.contentWindow.hopperResponse = (
      products: any[],
      appDisplayInfo: any
    ) => {
      if (products.length > 0) {
        trackEvent({
          eventName: CHOSE_FROM_SEATMAP,
          properties: {},
        });
      }
      setConvertingRewards(true);

      const prods: Promise<HopperSeat>[] = products.map(
        async (seat: Omit<HopperSeat, "rewards">) => {
          const rewards = await convertUsdToAllRewards({
            amount:
              seat.price_object.amountUsd /
              10 ** seat.price_object.decimalPlaces,
          });
          return { ...seat, rewards };
        }
      );
      setOpenSeatMapModal(false);
      Promise.all(prods).then((seats) => {
        if (
          seats.every(
            (seat) => seat.rewards && Object.keys(seat.rewards).length > 0
          )
        ) {
          setConvertingRewards(false);
          setSelectedSeats(
            seats.map((seat) => ({
              ...seat,
              price_object: {
                markup_amount: 0,
                currency: seat.price_object.currency,
                total: seat.price_object.amount,
                usd_base_price: seat.price_object.amountUsd,
                base_price: seat.price_object.amount,
                usd_markup_amount: 0,
                usd_total: seat.price_object.amountUsd,
                decimal_places: seat.price_object.decimalPlaces,
              },
            }))
          );
          setSelectedSeatsInfo(appDisplayInfo.segments);
        } else {
          setOpenSeatMapModal(false);
          setConvertingRewards(false);
          setHasSeatError(true);
        }
      });
    };
    (iframe.contentWindow.HopperSeats as HopperSeats)
      .showSeatMap({
        segmentIndex,
        ...HOPPER_SEAT_MAP_OPTIONS,
      })
      .then(() => {
        trackEvent({
          eventName: VIEWED_SEATMAP,
          properties: {},
        });
      })
      .catch((e) => console.error(e));
  };

  const onEditClick = (idx: number) => {
    setSegmentIndex(idx);
    setOpenSeatMapModal(true);
    setOnEditMode(true);
  };

  const onTryAgainClick = () => {
    setHasSeatError(false);
  };

  const onSeatErrorSkipClick = () => {
    setHasSeatError(false);
    setSkipSeatSelection(true);
    setSelectedSeats([]);
  };

  const renderDesktopSeatSelection = () => {
    const renderContent = () => {
      return (
        <>
          {openSeatMapModal && seatMapHtml && (
            <Dialog
              open={openSeatMapModal}
              onClose={() => setOpenSeatMapModal(false)}
              className={clsx("seat-map-wrapper")}
              /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
              TransitionProps={{ role: "none" } as never}
              PaperProps={{
                /* eslint-disable */
                // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
                tabIndex: 0,
                /* eslint-enable */
              }}
              role="dialog"
              aria-labelledby="choose-seats-modal"
            >
              <Box className="header-content">
                <Typography
                  variant="h6"
                  className="header-text"
                  id="choose-seats-modal"
                  tabIndex={-1}
                >
                  {SEAT_MAP_MODAL_TITLE}
                </Typography>

                <ActionLink
                  className="seat-selection-map-close-button"
                  content={<CloseButtonIcon />}
                  label="Close"
                  onClick={() => setOpenSeatMapModal(false)}
                />
              </Box>
              <Box className="iframe-wrapper">
                <iframe
                  id="iframe-id"
                  marginHeight={0}
                  marginWidth={0}
                  frameBorder={0}
                  title="seat map popup"
                  srcDoc={seatMapHtml}
                  onLoad={(obj) => onFrameLoad(obj)}
                ></iframe>
              </Box>
            </Dialog>
          )}
          <Box className="seat-selection-card">
            {!disabled &&
              cheapestSeat &&
              selectedSeatsInfo.length === 0 &&
              (selectedRewardsAccountId ? (
                <Typography
                  className="cheapest-seat-string"
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: getCheapestSeatString(
                      cheapestSeat,
                      selectedRewardsAccountId
                    ),
                  }}
                />
              ) : (
                <Typography
                  className="cheapest-seat-string"
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: getCheapestSeatString(cheapestSeat),
                  }}
                />
              ))}
          </Box>
          {selectedSeatsInfo.length > 0 && (
            <>
              <Divider className="seat-selection-divider" />
              {totalSeatRewards ? (
                <Typography
                  className="total-pricing-string"
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: getTotalSeatPricingString(
                      passengers,
                      totalSeatPricing,
                      totalSeatRewards
                    ),
                  }}
                />
              ) : (
                <Typography
                  className="total-pricing-string"
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: getTotalSeatPricingString(
                      passengers,
                      totalSeatPricing
                    ),
                  }}
                />
              )}
              <SelectedSeatsConfirmation
                outboundSeatSegments={outboundSegmentsSeats}
                returnSeatSegments={returnSegmentsSeats}
                onEditClick={onEditClick}
                outboundOriginCode={tripDetails.slices[0].originCode}
                returnOriginCode={
                  tripDetails.slices.length > 1
                    ? tripDetails.slices[1].originCode
                    : undefined
                }
                airports={airports}
              />
            </>
          )}

          {!seatMapAvailability && !disabled && (
            <NotificationBanner
              className="unavail-seat-selection-banner"
              label={UNAVAIL_SEAT_SELECTION_WARNING}
              severity={BannerSeverity.WARNING}
              icon={
                <Icon className="banner-icon" name={IconName.WarningAlert} />
              }
            />
          )}
        </>
      );
    };
    return (
      <Box
        className={clsx("seat-selection-container", className, {
          disabled: disabled || seatMapLoading || convertingRewards,
        })}
      >
        <Box className="seat-selection-description">
          <Typography className="step-title" variant="h2">
            {title}
          </Typography>
          <Typography variant="body2" className="seat-selection-subtitle">
            {SEAT_SELECTION_SUBTITLE}
          </Typography>
        </Box>

        <Box
          className={clsx("seat-selection-wrapper", {
            "seats-selected": selectedSeatsInfo.length > 0,
          })}
        >
          <Box
            className={clsx("seat-selection-title-and-pricing-section", {
              "full-width-with-padding": !seatMapAvailability,
              "full-width": selectedSeatsInfo.length > 0,
            })}
          >
            <Typography variant="body1" className="seat-selection-title">
              {SEAT_SELECTION_TITLE}
            </Typography>
            {renderContent()}
          </Box>
          {/* TODO: hide button when seats are selected */}
          {selectedSeatsInfo.length === 0 && seatMapAvailability && (
            <ActionButton
              defaultStyle={"h4r-secondary"}
              onClick={() => {
                trackEvent({
                  eventName: REQUESTED_SEATMAP,
                  properties: {},
                });
                setSkipSeatSelection(false);
                setOpenSeatMapModal(true);
              }}
              message={SELECT_SEATS_CTA_TEXT}
              disabled={disabled}
            />
          )}
        </Box>

        <Box
          className={clsx("skip-seat-selection-radio-container", {
            selected:
              skipSeatSelection || !seatMapAvailability || seatMapLoading,
            "seats-selected": selectedSeats.length > 0,
          })}
          aria-labelledby="skip-seat-selection"
        >
          <Radio
            name="skip-seat-selection"
            checked={
              skipSeatSelection || !seatMapAvailability || seatMapLoading
            }
            disabled={disabled}
            onChange={() => {
              trackEvent({
                eventName: SKIPPED_SEAT_SELECTION,
                properties: {},
              });
              setSkipSeatSelection(!skipSeatSelection);
            }}
          />
          <Box
            className="skip-seat-selection-radio-text"
            id="skip-seat-selection"
          >
            <Typography variant="body1" className="skip-seat-selection-title">
              {SKIP_SEAT_SELECTION_SUBTITLE}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="seat-selection-workflow-root">
      {renderDesktopSeatSelection()}
      <SeatSelectionErrorModal
        hasError={hasSeatError}
        onTryAgainClick={onTryAgainClick}
        onSeatErrorSkipClick={onSeatErrorSkipClick}
      />
    </Box>
  );
};
