import React from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  MobilePopoverCard,
  ActionLink,
  ActionButton,
  CloseButtonIcon,
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
} from "halifax";
import clsx from "clsx";
import { PriceFreezeDurationSelection } from "../index";
import * as constants from "./constants";
import { MobilePriceFreezeDurationModalConnectorProps } from "./container";
import "./styles.scss";
import { PriceFreezeDurationSelectionMethod, convertSecondsToDays } from "../../../utils";
import { TripCategory } from "redmond";
import { PRICE_FREEZE_VOID_WINDOW_CONTROL } from "../../../../../context/experiments";
import dayjs from "dayjs";

export interface IMobilePriceFreezeDurationModalProps
  extends MobilePriceFreezeDurationModalConnectorProps,
    RouteComponentProps {
  onContinue?: () => void;
  priceFreezeDurationModalOpen: boolean;
  setPriceFreezeDurationModalOpen: (
    priceFreezeDurationModalOpen: boolean
  ) => void;
  handleGoBack?: () => void;
}

export const MobilePriceFreezeDurationModal = (
  props: IMobilePriceFreezeDurationModalProps
) => {
  const {
    history,
    onContinue,
    tripCategory,
    priceFreezeDurationModalOpen,
    selectedPriceFreezeOfferCap,
    selectedPriceFreezeOfferData,
    priceFreezeMaxDuration,
    setPriceFreezeDurationModalOpen,
    generateCustomPriceFreezeOffer,
    selectedPriceFreezeOfferDataIndex,
    priceFreezeUserSelectedDurationSelectionMethod: selectionMethod,
    priceFreezeVoidWindow,
    priceFreezeVoidWindowEnd,
    priceFreezeMinOffer,
    handleGoBack,
  } = props;

  const footerVersion =
    selectionMethod === PriceFreezeDurationSelectionMethod.Buttons
      ? "short-price-remarks"
      : "full-explanation";

  const includeDurationsInSubtitle =
    selectionMethod !== PriceFreezeDurationSelectionMethod.Buttons;

  const useShortTitle =
    selectionMethod === PriceFreezeDurationSelectionMethod.Buttons;

  const hideDetailsSectionTopCriteria = {
    [PriceFreezeDurationSelectionMethod.Buttons]: true,
    [PriceFreezeDurationSelectionMethod.Slider]: true,
    [PriceFreezeDurationSelectionMethod.Toggle]: true,
  };

  const hideDetailsSectionTop = hideDetailsSectionTopCriteria[selectionMethod];

  const hideDetailsSectionBottomCriteria = {
    [PriceFreezeDurationSelectionMethod.Buttons]: true,
    [PriceFreezeDurationSelectionMethod.Slider]: false,
    [PriceFreezeDurationSelectionMethod.Toggle]: false,
  };

  const selectedPriceFreezeOfferTtlDays = convertSecondsToDays(selectedPriceFreezeOfferData?.secondsTtl ?? 0)

  const hideDetailsSectionBottom =
    hideDetailsSectionBottomCriteria[selectionMethod];

  const handleOnClose = () => {
    if (selectedPriceFreezeOfferData) {
      // note: it generates a new offer when the user exits this modal
      generateCustomPriceFreezeOffer(selectedPriceFreezeOfferData, history, false);
    }
    setPriceFreezeDurationModalOpen(false);
  };

  const handleOnContinue = () => {
    handleOnClose();
    handleGoBack && handleGoBack();
    onContinue && onContinue();
  };

  let showVoidWindowBanner: boolean;
  if(priceFreezeVoidWindowEnd){
    if(priceFreezeVoidWindowEnd === "Unavailable"){
      showVoidWindowBanner = false;
    } else {
      showVoidWindowBanner = dayjs().isBefore(dayjs(priceFreezeVoidWindowEnd))    
    }
  }

  const Footer = () => {
    const renderVoidWindowBanner = () => {
      return (
        (selectedPriceFreezeOfferDataIndex === 0 ||
          selectedPriceFreezeOfferDataIndex === 1) &&
        priceFreezeVoidWindow !== PRICE_FREEZE_VOID_WINDOW_CONTROL &&
        showVoidWindowBanner &&
        selectedPriceFreezeOfferTtlDays < 2 && (
          <NotificationBanner
            className="void-window-banner"
            severity={BannerSeverity.NOTICE}
            icon={<Icon name={IconName.AlertIcon} />}
            content={
              <Typography
                className="void-window-banner-label"
                variant="caption"
              >
                <span className="label-copy">{constants.VOID_WINDOW_TEXT}</span> 
              </Typography>
            }
          />
        )
      );
    };
    switch (footerVersion) {
      case "full-explanation":
        return (
          <Box className="footer-section">
            <Typography
              className="footer-copy"
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: constants.CHOOSE_DURATION_FOOTER_FULL_EXPLANATION(
                  `${selectedPriceFreezeOfferCap?.fiat.currencySymbol}${selectedPriceFreezeOfferCap?.fiat.value}`
                ),
              }}
            />
            {renderVoidWindowBanner()}
          </Box>
        );
      case "short-price-remarks":
        return (
          <Box className={clsx("footer-section")}>
            <Typography className="footer-copy" variant="body2">
              {constants.CHOOSE_DURATION_FOOTER_SHORT_PRICE_REMARKS(
                tripCategory === TripCategory.ROUND_TRIP
              )}
            </Typography>
            {renderVoidWindowBanner()}
          </Box>
        );
    }
  };

  const MobilePriceFreezeDurationContent = (
    <Box className="mobile-price-freeze-duration-content-root">
      <Box className="mobile-price-freeze-duration-content-container">
        <Box className="header-section">
          <Typography
            className={clsx("title", { "short-title": useShortTitle })}
            variant="h2"
          >
            {constants.CHOOSE_DURATION_TITLE(useShortTitle)}
          </Typography>
          <Typography className="subtitle" variant="body2">
            {constants.CHOOSE_DURATION_SUBTITLE(
              priceFreezeMinOffer?.secondsTtl,
              priceFreezeMaxDuration,
              includeDurationsInSubtitle
            )}
          </Typography>
        </Box>
        <Box className="duration-selection-section">
          <PriceFreezeDurationSelection
            textSize="small"
            useMultiPage
            hideDetailsSectionTop={hideDetailsSectionTop}
            hideDetailsSectionBottom={hideDetailsSectionBottom}
            disableGenerateOfferOnChange
            selectionMethod={selectionMethod}
          />
        </Box>
        <Footer />
        <Box className="cta-section">
          <ActionButton
            className={clsx("price-freeze-duration-continue-button", "b2b")}
            defaultStyle="h4r-primary"
            message={constants.CONTINUE}
            onClick={handleOnContinue}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <MobilePopoverCard
      open={priceFreezeDurationModalOpen}
      className="mobile-price-freeze-duration-popup"
      contentClassName="mobile-price-freeze-duration-content-wrapper"
      onClose={handleOnClose}
      centered={true}
      topRightButton={
        <ActionLink
          className="price-freeze-duration-close-button"
          onClick={handleOnClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea={true}
        />
      }
    >
      {MobilePriceFreezeDurationContent}
    </MobilePopoverCard>
  );
};
