import { actions, actionTypes } from "../actions";
import {
  CallState,
  Offer,
  IPassengerCounts,
  GetPriceFreezeResponse,
  PriceFreezePriceQuote,
  PriceFreezeOfferDataWithRewards,
  QuoteTokenKey,
  PollFareQuoteOutcome,
  ErrorModalType,
  TravelWalletCredit,
} from "redmond";
import {
  PRICE_FREEZE_USER_SELECTED_DURATION,
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY,
  PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY,
  PriceFreezeUserSelectedDurationVariantType,
  PriceFreezeUserSelectedDurationCopyVariantType,
  PriceFreezeUserSelectedDurationDisplayVariantType,
  PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER,
  PriceFreezeUserSelectedDurationNoSliderVariantType,
  PriceFreezeVoidWindowType,
  PRICE_FREEZE_VOID_WINDOW,
  PRICE_FREEZE_DEFAULT_DURATIONS,
  PriceFreezeDefaultDurationsType,
  PRICE_FREEZE_SHOW_DURATIONS,
  PriceFreezeShowDurationsType,
  DefaultVariantType,
  PRICE_FREEZE_TRAVEL_CREDITS,
  AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE,
  AirPriceFreezeOnlyFreezeQuotedPriceVariantType,
  PRICE_FREEZE_PREDICTION_COPY,
  PRICE_FREEZE_BACK_BUTTON_AIR,
} from "../../../context/experiments";

export enum Progress {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export const MINIMUM_ADULT_COUNT = 1;

export interface IFlightFreezeExperiments {
  [PRICE_FREEZE_USER_SELECTED_DURATION]: PriceFreezeUserSelectedDurationVariantType;
  [PRICE_FREEZE_USER_SELECTED_DURATION_COPY]: PriceFreezeUserSelectedDurationCopyVariantType;
  [PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY]: PriceFreezeUserSelectedDurationDisplayVariantType;
  [PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER]: PriceFreezeUserSelectedDurationNoSliderVariantType;
  [PRICE_FREEZE_VOID_WINDOW]: PriceFreezeVoidWindowType;
  [PRICE_FREEZE_DEFAULT_DURATIONS]: PriceFreezeDefaultDurationsType;
  [PRICE_FREEZE_SHOW_DURATIONS]: PriceFreezeShowDurationsType;
  [PRICE_FREEZE_TRAVEL_CREDITS]: DefaultVariantType;
  [AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE]: AirPriceFreezeOnlyFreezeQuotedPriceVariantType;
  [PRICE_FREEZE_PREDICTION_COPY]: DefaultVariantType;
  [PRICE_FREEZE_BACK_BUTTON_AIR]: DefaultVariantType;
}

export interface IFlightFreezeExperimentState {
  experiments: IFlightFreezeExperiments | null;
}

export type PriceFreezeFareQuoteErrorTitlesType =
  | ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED
  | ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED_DURING_PAYMENT
  | ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED
  | ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED_DURING_PAYMENT
  | ErrorModalType.PRICE_FREEZE_PURCHASE_HAS_NO_AVAILABILITY;

export interface IFlightFreezeState extends IFlightFreezeExperimentState {
  priceFreezeOfferCallState: CallState;
  setUpFlightFreezeParamsCallState: CallState;
  priceFreezeOffer: Offer | null;
  getPriceFreezeOfferDataCallState: CallState;
  priceFreezeOfferData: PriceFreezeOfferDataWithRewards[] | null;
  selectedPriceFreezeOfferDataIndex: number | null;
  generateCustomPriceFreezeOfferCallState: CallState;
  customPriceFreezeOffer: Offer | null;
  cheapestEligibleFlightCustomPriceFreezeOffer: Offer | null;
  counts: IPassengerCounts;
  currentPriceFreeze: GetPriceFreezeResponse | null;
  priceFreezeCallState: CallState;
  refundPriceFreezeCallState: CallState;
  displayPriceFreezeRefundModal: boolean;
  quoteData: PriceFreezePriceQuote | null;
  priceFreezeFareQuoteTokenKey: QuoteTokenKey | null;
  priceFreezeFareQuoteOutcome: PollFareQuoteOutcome | null;
  priceFreezeFareQuoteCallState: CallState;
  priceFreezeFareQuoteErrorTitlesType: PriceFreezeFareQuoteErrorTitlesType | null;
  priceFreezeFareQuoteAcknowledged: boolean;
  shouldIgnoreQuote?: boolean;
  priceFreezeEntryPointStep?: number;
  priceFreezeOfferRadioDurations: PriceFreezeOfferDataWithRewards[] | null;
  selectedRadioOfferDataIndex: number | null;
  initialPriceFreezeOfferDataWithRewards: PriceFreezeOfferDataWithRewards | null;
  initialSelectedPriceFreezeRadioIndex: number | null;
  fetchApplicableTravelWalletItemsCallState: CallState;
  credit?: TravelWalletCredit;
  creditToApply?: TravelWalletCredit;
}

export const initialState: IFlightFreezeState = {
  priceFreezeOfferCallState: CallState.NotCalled,
  setUpFlightFreezeParamsCallState: CallState.NotCalled,
  priceFreezeOffer: null,
  counts: {
    adultsCount: MINIMUM_ADULT_COUNT,
    childrenCount: 0,
    infantsInSeatCount: 0,
    infantsOnLapCount: 0,
  },
  currentPriceFreeze: null,
  priceFreezeCallState: CallState.NotCalled,
  refundPriceFreezeCallState: CallState.NotCalled,
  displayPriceFreezeRefundModal: false,
  quoteData: null,
  getPriceFreezeOfferDataCallState: CallState.NotCalled,
  priceFreezeOfferData: null,
  selectedPriceFreezeOfferDataIndex: null,
  generateCustomPriceFreezeOfferCallState: CallState.NotCalled,
  customPriceFreezeOffer: null,
  cheapestEligibleFlightCustomPriceFreezeOffer: null,
  priceFreezeFareQuoteTokenKey: null,
  priceFreezeFareQuoteOutcome: null,
  priceFreezeFareQuoteCallState: CallState.NotCalled,
  priceFreezeFareQuoteErrorTitlesType: null,
  priceFreezeFareQuoteAcknowledged: false,
  experiments: null,
  shouldIgnoreQuote: false,
  priceFreezeEntryPointStep: undefined,
  priceFreezeOfferRadioDurations: null,
  selectedRadioOfferDataIndex: null,
  initialPriceFreezeOfferDataWithRewards: null,
  initialSelectedPriceFreezeRadioIndex: null,
  fetchApplicableTravelWalletItemsCallState: CallState.NotCalled,
};

export const getExperimentState = (
  flightFreezeState: IFlightFreezeState | undefined
): IFlightFreezeExperimentState => {
  return {
    experiments: flightFreezeState?.experiments ?? initialState.experiments,
  };
};

export function reducer(
  state: IFlightFreezeState = initialState,
  action: actions.FlightFreezeActions
): IFlightFreezeState {
  switch (action.type) {
    case actionTypes.GET_PRICE_FREEZE_OFFER:
      return {
        ...state,
        priceFreezeOfferCallState: CallState.InProcess,
      };

    case actionTypes.SET_PRICE_FREEZE_OFFER:
      return {
        ...state,
        priceFreezeOffer: action.offer,
        priceFreezeOfferCallState: action.priceFreezeOfferCallState,
      };

    case actionTypes.SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED:
      return {
        ...state,
        priceFreezeOfferCallState: CallState.Failed,
      };

    case actionTypes.SET_PASSENGER_COUNTS:
      return {
        ...state,
        counts: action.counts,
      };

    case actionTypes.FETCH_PRICE_FREEZE:
      return {
        ...state,
        priceFreezeCallState: CallState.InProcess,
      };

    case actionTypes.SET_PRICE_FREEZE:
      return {
        ...state,
        currentPriceFreeze: action.priceFreeze,
        priceFreezeCallState: action.priceFreezeCallState,
      };

    case actionTypes.SET_PRICE_FREEZE_CALL_STATE_FAILED:
      return {
        ...state,
        priceFreezeCallState: CallState.Failed,
      };

    case actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS:
      return {
        ...state,
        setUpFlightFreezeParamsCallState: CallState.InProcess,
        priceFreezeOfferCallState: CallState.InProcess,
      };

    case actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED:
      return {
        ...state,
        setUpFlightFreezeParamsCallState: CallState.Success,
      };

    case actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED:
      return {
        ...state,
        setUpFlightFreezeParamsCallState: CallState.Failed,
      };

    case actionTypes.REFUND_PRICE_FREEZE:
      return {
        ...state,
        refundPriceFreezeCallState: CallState.InProcess,
      };

    case actionTypes.SET_REFUND_PRICE_FREEZE:
      return {
        ...state,
        refundPriceFreezeCallState: action.refundPriceFreezeCallState,
      };

    case actionTypes.DISPLAY_PF_REFUND_MODAL_FLOW:
      return {
        ...state,
        displayPriceFreezeRefundModal: action.value,
      };

    case actionTypes.SET_PRICE_FREEZE_QUOTE_DATA:
      return {
        ...state,
        quoteData: action.quoteData,
      };

    case actionTypes.RESET_PRICE_FREEZE_PURCHASE:
      return {
        ...state,
        priceFreezeOfferCallState: initialState.priceFreezeOfferCallState,
        setUpFlightFreezeParamsCallState:
          initialState.setUpFlightFreezeParamsCallState,
        priceFreezeOffer: initialState.priceFreezeOffer,
        quoteData: initialState.quoteData,
        // note: priceFreezeOfferData states will be cleared whenever shopSummary is called
        generateCustomPriceFreezeOfferCallState:
          initialState.generateCustomPriceFreezeOfferCallState,
        customPriceFreezeOffer: initialState.customPriceFreezeOffer,
        priceFreezeFareQuoteTokenKey: initialState.priceFreezeFareQuoteTokenKey,
        priceFreezeFareQuoteOutcome: initialState.priceFreezeFareQuoteOutcome,
        priceFreezeFareQuoteCallState:
          initialState.priceFreezeFareQuoteCallState,
        priceFreezeFareQuoteErrorTitlesType:
          initialState.priceFreezeFareQuoteErrorTitlesType,
        priceFreezeFareQuoteAcknowledged:
          initialState.priceFreezeFareQuoteAcknowledged,
      };

    case actionTypes.GET_PRICE_FREEZE_OFFER_DATA:
      return {
        ...state,
        getPriceFreezeOfferDataCallState: CallState.InProcess,
        priceFreezeOfferData: initialState.priceFreezeOfferData,
        selectedPriceFreezeOfferDataIndex:
          initialState.selectedPriceFreezeOfferDataIndex,
        generateCustomPriceFreezeOfferCallState:
          initialState.generateCustomPriceFreezeOfferCallState,
        customPriceFreezeOffer: initialState.customPriceFreezeOffer,
      };

    case actionTypes.SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED:
      return {
        ...state,
        getPriceFreezeOfferDataCallState: CallState.Failed,
      };

    case actionTypes.SET_PRICE_FREEZE_OFFER_DATA:
      return {
        ...state,
        getPriceFreezeOfferDataCallState: CallState.Success,
        priceFreezeOfferData: action.offerData,
      };

    case actionTypes.SET_SELECTED_PRICE_FREEZE_OFFER_DATA_INDEX:
      return {
        ...state,
        selectedPriceFreezeOfferDataIndex: action.index,
      };

    case actionTypes.GENERATE_CUSTOM_PRICE_FREEZE_OFFER:
      return {
        ...state,
        /*
          note: customPriceFreezeOffer is not being reset here because the duration slider is expected to update it dynamically;
          as the user drags the slider left and right, generateCustomPriceFreezeOffer gets triggered, and the old custom offer
          needs to persist in the state as a placeholder
        */
        generateCustomPriceFreezeOfferCallState: CallState.InProcess,
      };
    
    case actionTypes.SET_CHEAPEST_ELIGIBLE_FLIGHT_CUSTOM_PRICE_FREEZE_OFFER:
      return {
        ...state,
        cheapestEligibleFlightCustomPriceFreezeOffer: action.offer,
      };

    case actionTypes.SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_SUCCESS:
      return {
        ...state,
        generateCustomPriceFreezeOfferCallState: CallState.Success,
      };

    case actionTypes.SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED:
      return {
        ...state,
        generateCustomPriceFreezeOfferCallState: CallState.Failed,
      };

    case actionTypes.SET_CUSTOM_PRICE_FREEZE_OFFER:
      return {
        ...state,
        generateCustomPriceFreezeOfferCallState: CallState.Success,
        customPriceFreezeOffer: action.offer,
      };

    case actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE:
      return {
        ...state,
        priceFreezeFareQuoteTokenKey: null,
        priceFreezeFareQuoteCallState: CallState.InProcess,
      };

    case actionTypes.SET_PRICE_FREEZE_FARE_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        priceFreezeFareQuoteOutcome: null,
        priceFreezeFareQuoteCallState: CallState.Failed,
      };

    case actionTypes.SET_PRICE_FREEZE_FARE_QUOTE:
      return {
        ...state,
        priceFreezeFareQuoteTokenKey: action.priceFreezeFareQuoteTokenKey,
        priceFreezeFareQuoteOutcome: action.priceFreezeFareQuoteOutcome,
        priceFreezeFareQuoteCallState: CallState.Success,
        shouldIgnoreQuote: action.shouldIgnoreQuote,
      };

    case actionTypes.SET_PRICE_FREEZE_FARE_QUOTE_ERROR_TITLES_TYPE:
      return {
        ...state,
        priceFreezeFareQuoteErrorTitlesType: action.errorTitlesType,
        priceFreezeFareQuoteAcknowledged: false,
      };

    case actionTypes.ACKNOWLEDGE_PRICE_FREEZE_FARE_QUOTE_DETAILS:
      return {
        ...state,
        priceFreezeFareQuoteAcknowledged: action.acknowledged ?? true,
      };

    case actionTypes.SET_PRICE_FREEZE_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };
    case actionTypes.SET_PRICE_FREEZE_ENTRY_POINT_STEP:
      return {
        ...state,
        priceFreezeEntryPointStep: action.priceFreezeEntryPointStep,
      };
    case actionTypes.SET_PRICE_FREEZE_OFFER_RADIO_DURATIONS:
      return {
        ...state,
        priceFreezeOfferRadioDurations: action.radioDurations,
      };
    case actionTypes.SET_SELECTED_PRICE_FREEZE_RADIO_INDEX:
      return {
        ...state,
        selectedRadioOfferDataIndex: action.index,
      };
    case actionTypes.SET_INITIAL_PRICE_FREEZE_OFFER_DATA_WITH_REWARDS:
      return {
        ...state,
        initialPriceFreezeOfferDataWithRewards: action.offerData,
      };
    case actionTypes.SET_INITIAL_SELECTED_PRICE_FREEZE_RADIO_INDEX:
      return {
        ...state,
        initialSelectedPriceFreezeRadioIndex: action.index
      }
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.InProcess,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.Success,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.Failed,
      };
    case actionTypes.SET_TRAVEL_WALLET_CREDIT:
      return {
        ...state,
        credit: action.credit
      };
    case actionTypes.SET_CREDIT_TO_APPLY:
      return {
        ...state,
        creditToApply: action.credit,
      };

    default:
      return state;
  }
}

export * from "./selectors";
