import React, { useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  EntryButtonEnum,
  TimeToLive,
  CONTINUE_DURATION_SLIDER,
  FiatPrice,
  Prediction,
  RecommendationV2Enum,
} from "redmond";
import {
  ActionLink,
  getTotalPriceText,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import * as constants from "../textConstants";
import "./styles.scss";
import {
  PriceFreezeExplanationModal,
  IPriceFreezeExplanationModalOwnProps,
} from "../PriceFreezeExplanationModal";
import {
  IPriceFreezeFlightDetailsModalOwnProps,
  PriceFreezeFlightDetailsModal,
} from "../PriceFreezeFlightDetailsModal";
import {
  MobilePriceFreezeDurationModal,
  getPriceFreezeDurationSelectionPageProperty,
} from "../../../../freeze/components/priceFreezeDurationComponents";
import { PATH_FREEZE, PATH_PRICE_FREEZE } from "../../../../../utils/urlPaths";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import {
  goToPriceFreezeCheckout,
  handleClickOpenInfoModalLink,
  IGoToPriceFreezeCheckoutProps,
} from "../sharedUtils";
import { PriceFreezeEntryInfoConnectorProps } from "./container";
import { PriceFreezeSelectedDurationModal } from "../PriceFreezeSelectedDurationModal";
import { getPriceFreezeRewardsString } from "../../../utils/helpers";
import { AVAILABLE, getExperimentVariant, PRICE_FREEZE_PREDICTION_COPY, useExperiments } from "../../../../../context/experiments";

export interface IPriceFreezeEntryInfoProps
  extends PriceFreezeEntryInfoConnectorProps,
  IPriceFreezeEntryInfoOwnProps,
  RouteComponentProps { }

export interface IPriceFreezeEntryInfoOwnProps {
  useLowestPriceText?: boolean;
  fiatPrice?: string;
  rewards?: string;
  duration?: TimeToLive;
  showFreezeIcon?: boolean;
  withSmallBanner?: boolean;
  useDetailsPrefix?: boolean;
  highlightedRewards?: boolean;
  titleCaseHeader?: boolean;
  largeInfoDetailsInMobile?: boolean;
  priceFreezeEntryInfoTexts?: {
    header?: string;
    details?: string;
    priceFreezeIcon?: IconName;
  };
  disableTitleAsLink?: boolean;
  removeInfoTooltip?: boolean;
  showLearnMore?: boolean;
  useLockPriceLanguage?: boolean;
  priceFreezeFlightDetailsModalProps: IPriceFreezeFlightDetailsModalOwnPropsForInfo;
  priceFreezeExplanationModalProps: IPriceFreezeExplanationModalOwnPropsForInfo;
  isAirPriceFreezeNewReviewCTAEnabled?: boolean,
  isFromFlightShopReviewItinerary: boolean,
  prediction: Prediction | null,
}

export type IPriceFreezeFlightDetailsModalOwnPropsForInfo = Omit<
  IPriceFreezeFlightDetailsModalOwnProps,
  "priceFreezeFlightDetailsModalOpen" | "setPriceFreezeFlightDetailsModalOpen"
>;

export type IPriceFreezeExplanationModalOwnPropsForInfo = Omit<
  IPriceFreezeExplanationModalOwnProps,
  | "priceFreezeExplanationModalOpen"
  | "setPriceFreezeExplanationModalOpen"
  | "setPriceFreezeFlightDetailsModalOpen"
  | "setPriceFreezeDurationModalOpen"
>;

export const PriceFreezeEntryInfo = (props: IPriceFreezeEntryInfoProps) => {
  const {
    useLowestPriceText,
    history,
    fiatPrice,
    rewards,
    duration,
    showFreezeIcon,
    withSmallBanner,
    useDetailsPrefix,
    highlightedRewards,
    titleCaseHeader,
    largeInfoDetailsInMobile,
    priceFreezeEntryInfoTexts,
    disableTitleAsLink,
    removeInfoTooltip,
    showLearnMore,
    useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps,
    priceFreezeExplanationModalProps,
    isPriceFreezeDurationActive,
    isPriceFreezeDurationNewCopyActive,
    isPriceFreezeDurationDefault12HrCopyActiveSelector,
    populateFlightBookQueryParams,
    isPriceFreezeDurationPopupEnabled,
    priceDropProtectionCandidateId,
    changedPriceFreezeDurationProperties,
    priceFreezeMinOffer,
    selectedRewardsAccountId,
    isAirPriceFreezeNewReviewCTAEnabled,
    priceFreezeMiddleOffer,
    priceFreezeDefaultDurationsVariant,
    priceFreezeOfferDataLength,
    isCheapestPriceFreezeOffer,
    isPriceFreezeShowDurationsVariant1Active,
    isFromFlightShopReviewItinerary,
    prediction,
  } = props;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const expState = useExperiments();
  const [priceFreezeExplanationModalOpen, setPriceFreezeExplanationModalOpen] =
    useState(false);
  const [
    priceFreezeFlightDetailsModalOpen,
    setPriceFreezeFlightDetailsModalOpen,
  ] = useState<boolean>(false);
  const [priceFreezeDurationModalOpen, setPriceFreezeDurationModalOpen] =
    useState<boolean>(false);
  // note: the PF duration selection modal is only shown on mobile (in flights shop)
  const isPriceFreezeDurationMobileModalEnabled =
    matchesMobile && isPriceFreezeDurationActive;
  const isPriceFreezeDurationDesktopPopupEnabled =
    matchesDesktop && isPriceFreezeDurationPopupEnabled;
  const showPriceFreezeDurationModal =
    isPriceFreezeDurationMobileModalEnabled ||
    isPriceFreezeDurationDesktopPopupEnabled;
  const priceFreezePredictionCopy = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE_PREDICTION_COPY
  );
  const isPriceFreezePredictionCopyXpEnabled = useMemo(
    () => priceFreezePredictionCopy === AVAILABLE,
    [priceFreezePredictionCopy]
  );
  const shouldRunPredictionCopyXp =
    isPriceFreezePredictionCopyXpEnabled &&
    !!prediction &&
    isCheapestPriceFreezeOffer;

  const openPriceFreezeDurationModal = () => {
    setPriceFreezeFlightDetailsModalOpen(false);
    setPriceFreezeDurationModalOpen(true);
  };

  const getHeader = () => {
    let header: string;
    if (isPriceFreezeShowDurationsVariant1Active && isFromFlightShopReviewItinerary) {
      header = constants.PRICE_FREEZE_INFO_HEADER_TITLE_CASE(useLockPriceLanguage)
    } else if (isAirPriceFreezeNewReviewCTAEnabled) {
      header = constants.PRICE_FREEZE_INFO_DETAILS_PREFIX
    } else if (priceFreezeEntryInfoTexts?.header) {
      header = priceFreezeEntryInfoTexts.header;
    } else {
      header = titleCaseHeader
        ? constants.PRICE_FREEZE_INFO_HEADER_TITLE_CASE(useLockPriceLanguage)
        : constants.PRICE_FREEZE_INFO_HEADER(useLockPriceLanguage);
    }

    if (shouldRunPredictionCopyXp && prediction.recommendationV2 === RecommendationV2Enum.Buy) {
        header = constants.PRICE_FREEZE_PREDICTION_XP_BUY_NOW_HEADER
    }

    return header;
  };

  const getDetails = () => {
    let details: string;
    if (priceFreezeEntryInfoTexts?.details) {
      details = priceFreezeEntryInfoTexts.details;
    } else {
      let prefix = ""
      if (isAirPriceFreezeNewReviewCTAEnabled) {
        prefix = constants.PRICE_FREEZE_INFO_HEADER(false) + ". "
      } else {
        prefix = useDetailsPrefix ? constants.PRICE_FREEZE_INFO_DETAILS_PREFIX : "";
      }

      let mainText = constants.PRICE_FREEZE_INFO_DETAILS(
        fiatPrice as string,
        rewards as string,
        duration,
        highlightedRewards,
        useLockPriceLanguage
      );

      if (useLowestPriceText) {
        mainText = constants.PRICE_FREEZE_INFO_LOWEST_PRICE_DETAILS(
          fiatPrice as string,
          rewards as string,
          duration,
          highlightedRewards,
          useLockPriceLanguage,
          isCheapestPriceFreezeOffer
        );
      }

      if (isPriceFreezeDurationNewCopyActive) {
        mainText = constants.PRICE_FREEZE_USER_SELECTED_DURATION(
          fiatPrice as string,
          rewards as string,
          duration,
          priceFreezeOfferDataLength,
          highlightedRewards,
          useLockPriceLanguage,
          isCheapestPriceFreezeOffer
        );
      }

      if (isPriceFreezeDurationDefault12HrCopyActiveSelector) {
        mainText = constants.PRICE_FREEZE_USER_SELECTED_DURATION_DEFAULT_12_HR(
          getTotalPriceText({
            price: priceFreezeMinOffer?.price.fiat as FiatPrice,
          }),
          getPriceFreezeRewardsString(
            priceFreezeMinOffer?.price.rewards,
            selectedRewardsAccountId
          ),
          highlightedRewards,
          useLockPriceLanguage,
          isCheapestPriceFreezeOffer
        );
      }

      if (priceFreezeDefaultDurationsVariant === "Middle" && priceFreezeMiddleOffer) {
        mainText = constants.PRICE_FREEZE_DEFAULT_DURATIONS(
          getTotalPriceText({
            price: priceFreezeMiddleOffer.price.fiat as FiatPrice,
          }),
          getPriceFreezeRewardsString(
            priceFreezeMiddleOffer.price.rewards,
            selectedRewardsAccountId
          ),
          priceFreezeMiddleOffer.secondsTtl,
          priceFreezeOfferDataLength,
          highlightedRewards,
          useLockPriceLanguage,
          isCheapestPriceFreezeOffer
        );
      }

      if (priceFreezeDefaultDurationsVariant === "KnockOut") {
        mainText = constants.PRICE_FREEZE_DEFAULT_DURATIONS_KNOCKOUT(
          fiatPrice as string,
          rewards as string,
          highlightedRewards,
          useLockPriceLanguage,
          isCheapestPriceFreezeOffer
        );
      }


      if (shouldRunPredictionCopyXp) {
        if (prediction.recommendationV2 === RecommendationV2Enum.Wait) {
          mainText = constants.PRICE_FREEZE_PREDICTION_XP_WAIT_MAIN_TEXT(
            fiatPrice as string,
            rewards as string,
          )
        }
        if (prediction.recommendationV2 === RecommendationV2Enum.Buy) {
          mainText = constants.PRICE_FREEZE_PREDICTION_XP_BUY_NOW_MAIN_TEXT(
            fiatPrice as string,
            rewards as string,
          )
        }
      }

      details = prefix + mainText;
    }

    return details;
  };

  const goToPriceFreezeCheckoutProps: IGoToPriceFreezeCheckoutProps = {
    tripDetails: priceFreezeFlightDetailsModalProps.tripDetails,
    fareId: priceFreezeFlightDetailsModalProps.fareId,
    handleGoToPriceFreezeCheckout:
      priceFreezeFlightDetailsModalProps.handleGoToPriceFreezeCheckout,
    priceDropProtectionCandidateId: priceDropProtectionCandidateId,
    history,
    entryButton: EntryButtonEnum.INFO_MODAL,
  };

  let priceFreezeMiddleDurationExplanationModalProps: IPriceFreezeExplanationModalOwnPropsForInfo;
  if (priceFreezeDefaultDurationsVariant === "Middle" && priceFreezeMiddleOffer) {

    let dataForTitles = {
      priceFreezeFiat: priceFreezeMiddleOffer.price.fiat,
      priceFreezeRewards: priceFreezeMiddleOffer.price.rewards,
      selectedRewardsAccountId: selectedRewardsAccountId,
      priceFreezeCapAmount: priceFreezeMiddleOffer.cap.fiat.value,
      priceFreezeCapCurrency: priceFreezeMiddleOffer.cap.fiat.currencySymbol,
      duration: priceFreezeMiddleOffer.secondsTtl,
      useLockPriceLanguage: useLockPriceLanguage,
    }

    priceFreezeMiddleDurationExplanationModalProps = {
      showPriceFreezeFlightDetails: true,
      priceFreezeTitles: constants.getPriceFreezeDurationTitles({ ...dataForTitles }),
      useLockPriceLanguage: useLockPriceLanguage,
    }

  } else {
    priceFreezeMiddleDurationExplanationModalProps = priceFreezeExplanationModalProps
  }

  return (
    <>
      <Box className="price-freeze-info-section">
        <Box className="price-freeze-info-section-title">
          {withSmallBanner && (
            <Box className="price-freeze-banner-icon">
              <Icon
                className="price-freeze-icon"
                name={IconName.B2bPriceFreeze}
              />
            </Box>
          )}

          <ActionLink
            className="price-freeze-info-link"
            onClick={
              !disableTitleAsLink
                ? () =>
                  handleClickOpenInfoModalLink(
                    setPriceFreezeExplanationModalOpen,
                    history
                  )
                : undefined
            }
            disabled={disableTitleAsLink}
            content={
              <>
                {showFreezeIcon && (
                  <Icon
                    className="price-freeze-icon"
                    name={
                      priceFreezeEntryInfoTexts?.priceFreezeIcon ??
                      (useLockPriceLanguage
                        ? IconName.LockWithKeyhole
                        : IconName.B2bPriceFreezeNoCircle)
                    }
                  />
                )}
                <Typography
                  variant="subtitle2"
                  className="more-info"
                  dangerouslySetInnerHTML={{
                    __html: getHeader(),
                  }}
                />
                {!removeInfoTooltip && (
                  <Icon
                    className="info-icon"
                    name={IconName.InfoCircle}
                    ariaLabel={constants.PRICE_FREEZE_INFO_LINK_LABEL(
                      useLockPriceLanguage
                    )}
                  />
                )}
              </>
            }
            label={constants.PRICE_FREEZE_INFO_LINK_LABEL(useLockPriceLanguage)}
          />
        </Box>
        {
          isPriceFreezeShowDurationsVariant1Active && isFromFlightShopReviewItinerary ?
            <></>
            :
            <Typography
              variant="body2"
              className={clsx("price-freeze-info-details", {
                "large-text-in-mobile": largeInfoDetailsInMobile,
              })}
              dangerouslySetInnerHTML={{
                __html: getDetails(),
              }}
            />
        }
        {showLearnMore && (
          <Box className="price-freeze-info-learn-more">
            <ActionLink
              onClick={() =>
                handleClickOpenInfoModalLink(
                  setPriceFreezeExplanationModalOpen,
                  history
                )
              }
              content={constants.LEARN_MORE}
            />
          </Box>
        )}
      </Box>
      <PriceFreezeFlightDetailsModal
        {...priceFreezeFlightDetailsModalProps}
        handleContinue={
          showPriceFreezeDurationModal
            ? openPriceFreezeDurationModal
            : undefined
        }
        priceFreezeFlightDetailsModalOpen={priceFreezeFlightDetailsModalOpen}
        setPriceFreezeFlightDetailsModalOpen={
          setPriceFreezeFlightDetailsModalOpen
        }
      />
      {isPriceFreezeDurationDesktopPopupEnabled && (
        <PriceFreezeSelectedDurationModal
          priceFreezeSelectedDurationModalOpen={priceFreezeDurationModalOpen}
          setPriceFreezeSelectedDurationModalOpen={
            setPriceFreezeDurationModalOpen
          }
          termsProps={{
            content: constants.PRICE_FREEZE_DETAILS_READ_TERMS_TEXT,
            ariaLabelText: constants.PRICE_FREEZE_DETAILS_READ_TERMS_LABEL,
            onClick: () => window.open(`${PATH_PRICE_FREEZE}`, "_blank"),
          }}
          handleContinue={() => {
            // note: goToPriceFreezeCheckout mutates history, so it needs to stay in this order
            const { page } =
              getPriceFreezeDurationSelectionPageProperty(history) ?? {};

            goToPriceFreezeCheckout(goToPriceFreezeCheckoutProps);
            trackEvent({
              eventName: CONTINUE_DURATION_SLIDER,
              properties: {
                ...changedPriceFreezeDurationProperties,
                page,
              },
            });
          }}
          disableGenerateOfferOnChange={true}
        />
      )}
      {isPriceFreezeDurationMobileModalEnabled && (
        <MobilePriceFreezeDurationModal
          onContinue={() =>
            populateFlightBookQueryParams({
              history,
              pathname: PATH_FREEZE,
              preserveQuery: true,
            })
          }
          priceFreezeDurationModalOpen={priceFreezeDurationModalOpen}
          setPriceFreezeDurationModalOpen={setPriceFreezeDurationModalOpen}
        />
      )}
      <PriceFreezeExplanationModal
        {...priceFreezeMiddleDurationExplanationModalProps}
        showPriceFreezeDuration={showPriceFreezeDurationModal}
        priceFreezeExplanationModalOpen={priceFreezeExplanationModalOpen}
        setPriceFreezeExplanationModalOpen={setPriceFreezeExplanationModalOpen}
        setPriceFreezeFlightDetailsModalOpen={
          setPriceFreezeFlightDetailsModalOpen
        }
        setPriceFreezeDurationModalOpen={setPriceFreezeDurationModalOpen}
      />
    </>
  );
};
