import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  SEAT_SELECTION_SUBTITLE,
  SKIP_SEAT_SELECTION_CTA,
  MOBILE_SKIP_SEAT_SELECTION_SUBTITLE,
  SEAT_MAP_MODAL_TITLE,
  getSeatSelectionCTAText,
  MOBILE_HOPPER_SEAT_MAP_STYLE,
} from "./textConstants";
import {
  MobilePopoverCard,
  MobileFloatingButton,
  BackButton,
  ActionLink,
  CloseButtonIcon,
  SelectedSeatsConfirmation,
} from "halifax";
import "./styles.scss";
import { MobileSeatSelectionConnectorProps } from "./container";
import {
  CHOSE_FROM_SEATMAP,
  GordianSeat,
  HopperSeat,
  SeatMapAvailable,
  SeatMapResponseEnum,
  SeatSegment,
  SelectedSeatsSegment,
  VIEWED_SEATMAP,
} from "redmond";
import { getSelectedSeatsSegments } from "../SeatSelection/component";
import {
  GORDIAN_SEAT_MAP_OPTIONS,
  HOPPER_SEAT_MAP_OPTIONS,
} from "../SeatSelection/textConstants";
import { convertUsdToAllRewards } from "../../../../api/v0/rewards/convertUsdToAllRewards";
import { setSkipSeatSelection } from "../../actions/actions";
import { SeatSelectionErrorModal } from "../SeatSelectionErrorModal";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { HopperSeats } from "@capone/checkout";

export enum SeatSelectionStep {
  Main,
  SeatSelection,
}
export interface ISeatSelectionProps extends MobileSeatSelectionConnectorProps {
  className?: string;
  title: string;
  disabled?: boolean;
  mobileHeaderElement?: JSX.Element;
  fullScreenWithBanner?: boolean;
  progressStep: SeatSelectionStep;
  setProgressStep: (progressStep: SeatSelectionStep) => void;
  onProgressChange?: (step: SeatSelectionStep) => void;
  onGoBack?: () => void;
  onContinue?: () => void;
}

export const MobileSeatSelection = ({
  title,
  className,
  mobileHeaderElement,
  fullScreenWithBanner,
  progressStep,
  setProgressStep,
  onProgressChange,
  onGoBack,
  onContinue,
  fetchSeatMap,
  priceQuote,
  seatSelectAvailability,
  seatMapHtml,
  setSelectedSeats,
  selectedSeats,
  tripDetails,
  passengers,
  airports,
  cheapestSeat,
  seatMapAvailability,
  setSeatMap,
}: ISeatSelectionProps) => {
  const [openSeatSelectionModal, setOpenSeatSelectionModal] =
    React.useState<boolean>(false);
  const [openSeatMapModal, setOpenSeatMapModal] =
    React.useState<boolean>(false);
  const [selectedSeatsInfo, setSelectedSeatsInfo] = React.useState<
    SeatSegment[]
  >([]);
  const [outboundSegmentsSeats, setOutboundSegmentsSeats] = React.useState<
    SelectedSeatsSegment[]
  >([]);
  const [returnSegmentsSeats, setReturnSegmentsSeats] = React.useState<
    SelectedSeatsSegment[]
  >([]);
  const [segmentIndex, setSegmentIndex] = React.useState<number>(0);
  const [hasSeatError, setHasSeatError] = React.useState<boolean>(false);

  const setProgress = (step: SeatSelectionStep) => {
    setProgressStep(step);
    if (onProgressChange) {
      onProgressChange(step);
    }
  };
  React.useEffect(() => {
    switch (progressStep) {
      case SeatSelectionStep.Main:
        setOpenSeatSelectionModal(false);
        break;
      case SeatSelectionStep.SeatSelection:
        setOpenSeatSelectionModal(true);
        break;
      default:
        break;
    }
  }, [progressStep]);

  React.useEffect(() => {
    if (!priceQuote) {
      setSelectedSeats([]);
      setSeatMap(null);
    }
  }, [priceQuote]);

  React.useEffect(() => {
    if (seatSelectAvailability === SeatMapResponseEnum.SeatMapAvailable) {
      fetchSeatMap((priceQuote?.seatMap as SeatMapAvailable).callback);
    }
  }, [seatSelectAvailability]);

  React.useEffect(() => {
    if (!seatMapAvailability) {
      setSkipSeatSelection(true);
    }
  }, [seatMapAvailability]);

  React.useEffect(() => {
    setOpenSeatMapModal(false);
    if (selectedSeats.length === 0) {
      setSelectedSeatsInfo([]);
    }
  }, [selectedSeats]);

  React.useEffect(() => {
    if (selectedSeatsInfo.length > 0) {
      const outboundSegmentSelectedSeats = getSelectedSeatsSegments(
        selectedSeatsInfo,
        tripDetails.slices[0],
        passengers,
        tripDetails.slices[0].segmentDetails.length,
        (priceQuote?.seatMap as SeatMapAvailable).seatsInfo.slices[0]
      );
      setOutboundSegmentsSeats(outboundSegmentSelectedSeats);
      if (tripDetails.slices.length > 1) {
        const returnSegmentSelectedSeats = getSelectedSeatsSegments(
          selectedSeatsInfo,
          tripDetails.slices[1],
          passengers,
          tripDetails.slices[0].segmentDetails.length,
          (priceQuote?.seatMap as SeatMapAvailable).seatsInfo.slices[1]
        );
        setReturnSegmentsSeats(returnSegmentSelectedSeats);
      }
    }
  }, [selectedSeatsInfo]);

  React.useEffect(() => {
    // removes medallia from the fixed bottom that was covering pricing
    if (document && document.getElementById("nebula_div_btn")) {
      if (openSeatMapModal) {
        document!.getElementById("nebula_div_btn")!.style.display = "none";
      } else {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    }
  }, [openSeatMapModal]);

  const onFrameLoad = (_: any) => {
    const iframe = document.getElementById(
      "iframe-id"
    ) as HTMLIFrameElement as any;

    iframe.contentWindow.HopperSeats != null
      ? initHopperSeats(iframe)
      : initGordianSeats(iframe);
  };

  const initGordianSeats = (frame: any) => {
    let styles = `@font-face { font-family: "Optimist"; font-style: normal; font-weight: normal; src: url(${window.location.origin}/email-assets/fonts/Optimist_Rg.ttf) format("truetype");}`;
    var styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    frame.contentWindow.document.head.appendChild(styleSheet);
    frame.contentDocument.body.children[0].style.height = "100vh";
    const addPaxIdAndName = frame.contentWindow.addPaxIdAndName;
    const getAppDisplayInfo = frame.contentWindow.getAppDisplayInfo;
    const seatsSelected = frame.contentWindow.hopper_seats.seatsSelected;
    frame.contentWindow.gordianResponse = (products: any) => {
      const productsWithPaxId = addPaxIdAndName(products);
      const appDisplayInfo = getAppDisplayInfo(products);
      seatsSelected(productsWithPaxId, appDisplayInfo);
      try {
        const prods: Promise<GordianSeat>[] = productsWithPaxId.map(
          async (seat: Omit<GordianSeat, "rewards">) => {
            const rewards = await convertUsdToAllRewards({
              amount:
                seat.price_object.usd_total /
                10 ** seat.price_object.decimal_places,
            });
            return { ...seat, rewards };
          }
        );
        setOpenSeatMapModal(false);
        Promise.all(prods).then((seats) => {
          if (
            seats.every(
              (seat) => seat.rewards && Object.keys(seat.rewards).length > 0
            )
          ) {
            setSelectedSeats(seats);
            setSelectedSeatsInfo(appDisplayInfo.segments);
            onContinue && onContinue();
          } else {
            setOpenSeatMapModal(false);
            setOpenSeatSelectionModal(true);
            setHasSeatError(true);
          }
        });
      } catch {
        setSelectedSeats([]);
        setSelectedSeatsInfo([]);
      }
    };
    const passengers = frame.contentWindow.PASSENGERS;
    const container = document.getElementById("seatmap");
    const options = GORDIAN_SEAT_MAP_OPTIONS;
    frame.contentWindow.GordianDisplay.showSeatMap({
      ...options,
      passengers,
      container,
      segmentIndex,
    });
  };

  const initHopperSeats = (iframe: any) => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = MOBILE_HOPPER_SEAT_MAP_STYLE;
    iframe.contentWindow.document.head.appendChild(styleSheet);
    iframe.contentDocument.body.children[0].style.height = "100vh";

    iframe.contentWindow.hopperResponse = (
      products: any[],
      appDisplayInfo: any
    ) => {
      if (products.length > 0) {
        trackEvent({
          eventName: CHOSE_FROM_SEATMAP,
          properties: {},
        });
      }

      const prods: Promise<HopperSeat>[] = products.map(
        async (seat: Omit<HopperSeat, "rewards">) => {
          const rewards = await convertUsdToAllRewards({
            amount:
              seat.price_object.amountUsd /
              10 ** seat.price_object.decimalPlaces,
          });
          return { ...seat, rewards };
        }
      );
      setOpenSeatMapModal(false);
      Promise.all(prods).then((seats) => {
        if (
          seats.every(
            (seat) => seat.rewards && Object.keys(seat.rewards).length > 0
          )
        ) {
          setSelectedSeats(
            seats.map((seat) => ({
              ...seat,
              price_object: {
                markup_amount: 0,
                currency: seat.price_object.currency,
                total: seat.price_object.amount,
                usd_base_price: seat.price_object.amountUsd,
                base_price: seat.price_object.amount,
                usd_markup_amount: 0,
                usd_total: seat.price_object.amountUsd,
                decimal_places: seat.price_object.decimalPlaces,
              },
            }))
          );
          setSelectedSeatsInfo(appDisplayInfo.segments);
          onContinue?.();
        } else {
          setOpenSeatMapModal(false);

          setHasSeatError(true);
        }
      });
    };
    (iframe.contentWindow.HopperSeats as HopperSeats)
      .showSeatMap({
        segmentIndex,
        ...HOPPER_SEAT_MAP_OPTIONS,
      })
      .then(() => {
        trackEvent({
          eventName: VIEWED_SEATMAP,
          properties: {},
        });
      })
      .catch((e) => console.error(e));
  };

  const onEditClick = (idx: number) => {
    setSegmentIndex(idx);
    setOpenSeatMapModal(true);
  };

  const onTryAgainClick = () => {
    setHasSeatError(false);
  };

  const onSeatErrorSkipClick = () => {
    setHasSeatError(false);
    setSkipSeatSelection(true);
    setProgress(SeatSelectionStep.Main);
    onContinue && onContinue();
    setSelectedSeats([]);
  };

  const renderHeaderContent = () => {
    return (
      <Box className="header-content">
        <Typography variant="h6" className="header-text">
          {SEAT_MAP_MODAL_TITLE}
        </Typography>
        <ActionLink
          className="seat-selection-map-close-button"
          onClick={() => {
            setOpenSeatMapModal(false);
            setOpenSeatSelectionModal(true);
          }}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea={true}
        />
      </Box>
    );
  };

  const renderMobileSeatSelectionPopup = () => {
    return (
      <>
        {openSeatMapModal && seatMapHtml ? (
          <MobilePopoverCard
            open={openSeatMapModal}
            onClose={() => setOpenSeatMapModal(false)}
            className={clsx("seat-map-wrapper", "b2b", "mobile")}
            contentClassName="seat-map-content"
            headerElement={renderHeaderContent()}
            fullScreen={true}
            fullScreenWithBanner={fullScreenWithBanner}
          >
            <Box className="iframe-wrapper">
              <iframe
                id="iframe-id"
                marginHeight={0}
                marginWidth={0}
                frameBorder={0}
                title="seat map popup"
                srcDoc={seatMapHtml}
                onLoad={(obj) => onFrameLoad(obj)}
              ></iframe>
            </Box>
          </MobilePopoverCard>
        ) : (
          <MobilePopoverCard
            open={openSeatSelectionModal}
            className={clsx("seat-selection-popup", "mobile", "b2b", className)}
            onClose={() => {
              setProgress(SeatSelectionStep.Main);
              onGoBack && onGoBack();
            }}
            fullScreen={true}
            headerElement={mobileHeaderElement}
            fullScreenWithBanner={fullScreenWithBanner}
            topLeftButton={
              <BackButton
                className="seat-selection-back-button"
                onClick={() => {
                  setProgress(SeatSelectionStep.Main);
                  onGoBack && onGoBack();
                }}
              />
            }
          >
            <Box className="seat-selection-container">
              <Box className="seat-selection-title-section">
                <Typography variant="h4" className="seat-selection-title">
                  {title}
                </Typography>
                <Typography variant="body2">
                  {SEAT_SELECTION_SUBTITLE}
                </Typography>
                <Typography variant="body2">
                  {MOBILE_SKIP_SEAT_SELECTION_SUBTITLE}
                </Typography>
              </Box>

              <Divider className="seat-selection-divider" />
            </Box>
            <MobileFloatingButton
              className={clsx("seat-selection-button", "b2b")}
              wrapperClassName={clsx("seat-selection-button-container", "b2b")}
              onClick={() => {
                setOpenSeatMapModal(true);
                setOpenSeatSelectionModal(false);
              }}
            >
              {getSeatSelectionCTAText(cheapestSeat)}
            </MobileFloatingButton>
            <MobileFloatingButton
              className="seat-selection-opt-out-button"
              wrapperClassName={clsx("seat-selection-opt-out-button-container")}
              onClick={() => {
                setProgress(SeatSelectionStep.Main);
                onContinue && onContinue();
                setSelectedSeats([]);
                setSkipSeatSelection(true);
              }}
            >
              {SKIP_SEAT_SELECTION_CTA}
            </MobileFloatingButton>
          </MobilePopoverCard>
        )}
      </>
    );
  };
  return (
    <>
      {selectedSeatsInfo.length > 0 && (
        <Box className={clsx("seat-selection-workflow-root", "mobile")}>
          <Typography variant="h4" className="seat-selection-title">
            {title}
          </Typography>
          <SelectedSeatsConfirmation
            outboundSeatSegments={outboundSegmentsSeats}
            returnSeatSegments={returnSegmentsSeats}
            onEditClick={onEditClick}
            outboundOriginCode={tripDetails.slices[0].originCode}
            returnOriginCode={
              tripDetails.slices.length > 1
                ? tripDetails.slices[1].originCode
                : undefined
            }
            isMobile={true}
            airports={airports}
          />
        </Box>
      )}
      {renderMobileSeatSelectionPopup()}
      <SeatSelectionErrorModal
        hasError={hasSeatError}
        onTryAgainClick={onTryAgainClick}
        onSeatErrorSkipClick={onSeatErrorSkipClick}
        isMobile
      />
    </>
  );
};
