import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  getTotalPriceText,
  twoDecimalFormatter,
  ISummaryLineItem,
  emailRegex,
  phoneRegex,
  roundToTwoDecimals,
  getTotalPassenger,
  getAgentErrorTitle,
  getAgentErrorSubtitle,
  getRewardText,
  getTotalPassengerFromPaxPricing,
  IconName,
  getCurrencySymbol,
  GENERIC_ERR_TITLE,
  GENERIC_ERR_SUBTITLE,
  IFlightSummaryData,
  truncateToTwoDecimals,
  getCheckoutCreditBreakdown,
} from "halifax";
import {
  CallState,
  Currency,
  Prices as LegacyPrices,
  RewardsPrice,
  FiatPrice,
  TimeToLive,
  CreditCard,
  PassengerError,
  PaymentAmountEnum,
  PaymentSplitRequest,
  PaymentSplitRequestEnum,
  SchedulePriceQuoteRequest,
  IsEligible,
  PaymentVerifyResultEnum,
  ReviewFlightDetailsProperties,
  FareBrand,
  CompleteBuyAirProperties,
  PriceDropViewedProperties,
  PriceDropProtectionEnum,
  RewardsAccountMinimumRequirementState,
  getRewardsAccountMinimumRequirementState,
  getHopperFareRatingName,
  SeatMapResponseEnum,
  PostQuoteAncillariesEnum,
  PriceFreezeSchedulePriceQuoteRequest,
  PriceFreezeItineraryEnum,
  FlightBookType,
  PostQuoteAncillaries,
  PaymentType,
  TypeOfPaymentEnum,
  SplitPaymentType,
  RewardsPaymentType,
  UserCardPaymentType,
  ErrorTitles,
  ErrorModalType,
  CurrentFareVersusCapEnum,
  PriceDropProtection,
  ProtectionId,
  PassengersForFareRequest,
  CompleteBuyAirPriceFreezeProperties,
  PassengerTypes,
  ProtectionPolicyIdEnum,
  ProtectionIdEnum,
  PriceQuoteAirProperties,
  CfarAttachProperties,
  PaymentV2Enum,
  AncillaryOfferEnum,
  ITrackingProperties,
  SelectedPriceFreezePurchaseProperties,
  PaymentError as LegacyPaymentError,
  GeneralPurchaseErrorEnum,
  PurchaseError as RedmondPurchaseError,
  UncategorizedPurchaseError,
  PaymentErrorEnum,
  PaymentPurchaseErrorEnum,
  TravelItineraryEnum,
  BookedFlight,
  PartialRebookedItineraryDpExerciseItineraryFacts,
  PartialRebookedItineraryDpDisruptionPolicyExercisedFacts,
  PartialRebookedItineraryDpRebookingCompleteBuyFacts,
  getCoverageAndPremiumFromDisruptionAncillaries,
  getTripSegmentsFromItinerarySegments,
  getPlusDaysFromItinerarySegments,
  getStopsFromSegments,
  getSlicesFromTravelItinerary,
  PartialPassengerDpExerciseFlightsListFacts,
  TripCategory,
  getPriceBucketValueFromDealness,
  DullesUta,
  CorpPriceQuoteData,
} from "redmond";
import {
  PassengerTripPricing,
  PersonCredits,
  PriceQuoteData,
  Prices,
} from "@b2bportal/air-booking-api";
import { IStoreState } from "../../../../reducers/types";
import {
  getAgentEmail,
  getEligibleRewardsAccountWithLargestValue,
  getRewardsAccounts,
  getRewardsAccountWithLargestValue,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import {
  priceFreezeQuoteDataSelector,
  estimatedPriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  passengerCountSelector,
  priceFreezePassengerCountsSelector,
  setUpFlightFreezeParamsCallStateSelector,
  currentPriceFreezeSelector,
  priceFreezeOfferCallStateSelector,
  generateCustomPriceFreezeOfferCallStateSelector,
  currentPriceFreezePriceDropProtectionSelector,
  isPriceFreezeDurationActiveSelector,
  changedPriceFreezeDurationPropertiesSelector,
  priceFreezeFareQuotePerPassengerTotalPricingSelector,
  priceFreezeFareQuoteErrorTitlesTypeSelector,
  priceFreezeFareQuoteAcknowledgedSelector,
  priceFreezeFareQuoteCallStateSelector,
  isPriceFreezeDurationPopupEnabledSelector,
  getMaxApplicableTravelWalletCreditAmount as getPriceFreezeMaxApplicableCreditAmount,
  getIsTravelWalletCreditPaymentOnly as getIsPriceFreezeTravelCreditPaymentOnly,
} from "../../../freeze/reducer";
import {
  currentPriceFreezeOfferFlightShopSelector,
  getViewedTripSummaryProperties,
  shopPricingInfoSelector,
  selectedTripDetailsSelector,
  selectedTripSelector,
  tripDetailsByIdSelector,
  fetchSimilarFlightsCallStateSelector,
  getSelectedFlightIndex,
  selectedCfarIdSelector,
  getCfarAttachProperties,
  selectedDisruptionProtectionIdSelector,
  selectedDisruptionProtectionOfferSelector,
  dpOfferPropertiesSelector,
  isDpOptionSelectionCompleteSelector,
  isDisruptionProtectionAvailableSelector,
  isCfarOptionSelectionCompleteSelector,
  isCfarAvailableSelector,
  selectedChfarIdSelector,
  isFlightBookWithAncillariesActiveSelector,
  hasSelectedRefundableFareSelector,
  flightsByFlightShopTypeSelector,
  predictionSelector,
  getFlightShopEntryPoint,
  selectedDiscountedCfarOfferPricesSelector,
  cfarDiscountPropertiesSelector,
  getAllowRewardsWithPolicy,
  getShopExperiments,
} from "../../../shop/reducer/selectors";
import {
  DO_NOT_APPLY_OPTION_KEY,
  ISelectedMulticityTrip,
  ISelectedTrip,
} from "../../../shop/reducer";
import {
  singleFlightItineraryPassengersSelector,
  singleFlightItineraryIdSelector,
  contextFromRebookSummarySelector,
  singleFlightItinerarySelector,
  singleFlightItineraryReturnSliceSelector,
  isSelectingReturnFlightSelector,
  dpExercisePassengerPropertiesSelector,
  isRefundableFaresEnabledSelector,
  getPriceDropRefundTypeSelector,
} from "../../../ancillary/reducer/selectors";
import * as textConstants from "./textConstants";
import { getErrorTitlesFromPaymentError } from "../utils/getErrorTitles";
import {
  getCfarFee,
  getDisruptionProtectionFee,
  getSubtotalWithAncillaries,
} from "../utils/pricingHelpers";
import { formatFiatValue } from "../../../../utils/currency";
import {
  Ancillary,
  AncillaryOpaqueValue,
  ErrorCode,
  Payment,
  PaymentError,
  PaymentOpaqueValue,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import { FlightError } from "@b2bportal/air-booking-api";
import { cloneDeep } from "lodash";
import queryStringParser from "query-string";
import { getTripCategory } from "../../../search/reducer";
import {
  getMulticityParamsFromTripDetails,
  getSliceIndex,
} from "../../../../utils/flights";
import { PriceFreezeExercise } from "redmond/apis/tysons/payment-machine";
import {
  AVAILABLE,
  CONTROL,
  CORP_HIDE_PRICE_DROP_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
} from "../../../../context/experiments";
import { getPotentialCrossSellOffers } from "../../../cross-sell/reducer/selectors";

export const getUserPassengers = (state: IStoreState) =>
  state.flightBook.userPassengers;

export const getUserSelectedPassengerIds = (state: IStoreState) =>
  state.flightBook.userSelectedPassengerIds;

export const getUserSelectedLapInfantIds = (state: IStoreState) =>
  state.flightBook.userSelectedLapInfantIds;

export const getUserPassengerCallState = (state: IStoreState) =>
  state.flightBook.userPassengerCallState;

export const getConfirmationEmail = (state: IStoreState) =>
  state.flightBook.confirmationEmailAddress;

export const getConfirmationPhoneNumber = (state: IStoreState) =>
  state.flightBook.confirmationPhoneNumber;

export const getUserTcpaConsentPhoneNumber = (state: IStoreState) =>
  state.flightBook.tcpaConsentPhoneNumber;

export const getPaymentMethods = (state: IStoreState) =>
  state.flightBook.paymentMethods;

export const getPaymentMethod = (state: IStoreState) =>
  state.flightBook.paymentMethod;

export const getSelectedPaymentMethodId = (state: IStoreState) =>
  state.flightBook.selectedPaymentMethodId;

export const getListPaymentMethodsCallState = (state: IStoreState) =>
  state.flightBook.listPaymentMethodCallState;

export const getDeletePaymentMethodCallState = (state: IStoreState) =>
  state.flightBook.deletePaymentMethodCallState;

export const getFetchPaymentMethodCallState = (state: IStoreState) =>
  state.flightBook.fetchPaymentMethodCallState;

export const getVerifyPaymentMethodCallState = (state: IStoreState) =>
  state.flightBook.verifyPaymentMethodCallState;

export const getSession = (state: IStoreState) => state.flightBook.session;

export const getSessionCallState = (state: IStoreState) =>
  state.flightBook.sessionCallState;

export const getPriceDifferenceAcknowledged = (state: IStoreState) =>
  state.flightBook.priceDifferenceAcknowledged;

export const getPriceQuote = (state: IStoreState) =>
  state.flightBook.priceQuote;

export const getPriceQuoteErrors = (state: IStoreState) =>
  state.flightBook.priceQuoteErrors;

export const getSchedulePriceQuoteError = (state: IStoreState) =>
  state.flightBook.schedulePriceQuoteError;

export const getSchedulePaymentError = (state: IStoreState) =>
  state.flightBook.schedulePaymentError;

export const getPollPriceQuoteCallState = (state: IStoreState) =>
  state.flightBook.pollPriceQuoteCallState;

export const getSchedulePriceQuoteCallState = (state: IStoreState) =>
  state.flightBook.schedulePriceQuoteCallState;

export const getInfantOnlyError = (state: IStoreState) =>
  state.flightBook.infantOnlyError;

export const getPassengersValid = (state: IStoreState) =>
  state.flightBook.passengersValid;

export const getPassengersValidErrors = (state: IStoreState) =>
  state.flightBook.passengerErrors;

export const getPassengersValidCallState = (state: IStoreState) =>
  state.flightBook.validatePassengersCallState;

export const getTripPricing = (state: IStoreState) =>
  state.flightBook.tripPricing;

export const getTripPricingErrors = (state: IStoreState) =>
  state.flightBook.tripPricingErrors;

export const getTripPricingCallState = (state: IStoreState) =>
  state.flightBook.tripPricingCallState;

export const getFinalizedItinerary = (state: IStoreState) =>
  state.flightBook.finalizedItinerary;

export const getFinalizedPriceFreeze = (state: IStoreState) =>
  state.flightBook.finalizedPriceFreeze;

export const getFinalizedPriceFreezeTripDetails = (state: IStoreState) =>
  state.flightBook.finalizedPriceFreezeTripDetails;

export const finalizedPriceFreezeFare = (state: IStoreState) =>
  state.flightBook.finalizedPriceFreezeFare;

export const getFinalizedUserEmail = (state: IStoreState) =>
  state.flightBook.finalizedUserEmail;

export const getFinalizedErrors = (state: IStoreState) =>
  state.flightBook.finalizedErrors;

export const getFinalizedCallState = (state: IStoreState) =>
  state.flightBook.finalizedCallState;

export const getChargeAgentBookingFeeCallState = (state: IStoreState) =>
  state.flightBook.chargeAgentBookingFeeCallState;

export const getFlightBookType = (state: IStoreState) =>
  state.flightBook.flightBookType;

export const getSetFlightBookQueryParamsCallState = (state: IStoreState) =>
  state.flightBook.setFlightBookQueryParamsCallState;

export const getFlightBookPriceDropProtection = (state: IStoreState) => {
  if (
    state.flightShop.experiments?.[CORP_HIDE_PRICE_DROP_EXPERIMENT] ===
    AVAILABLE
  ) {
    return null;
  }
  return state.flightBook.priceDropProtection;
};

export const getFetchPriceDropEligibilityCallState = (state: IStoreState) =>
  state.flightBook.fetchPriceDropEligibilityCallState;

export const getIsSimilarFlightsEnabled = (state: IStoreState) =>
  state.flightBook.isSimilarFlightsEnabled;

export const getBestOfferOverall = (state: IStoreState) =>
  state.flightBook.bestOfferOverall;

export const getOffers = (state: IStoreState) => state.flightBook.offers;

export const getOfferToApply = (state: IStoreState) =>
  state.flightBook.offerToApply;

export const getCredit = (state: IStoreState) => {
  const credit = state.flightBook.credit;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getCreditToApply = (state: IStoreState) => {
  const credit = state.flightBook.creditToApply;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getFlightFreezeCreditToApply = (state: IStoreState) => {
  const credit = state.flightFreeze.creditToApply;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getCorporateInfo = (state: IStoreState) =>
  state.flightRewards.corporateInfo;

export const getTravelWalletItemsToApply = createSelector(
  getOfferToApply,
  getCreditToApply,
  (offerToApply, creditToApply) => ({
    offerToApply,
    creditToApply,
  })
);

export const getTravelOfferProperties = createSelector(
  getBestOfferOverall,
  getOfferToApply,
  getOffers,
  (bestOverallOffer, offerToApply, offers): ITrackingProperties => ({
    properties: {
      ...bestOverallOffer?.trackingPropertiesV2?.properties,
      ...offerToApply?.trackingPropertiesV2?.properties,
      has_offer: !!bestOverallOffer,
      offer_count: offers?.length || 0,
    },
    encryptedProperties: [
      bestOverallOffer?.trackingPropertiesV2?.encryptedProperties ?? "",
      offerToApply?.trackingPropertiesV2?.encryptedProperties ?? "",
    ],
  })
);

export const getFetchApplicableTravelWalletItemsCallState = (
  state: IStoreState
) => state.flightBook.fetchApplicableTravelWalletItemsCallState;

export const getIsPriceFreezeExerciseEnabled = createSelector(
  getFlightBookType,
  (flightBookType) => {
    return flightBookType === FlightBookType.PRICE_FREEZE_EXERCISE;
  }
);

export const getConfirmationContactInfo = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  (email, phoneNumber) => {
    return {
      email: email ?? "",
      phoneNumber: phoneNumber ?? "",
    };
  }
);

export const getIsWaitingPriceQuote = createSelector(
  getPollPriceQuoteCallState,
  getSchedulePriceQuoteCallState,
  (pollPriceQuoteCallState, shedulePriceQuoteCallState) =>
    pollPriceQuoteCallState === CallState.InProcess ||
    shedulePriceQuoteCallState === CallState.InProcess
);

export const isRefetchingPriceQuoteSelector = createSelector(
  getPriceQuote,
  getTripPricing,
  getIsWaitingPriceQuote,
  isFlightBookWithAncillariesActiveSelector,
  (
    priceQuote,
    tripPricing,
    isWaitingPriceQuote,
    isFlightBookWithAncillariesActive
  ): boolean => {
    return (
      /*
        note: isFlightBookWithAncillariesActive is controlled by the feature flag for the https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting, and it can only return
        true when the flight-book variant is enabled; the condition `isWaitingPriceQuote && !!priceQuote` can only be satisfied on desktop,
        because it's created through using the `preserveQuote` option of scheduleQuote, which is only used in the ancillary selection component.
      */
      isFlightBookWithAncillariesActive &&
      isWaitingPriceQuote &&
      !!priceQuote &&
      !!tripPricing
    );
  }
);

/*
  note: in the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting, the persisted price quote needs to include ancillary options
  while refetching a new quote; by overwriting itinerary.sellingPricing with tripPricing, latest ancillary options will be included
*/
export const getPriceQuoteWithUpdatedAncillary = createSelector(
  getPriceQuote,
  getTripPricing,
  isRefetchingPriceQuoteSelector,
  (
    priceQuote,
    tripPricing,
    isRefetchingPriceQuote
  ): PriceQuoteData | CorpPriceQuoteData | null => {
    if (isRefetchingPriceQuote && !!priceQuote && !!tripPricing) {
      const priceQuoteWithUpdatedAncillary = cloneDeep(priceQuote);

      // handle pricingByPassenger
      const tripPricingPassengerMap = tripPricing.pricingByPassenger.reduce(
        (map, pricing) => {
          map[pricing.person.id] = pricing;
          return map;
        },
        {} as { [key in string]: PassengerTripPricing }
      );
      priceQuoteWithUpdatedAncillary.itinerary.sellingPricing.pricingByPassenger.forEach(
        (pricing) => {
          const id = pricing.person.id;
          // update the person in priceQuote with the same person in tripPrice
          if (tripPricingPassengerMap[id]) {
            pricing.ancillaries = tripPricingPassengerMap[id].ancillaries;
            pricing.total = getSubtotalWithAncillaries(
              pricing.subtotal,
              tripPricingPassengerMap[id].ancillaries
            );
          }
        }
      );

      // handle totalPricing
      priceQuoteWithUpdatedAncillary.itinerary.sellingPricing.totalPricing.ancillaries =
        tripPricing.totalPricing.ancillaries;
      priceQuoteWithUpdatedAncillary.itinerary.sellingPricing.totalPricing.total =
        getSubtotalWithAncillaries(
          priceQuoteWithUpdatedAncillary.itinerary.sellingPricing.totalPricing
            .subtotal,
          tripPricing.totalPricing.ancillaries
        );

      return priceQuoteWithUpdatedAncillary;
    } else {
      return priceQuote;
    }
  }
);

export const getSeatMapAvailability = createSelector(
  getPriceQuoteWithUpdatedAncillary,
  (priceQuote) => {
    return priceQuote?.seatMap &&
      priceQuote?.seatMap.SeatMapResponse ===
        SeatMapResponseEnum.SeatMapUnavailable
      ? false
      : priceQuote && !priceQuote.seatMap
      ? false
      : true;
  }
);

export const getSeatSelectionAvailability = createSelector(
  getPriceQuoteWithUpdatedAncillary,
  (priceQuote) => {
    return priceQuote?.seatMap?.SeatMapResponse;
  }
);

export const getSeatMapHtml = (state: IStoreState) => {
  return state.flightBook.seatMapHtml;
};

export const getCheapestSeat = (state: IStoreState) => {
  return state.flightBook.cheapestSeat;
};

export const getSeatMapLoading = (state: IStoreState) => {
  return state.flightBook.seatMapLoading;
};
export const getSkipSeatSelection = (state: IStoreState) => {
  return state.flightBook.skipSeatSelection;
};

export const getSelectedSeats = (state: IStoreState) => {
  return state.flightBook.selectedSeats;
};
export const getPriceDifference = createSelector(
  getPriceQuoteWithUpdatedAncillary,
  getTripPricing,
  getPriceDifferenceAcknowledged,
  isFlightBookWithAncillariesActiveSelector,
  (
    priceQuote,
    tripPricing,
    acknowledged,
    isFlightBookWithAncillariesActive
  ): {
    hasDifference: boolean;
    isIncrease: boolean;
    predictedTotal: number;
    priceQuoteTotal: number;
    amount?: string;
    amountPerTraveler?: string;
    currency?: string;
  } => {
    if (!priceQuote || !tripPricing || acknowledged) {
      return {
        hasDifference: false,
        isIncrease: false,
        predictedTotal: 0,
        priceQuoteTotal: 0,
      };
    }

    let predictedTotal: number;
    let priceQuoteTotal: number;
    let predictedTotalPerTraveler: number;
    let priceQuoteTotalPerTraveler: number;
    /*
      note: comparing total.fiat is incorrect in the ancillary attachment variant of the flights checkout flow, because when
      the ancillaries are included in the calculation, it causes the modal to show up whenever the user chooses to include any ancillary;
      it's the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting, which currently isn't active 
    */
    if (isFlightBookWithAncillariesActive) {
      predictedTotal = tripPricing.totalPricing.baseWithoutMargin.fiat.value;
      priceQuoteTotal =
        priceQuote.itinerary.sellingPricing.totalPricing.baseWithoutMargin.fiat
          .value;
      predictedTotalPerTraveler =
        tripPricing.pricingByPassenger[0]?.baseWithoutMargin.fiat.value;
      priceQuoteTotalPerTraveler =
        priceQuote.itinerary.sellingPricing.pricingByPassenger[0]
          ?.baseWithoutMargin.fiat.value;
    } else {
      predictedTotal = tripPricing.totalPricing.total.fiat.value;
      priceQuoteTotal =
        priceQuote.itinerary.sellingPricing.totalPricing.total.fiat.value;
      predictedTotalPerTraveler =
        tripPricing.pricingByPassenger[0]?.total.fiat.value;
      // note: pricingByPassenger.total does not seem to include ancillary prices - not sure if that's intended
      priceQuoteTotalPerTraveler = roundToTwoDecimals(
        priceQuote.itinerary.sellingPricing.pricingByPassenger[0]?.subtotal.fiat
          .value +
          priceQuote.itinerary.sellingPricing.pricingByPassenger[0]?.ancillaries.reduce(
            (sum, ancillary) => sum + ancillary.premium.fiat.value,
            0
          )
      );
    }

    const difference = Math.abs(priceQuoteTotal - predictedTotal);
    const amount = difference.toFixed();
    const amountPerTraveler = Math.abs(
      priceQuoteTotalPerTraveler - predictedTotalPerTraveler
    ).toFixed();
    const currency =
      priceQuote.itinerary.sellingPricing.totalPricing.total.fiat
        .currencySymbol;

    return {
      // If the difference is within a dollar we don't show the error modal
      hasDifference: difference >= 1,
      isIncrease: priceQuoteTotal > predictedTotal,
      predictedTotal,
      priceQuoteTotal,
      amount,
      amountPerTraveler,
      currency,
    };
  }
);

export const getRewardsConversionFailed = (state: IStoreState) =>
  state.flightBook.rewardsConversionFailed;

const getHasFlightBookError = createSelector(
  getUserPassengerCallState,
  getTripPricingCallState,
  getPassengersValidCallState,
  getFetchPriceDropEligibilityCallState,
  getFlightBookType,
  (
    callState,
    tripPricingError,
    passengerValidError,
    fetchPriceDropEligibilityCallState,
    flightBookType
  ): boolean => {
    let flightBookHasError: boolean;
    switch (flightBookType) {
      case FlightBookType.DEFAULT:
        flightBookHasError =
          callState === CallState.Failed ||
          tripPricingError === CallState.Failed ||
          passengerValidError === CallState.Failed ||
          fetchPriceDropEligibilityCallState === CallState.Failed;
        break;
      case FlightBookType.PRICE_FREEZE_EXERCISE:
        flightBookHasError =
          callState === CallState.Failed ||
          tripPricingError === CallState.Failed ||
          passengerValidError === CallState.Failed;
        break;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        flightBookHasError =
          fetchPriceDropEligibilityCallState === CallState.Failed;
        break;
      default:
        flightBookHasError = false;
        break;
    }
    return flightBookHasError;
  }
);

export const getHasFlightFreezeError = createSelector(
  priceFreezeOfferCallStateSelector,
  generateCustomPriceFreezeOfferCallStateSelector,
  priceFreezeFareQuoteCallStateSelector,
  priceFreezeFareQuoteErrorTitlesTypeSelector,
  priceFreezeFareQuoteAcknowledgedSelector,
  (
    priceFreezeOfferCallState,
    generateCustomPriceFreezeOfferCallState,
    priceFreezeFareQuoteCallState,
    priceFreezeFareQuoteErrorTitlesType,
    priceFreezeFareQuoteAcknowledged
  ): boolean => {
    return (
      priceFreezeOfferCallState === CallState.Failed ||
      generateCustomPriceFreezeOfferCallState === CallState.Failed ||
      priceFreezeFareQuoteCallState === CallState.Failed ||
      (priceFreezeFareQuoteErrorTitlesType !== null &&
        !priceFreezeFareQuoteAcknowledged)
    );
  }
);

export const getHasError = createSelector(
  getIsWaitingPriceQuote,
  getPollPriceQuoteCallState,
  getSchedulePaymentError,
  getSchedulePriceQuoteError,
  getPriceDifferenceAcknowledged,
  getPriceDifference,
  getFinalizedCallState,
  getVerifyPaymentMethodCallState,
  getHasFlightBookError,
  getSetFlightBookQueryParamsCallState,
  getInfantOnlyError,
  (
    isWaitingPriceQuote,
    priceQuoteError,
    schedulePaymentError,
    schedulePriceQuoteError,
    acknowledged,
    priceDifference,
    finalizedError,
    verifyPaymentError,
    hasFlightBookError,
    setFlightBookQueryParamsCallState,
    infantOnlyError
  ) => {
    return (
      !isWaitingPriceQuote &&
      ((priceDifference.hasDifference && !acknowledged) ||
        !!schedulePaymentError ||
        !!schedulePriceQuoteError ||
        verifyPaymentError === CallState.Failed ||
        finalizedError === CallState.Failed ||
        priceQuoteError === CallState.Failed ||
        setFlightBookQueryParamsCallState === CallState.Failed ||
        hasFlightBookError ||
        infantOnlyError)
    );
  }
);

export const getVerifyPaymentMethodResult = (state: IStoreState) =>
  state.flightBook.verifyPaymentMethodResult;

export const getIsMissingPassport = createSelector(
  getPriceQuoteErrors,
  (errors) =>
    errors.length > 0 &&
    !!errors.find(
      (e) =>
        e === FlightError.MissingPassport ||
        e === FlightError.MissingPassportNumber
    )
);

export const getIsInvalidPassport = createSelector(
  getPriceQuoteErrors,
  (errors) =>
    errors.length > 0 &&
    !!errors.find((e) => e === FlightError.InvalidPassportNumber)
);

export const getSchedulePaymentCallState = (state: IStoreState) =>
  state.flightBook.schedulePaymentCallState;

export const ancillaryIdsSelector = createSelector(
  selectedCfarIdSelector,
  selectedDisruptionProtectionOfferSelector,
  getFlightBookType,
  (cfarId, disruptionProtectionOffer, flightBookType) => {
    const ancillaryIds: ProtectionId[] = [];

    switch (flightBookType) {
      case FlightBookType.DEFAULT:
      case FlightBookType.PRICE_FREEZE_EXERCISE:
        if (cfarId && cfarId.policyId !== DO_NOT_APPLY_OPTION_KEY) {
          ancillaryIds.push({
            ...cfarId,
            ProtectionPolicyId: ProtectionPolicyIdEnum.CfarId,
            ProtectionId: ProtectionIdEnum.ProtectionPolicyId,
          });
        }
        if (
          disruptionProtectionOffer &&
          disruptionProtectionOffer.id.policyId !== DO_NOT_APPLY_OPTION_KEY
        ) {
          ancillaryIds.push({
            ...disruptionProtectionOffer.id,
            ProtectionPolicyId:
              disruptionProtectionOffer.AncillaryOffer ===
              AncillaryOfferEnum.DelayOffer
                ? ProtectionPolicyIdEnum.DelayId
                : ProtectionPolicyIdEnum.MissedConnectionId,
            ProtectionId: ProtectionIdEnum.ProtectionPolicyId,
          });
        }
        break;
      default:
        break;
    }

    return ancillaryIds;
  }
);

export const hasActiveRefundableFareInFlightBookSelector = createSelector(
  selectedCfarIdSelector,
  hasSelectedRefundableFareSelector,
  isRefundableFaresEnabledSelector,
  getFlightBookType,
  (
    selectedCfarId,
    hasSelectedRefundableFare,
    isRefundableFaresEnabled,
    flightBookType
  ): boolean =>
    !!selectedCfarId &&
    selectedCfarId.policyId !== DO_NOT_APPLY_OPTION_KEY &&
    hasSelectedRefundableFare &&
    isRefundableFaresEnabled &&
    flightBookType === FlightBookType.DEFAULT
);

export const pricingParamsSelector = createSelector(
  selectedTripSelector,
  getUserPassengers,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  currentPriceFreezeSelector,
  ancillaryIdsSelector,
  getFlightBookType,
  (
    selectedTrip,
    userPassengers,
    selectedTravelerIds,
    selectedLapInfants,
    priceFreeze,
    ancillaryIds,
    flightBookType
  ): PassengersForFareRequest => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
    const fareId =
      selectedOWRTrip.returnFareId ||
      selectedOWRTrip.outgoingFareId ||
      selectedMcTrip.departureFareId ||
      "";
    const defaultPricingParams = {
      tripId: selectedTrip.tripId || "",
      seatedPassengers: userPassengers
        .filter((p) => selectedTravelerIds.includes(p.id))
        .map((u) => u.id),
      lapInfants: userPassengers
        .filter((p) => selectedLapInfants.includes(p.id))
        .map((u) => u.id),
      fareId: fareId!!,
      ancillaryIds,
    };

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
        return {
          ...defaultPricingParams,
          priceFreezeId: priceFreeze?.priceFreeze.id,
        };
      case FlightBookType.DEFAULT:
      default:
        return defaultPricingParams;
    }
  }
);

export const getContactEmail = (state: IStoreState) =>
  state.flightBook.confirmationEmailAddress;

export const getContactPhone = (state: IStoreState) =>
  state.flightBook.confirmationPhoneNumber;

export const currentPriceDropProtectionSelector = createSelector(
  getFlightBookPriceDropProtection,
  currentPriceFreezePriceDropProtectionSelector,
  getFlightBookType,
  (
    priceDropProtection,
    priceFreezePriceDropProtection,
    flightBookType
  ): PriceDropProtection | null => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
        return priceFreezePriceDropProtection ?? null;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
      case FlightBookType.DEFAULT:
        return priceDropProtection;
      default:
        return null;
    }
  }
);

export const priceQuoteDisruptionProtectionRebookParamsSelector =
  createSelector(
    singleFlightItineraryPassengersSelector,
    singleFlightItineraryIdSelector,
    (passengers, itineraryId) => {
      return {
        passengers,
        itineraryId,
      };
    }
  );

export const priceQuoteParamsSelector = createSelector(
  selectedTripSelector,
  getUserPassengers,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  getContactEmail,
  getContactPhone,
  getAgentEmail,
  currentPriceDropProtectionSelector,
  ancillaryIdsSelector,
  currentPriceFreezeSelector,
  priceQuoteDisruptionProtectionRebookParamsSelector,
  getFlightBookType,
  (
    selectedTrip,
    userPassengers,
    selectedTravelerIds,
    selectedLapInfantIds,
    email,
    phone,
    agentEmail,
    priceDropProtection,
    ancillaryIds,
    currentPriceFreeze,
    disruptionProtectionRebookParams,
    flightBookType
  ): SchedulePriceQuoteRequest => {
    const { passengers: originalPassengers, itineraryId: originalItineraryId } =
      disruptionProtectionRebookParams;
    const getTripId = () => {
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
          return currentPriceFreeze?.bookingDetails.tripId ?? "";
        case FlightBookType.DEFAULT:
        default:
          return selectedTrip.tripId ?? "";
      }
    };
    const getFareId = () => {
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
          return currentPriceFreeze?.bookingDetails.fareId ?? "";
        case FlightBookType.DEFAULT:
        default:
          const selectedOWRTrip = selectedTrip as ISelectedTrip;
          const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
          return (
            selectedOWRTrip.returnFareId ||
            selectedOWRTrip.outgoingFareId ||
            selectedMcTrip.departureFareId ||
            ""
          );
      }
    };
    const getSeatedPassengers = () => {
      switch (flightBookType) {
        case FlightBookType.DISRUPTION_PROTECTION_EXERCISE:
          let originalPassengersSet = new Set<string>();
          originalPassengers?.alone.forEach((passenger) =>
            originalPassengersSet.add(passenger.person.id)
          );
          originalPassengers?.withLapInfants.forEach((entry) =>
            originalPassengersSet.add(entry.adult.person.id)
          );
          return Array.from(originalPassengersSet.values());
        case FlightBookType.DEFAULT:
        default:
          return userPassengers
            .filter((p) => selectedTravelerIds.includes(p.id))
            .map((u) => u.id);
      }
    };
    const getLapInfants = () => {
      switch (flightBookType) {
        case FlightBookType.DISRUPTION_PROTECTION_EXERCISE:
          return (
            originalPassengers?.withLapInfants.map(
              (infant) => infant.infant.person.id
            ) ?? []
          );
        case FlightBookType.DEFAULT:
        default:
          return userPassengers
            .filter((p) => selectedLapInfantIds.includes(p.id))
            .map((u) => u.id);
      }
    };
    const getEmailAddress = () => {
      switch (flightBookType) {
        case FlightBookType.DISRUPTION_PROTECTION_EXERCISE:
          return originalPassengers?.contact.contact.emailAddress ?? "";
        case FlightBookType.DEFAULT:
        default:
          return email ?? "";
      }
    };
    const getPhoneNumber = () => {
      switch (flightBookType) {
        case FlightBookType.DISRUPTION_PROTECTION_EXERCISE:
          return originalPassengers?.contact.contact.phoneNumber ?? "";
        case FlightBookType.DEFAULT:
        default:
          return phone ?? "";
      }
    };
    const getDisruptionProtection = () => {
      switch (flightBookType) {
        case FlightBookType.DISRUPTION_PROTECTION_EXERCISE:
          return {
            originalItineraryId: originalItineraryId ?? "",
          };
        case FlightBookType.DEFAULT:
        default:
          return undefined;
      }
    };

    const getPriceFreezeExercise: () =>
      | PriceFreezeExercise
      | undefined = () => {
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
          return currentPriceFreeze?.priceFreeze.id !== undefined
            ? {
                voucherId: currentPriceFreeze.priceFreeze.id,
              }
            : undefined;
        case FlightBookType.DEFAULT:
        default:
          return undefined;
      }
    };

    const candidateId = (priceDropProtection as IsEligible)?.candidateId;
    const params: SchedulePriceQuoteRequest = {
      tripId: getTripId(),
      seatedPassengers: getSeatedPassengers(),
      lapInfants: getLapInfants(),
      fareId: getFareId(),
      emailAddress: getEmailAddress(),
      phoneNumber: getPhoneNumber(),
      disruptionProtection: getDisruptionProtection(),
      priceFreezeExercise: getPriceFreezeExercise(),
      ancillaryIds,
    };
    if (candidateId) {
      params.priceDropCandidateId = candidateId;
    }
    if (agentEmail) {
      params.delegatedTo = agentEmail;
    }
    return params;
  }
);

export const bookingInProgressSelector = createSelector(
  getSchedulePaymentCallState,
  getFinalizedCallState,
  getIsWaitingPriceQuote,
  isRefetchingPriceQuoteSelector,
  getFlightBookType,
  (
    paymentCallState,
    finalizedCallState,
    isWaitingPriceQuote,
    isRefetchingPriceQuote,
    flightBookType
  ) => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_PURCHASE: {
        return (
          paymentCallState === CallState.InProcess ||
          finalizedCallState === CallState.InProcess
        );
      }
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
      default: {
        /*
          note: in the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting, price quote and payment callstates can be in-progress simultaneously;
          when it's fetching a price quote, it needs to be non-refetching to be considered booking in progress
        */
        return (
          paymentCallState === CallState.InProcess ||
          finalizedCallState === CallState.InProcess ||
          (isWaitingPriceQuote && !isRefetchingPriceQuote)
        );
      }
    }
  }
);

export const hasNoUserPassengersSelector = createSelector(
  getUserPassengers,
  getUserPassengerCallState,
  (userPassengers, userPassengerCallState) => {
    return (
      userPassengers.length === 0 &&
      userPassengerCallState === CallState.Success
    );
  }
);

// PF CHECKOUT SELECTORS

export const activePriceFreezeQuoteDataSelector = createSelector(
  estimatedPriceFreezeQuoteDataSelector,
  priceFreezeQuoteDataSelector,
  getIsWaitingPriceQuote,
  (estimatedQuoteData, quoteData, isWaitingPriceQuote) => {
    // note: when the price quote hasn't been called (or when it's still being fetched), use the estimated quote
    if (!quoteData || isWaitingPriceQuote) {
      return estimatedQuoteData;
    }
    return quoteData;
  }
);

export const priceFreezeFrozenPricingSelector = createSelector(
  activePriceFreezeQuoteDataSelector,
  (quoteData) => quoteData?.frozenPricing ?? null
);

export const priceFreezeSinglePassengerPricesSelector = createSelector(
  priceFreezeFareQuotePerPassengerTotalPricingSelector,
  priceFreezeFrozenPricingSelector,
  (
    fareQuotePerPassengerTotalPricing,
    frozenPricing
  ): LegacyPrices | undefined =>
    fareQuotePerPassengerTotalPricing ?? frozenPricing?.singlePassenger
);

export const priceFreezeSummaryFrozenPriceSelector = createSelector(
  selectedTripDetailsSelector,
  selectedTripSelector,
  priceFreezeSinglePassengerPricesSelector,
  (
    selectedTripDetails,
    selectedTrip,
    singlePassengerPrices
  ): LegacyPrices | null => {
    if (singlePassengerPrices) {
      return singlePassengerPrices;
    } else if (selectedTripDetails) {
      const selectedOWRTrip = selectedTrip as ISelectedTrip;
      const fare = selectedOWRTrip.returnFareId
        ? selectedOWRTrip.returnFareId
        : selectedOWRTrip.outgoingFareId;
      const fareDetails = selectedTripDetails.fareDetails.find(
        (f) => f.id === fare
      );

      // note: returning paxPricings[0].pricing.total as a fallback option for the frozen price
      return fareDetails?.paxPricings![0].pricing.total ?? null;
    }

    return null;
  }
);

export const priceFreezeOfferTotalSelector = createSelector(
  activePriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  (quoteData, offer): number | undefined => {
    // note: offer is immutable, so it doesn't respond to passenger count changes
    return quoteData?.totalAmount.fiat.value ?? offer?.totalAmount.fiat.value;
  }
);

export const priceFreezeOfferPerPaxSelector = createSelector(
  activePriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  (quoteData, offer): number | undefined => {
    return quoteData?.perPaxAmount.fiat.value ?? offer?.perPaxAmount.fiat.value;
  }
);

export const priceFreezeOfferRewardsPerPaxSelector = createSelector(
  activePriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  (quoteData, offer) => {
    return quoteData?.perPaxAmount.rewards ?? offer?.perPaxAmount.rewards;
  }
);

export const priceFreezeOfferDurationSelector = createSelector(
  currentPriceFreezeOfferSelector,
  (offer): TimeToLive | undefined => {
    return offer?.timeToLive;
  }
);

export const priceFreezeOfferCurrencySelector = createSelector(
  activePriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  (quoteData, offer): Omit<FiatPrice, "value"> => {
    return {
      currencySymbol:
        quoteData?.perPaxAmount.fiat.currencySymbol ??
        offer?.perPaxAmount.fiat.currencySymbol ??
        "",
      currencyCode:
        quoteData?.perPaxAmount.fiat.currencyCode ??
        offer?.perPaxAmount.fiat.currencyCode ??
        "",
    };
  }
);

export const priceFreezeOfferTotalFiatSelector = createSelector(
  priceFreezeOfferCurrencySelector,
  priceFreezeOfferTotalSelector,
  ({ currencySymbol }, value): string => {
    return `${currencySymbol}${formatFiatValue(value || 0)}`;
  }
);

export const priceFreezeOfferPerPaxFiatSelector = createSelector(
  priceFreezeOfferCurrencySelector,
  priceFreezeOfferPerPaxSelector,
  ({ currencySymbol }, value): string => {
    return `${currencySymbol}${formatFiatValue(value || 0)}`;
  }
);

// PAYMENT SELECTORS

export const getRewardsPaymentAccountReferenceId = (state: IStoreState) =>
  state.flightBook.rewardsAccountReferenceId;

export const getRewardsPaymentAccount = createSelector(
  getRewardsPaymentAccountReferenceId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getRewardsPaymentAccountIfRedemptionEnabled = createSelector(
  getRewardsPaymentAccount,
  getEligibleRewardsAccountWithLargestValue,
  (rewardsAccount, largestRewardsAccount) =>
    rewardsAccount?.allowRewardsRedemption ?? true
      ? rewardsAccount || largestRewardsAccount
      : undefined
);

export const getRewardsPaymentAccountReferenceIdIfRedemptionEnabled =
  createSelector(
    getRewardsPaymentAccountIfRedemptionEnabled,
    (rewardsAccount) => rewardsAccount?.accountReferenceId
  );

export const getUserSelectedPaymentMethod = createSelector(
  getPaymentMethods,
  getSelectedPaymentMethodId,
  (paymentMethods, selectedPaymentMethodId): CreditCard | undefined => {
    if (paymentMethods.length === 0 || !selectedPaymentMethodId) {
      return undefined;
    }

    return paymentMethods.find(
      (paymentMethod) => paymentMethod.id === selectedPaymentMethodId
    );
  }
);

export const getPassengerSeatMap = createSelector(
  getSelectedSeats,
  (seats): Map<string, number> => {
    const map = new Map();
    seats.forEach((seat) => {
      const total = map.get(seat.person_id);
      const seatPrice =
        seat.price_object.usd_total / 10 ** seat.price_object.decimal_places;
      if (total) {
        map.set(seat.person_id, total + seatPrice);
      } else {
        map.set(seat.person_id, seatPrice);
      }
    });
    return map;
  }
);

export const getSeatsTotalPricing = createSelector(
  getPassengerSeatMap,
  (passengerSeatMap) => {
    return Array.from(passengerSeatMap.values()).reduce(
      (currentTotal, seatTotal) => {
        return currentTotal + seatTotal;
      },
      0
    );
  }
);

export const getSeatsRewardsPricing = createSelector(
  getSelectedSeats,
  (seats): Array<{ [key: string]: RewardsPrice }> => {
    return seats.map((s) => s.rewards);
  }
);

export const getSeatsTotalRewardsPricing = createSelector(
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  getSeatsRewardsPricing,
  (
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue,
    seatsRewardsPricing
  ) => {
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    if (!activeRewardsAccount) return null;

    const { accountReferenceId } = activeRewardsAccount;

    if (seatsRewardsPricing.length > 0) {
      const seatsRewardsTotal = seatsRewardsPricing.reduce(
        (total: number, rewardsMap: { [key: string]: RewardsPrice }) => {
          const rewardsPrice = rewardsMap[accountReferenceId];
          if (rewardsPrice) {
            return total + rewardsPrice.value;
          } else {
            return total;
          }
        },
        0
      );
      const tripSeatsTotal: RewardsPrice = {
        value: seatsRewardsTotal,
        currency: seatsRewardsPricing[0][accountReferenceId].currency,
      };
      return tripSeatsTotal;
    } else {
      return null;
    }
  }
);

export const getFlightTotal = createSelector(
  getTripPricing,
  getPriceQuoteWithUpdatedAncillary,
  getSeatsTotalPricing,
  (tripPricing, priceQuote, seatsTotalPricing) => {
    return priceQuote && priceQuote?.itinerary.sellingPricing.totalPricing.total
      ? priceQuote?.itinerary.sellingPricing.totalPricing.total.fiat.value +
          seatsTotalPricing
      : tripPricing?.totalPricing?.total.fiat.value;
  }
);

export const getPricing = createSelector(
  getTripPricing,
  getPriceQuoteWithUpdatedAncillary,
  (tripPricing, priceQuote) => {
    return priceQuote?.itinerary.sellingPricing || tripPricing;
  }
);

export const getPaymentMethodRewardsAccountId = (state: IStoreState) =>
  state.flightBook.paymentMethodRewardsAccountId;

export const getCardPaymentRewardsAccount = createSelector(
  getPaymentMethodRewardsAccountId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getFlightTotalInRewards = createSelector(
  getRewardsPaymentAccountIfRedemptionEnabled,
  getRewardsAccountWithLargestValue,
  getPricing,
  getSeatsRewardsPricing,
  getCardPaymentRewardsAccount,
  (
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue,
    tripPricing,
    seatsRewardsPricing,
    cardPaymentRewardsAccount
  ): RewardsPrice | null => {
    const activeRewardsAccount =
      rewardsPaymentAccount ||
      cardPaymentRewardsAccount ||
      rewardsAccountWithLargestValue;

    if (
      !tripPricing ||
      !activeRewardsAccount ||
      !(activeRewardsAccount.allowRewardsRedemption ?? true)
    )
      return null;

    const { accountReferenceId } = activeRewardsAccount;
    const tripTotal =
      tripPricing?.totalPricing.total.accountSpecific[accountReferenceId];

    if (seatsRewardsPricing.length > 0) {
      const seatsRewardsTotal = seatsRewardsPricing.reduce(
        (total: number, rewardsMap: { [key: string]: RewardsPrice }) => {
          const rewardsPrice = rewardsMap[accountReferenceId];
          return total + rewardsPrice.value;
        },
        0
      );
      const tripSeatsTotal: RewardsPrice = {
        value: seatsRewardsTotal + tripTotal.value,
        currency: tripTotal.currency,
      };
      return tripSeatsTotal;
    } else {
      return tripTotal;
    }
  }
);

export const finalizedItineraryIdSelector = createSelector(
  getFinalizedItinerary,
  (finalizedItinerary) => finalizedItinerary?.id
);

export const finalizedRebookItineraryFlightSummaryDataSelector = createSelector(
  getFinalizedItinerary,
  contextFromRebookSummarySelector,
  (finalizedItinerary, context): IFlightSummaryData | undefined => {
    if (finalizedItinerary) {
      /*
        note: the BookedFlightItinerary type is duplicated in payment-machine.ts and itinerary.ts,
        and it's quite a pain to completely replace one of them in Redmond, because they each have so many dependencies
      */
      const { travelItinerary } = finalizedItinerary as unknown as BookedFlight;
      const { airlines: airlineMap = {}, airports: airportMap = {} } =
        context ?? {};

      // note: the rebooked itinerary is always a new itinerary with just a single slice
      // as we only allow rebooking one slice of the flight.
      const travelItinerarySlice = (() => {
        switch (travelItinerary.TravelItinerary) {
          case TravelItineraryEnum.SingleTravelItinerary:
            return travelItinerary.slices[0];
          case TravelItineraryEnum.MultiTravelItinerary:
            return travelItinerary.travelItineraries[0]?.slices[0];
          default:
            return undefined;
        }
      })();
      const segments = travelItinerarySlice?.segments ?? [];
      const fareShelf = travelItinerarySlice?.fareShelf;
      const tripSegments = getTripSegmentsFromItinerarySegments({
        segments,
        airportMap,
        airlineMap,
        useCityName: true,
      });
      const firstSegment = tripSegments[0];
      const lastSegment = tripSegments[tripSegments.length - 1];

      return {
        fareShelfBrandName: fareShelf?.brandName,
        fareShelfRating: fareShelf?.rating,
        fareShelfShortBrandName: fareShelf?.shortBrandName,
        tripSlice: {
          segments: tripSegments,
          cabinClassNames: segments.map((segment) => segment.cabinClassName),
          airlineCode: firstSegment?.airlineCode,
          airlineName: firstSegment?.airlineName,
          departureTime: firstSegment?.departureTime,
          arrivalTime: lastSegment?.arrivalTime,
          originName: `${firstSegment.originName} (${firstSegment.originCode})`,
          originCode: firstSegment?.originCode,
          destinationName: `${lastSegment?.destinationName} (${lastSegment?.destinationCode})`,
          destinationCode: lastSegment?.destinationCode,
          plusDays: getPlusDaysFromItinerarySegments(segments),
          stops: getStopsFromSegments(segments),
        },
      } as IFlightSummaryData;
    }

    return undefined;
  }
);

export const rebookedItineraryDpExerciseItineraryFactsPropertiesSelector =
  createSelector(
    getFinalizedItinerary,
    selectedTripDetailsSelector,
    singleFlightItinerarySelector,
    singleFlightItineraryReturnSliceSelector,
    isSelectingReturnFlightSelector,
    getPriceQuoteWithUpdatedAncillary,
    (
      finalizedItinerary,
      selectedTripDetails,
      originalItinerary,
      originalReturnSlice,
      isSelectingReturnFlight,
      priceQuote
    ): PartialRebookedItineraryDpExerciseItineraryFacts | undefined => {
      if (
        !!originalItinerary &&
        (!!finalizedItinerary || !!priceQuote || !!selectedTripDetails)
      ) {
        // finalized payment data
        const finalizedTravelItinerary = finalizedItinerary?.travelItinerary;
        const finalizedDepartureSlice = getSlicesFromTravelItinerary(
          finalizedTravelItinerary as any
        )?.[0];
        const finalizedFirstDepartureSegment =
          finalizedDepartureSlice?.segments[0];
        const finalizedLastDepartureSegment =
          finalizedDepartureSlice?.segments[
            finalizedDepartureSlice.segments.length - 1
          ];
        const finalizedTotalPrice =
          finalizedItinerary?.sellingPricing.totalPricing.total.fiat.value;
        // rebookSummary data
        const originalPricingByPassenger =
          originalItinerary.itinerary.bookedItinerary.sellingPricing
            .pricingByPassenger[0];
        const originalTotalPricing =
          originalItinerary.itinerary.bookedItinerary.sellingPricing
            .totalPricing;
        const totalPricing = priceQuote?.itinerary.sellingPricing.totalPricing;

        return {
          price_per_pax_usd:
            // note: pricingByPassenger.total does not seem to include ancillary prices - not sure if that's intended
            roundToTwoDecimals(
              originalPricingByPassenger.subtotal.fiat.value +
                originalPricingByPassenger.ancillaries.reduce(
                  (sum, ancillary) => sum + ancillary.premium.fiat.value,
                  0
                )
            ),
          rebooking_direction: isSelectingReturnFlight ? "return" : "outbound",
          rebooking_flow: "disruption",
          rebooking_tool: true,
          subtotal_usd: originalTotalPricing.subtotal.fiat.value,
          total_price: originalTotalPricing.total.fiat.value,
          total_price_usd: originalTotalPricing.total.fiat.value,
          trip_type: !!originalReturnSlice ? "round_trip" : "one_way",
          disruption_rebook_costs:
            finalizedTotalPrice ?? totalPricing?.total.fiat.value,
          rebook_origin: finalizedFirstDepartureSegment?.origin.locationCode,
          rebook_destination:
            finalizedLastDepartureSegment?.destination.locationCode,
        };
      }

      return undefined;
    }
  );

export const rebookedItineraryDpDisruptionPolicyExercisedFactsPropertiesSelector =
  createSelector(
    getFinalizedItinerary,
    singleFlightItinerarySelector,
    dpExercisePassengerPropertiesSelector,
    (
      finalizedItinerary,
      originalItinerary,
      passengerProperties
    ): PartialRebookedItineraryDpDisruptionPolicyExercisedFacts &
      PartialPassengerDpExerciseFlightsListFacts => {
      // finalized payment data
      const finalizedPassengers = finalizedItinerary?.passengers;
      const finalizedPassengersCount = (() => {
        if (!finalizedPassengers) {
          return undefined;
        }

        let originalPassengersSet = new Set<string>();
        finalizedPassengers.alone.forEach((passenger) =>
          originalPassengersSet.add(passenger.person.id)
        );
        finalizedPassengers.withLapInfants.forEach((entry) => {
          originalPassengersSet.add(entry.adult.person.id);
          originalPassengersSet.add(entry.infant.person.id);
        });
        return originalPassengersSet.size;
      })();
      const finalizedLocators = finalizedItinerary?.travelItinerary.locators;
      // rebookSummary data
      const originalTotalPricing =
        originalItinerary?.itinerary.bookedItinerary.sellingPricing
          .totalPricing;
      const originalPricingByPassenger =
        originalItinerary?.itinerary.bookedItinerary.sellingPricing
          .pricingByPassenger[0];
      const originalLocators =
        originalItinerary?.itinerary.bookedItinerary.travelItinerary.locators;
      const ancillaries = originalItinerary?.itinerary.ancillaries;
      const delay = ancillaries?.delay;
      const missedConnection = ancillaries?.missedConnection;

      return {
        pax_total:
          finalizedPassengersCount ?? passengerProperties?.searched_pax_total,
        ...passengerProperties,
        exercise_choice: "rebook",
        base_total_usd: originalTotalPricing?.baseWithoutMargin.fiat.value,
        disruption_new_system_locator: finalizedLocators?.b2b,
        disruption_original_agent_locator:
          originalLocators?.agent.unscopedValue,
        disruption_original_system_locator: originalLocators?.b2b,
        disruption_original_trip_cost:
          originalPricingByPassenger?.subtotal.fiat.value,
        disruption_policy_id:
          delay?.id.policyId ?? missedConnection?.id.policyId,
        disruption_premium: ancillaries
          ? getCoverageAndPremiumFromDisruptionAncillaries(ancillaries)?.premium
              .amount
          : undefined,
        disruption_product_id:
          delay?.id.productId ?? missedConnection?.id.productId,
      };
    }
  );

export const rebookedItineraryDpRebookingCompleteBuyFactsPropertiesSelector =
  createSelector(
    getFinalizedItinerary,
    isSelectingReturnFlightSelector,
    selectedTripDetailsSelector,
    (
      finalizedItinerary,
      isSelectingReturnFlight,
      selectedTripDetails
    ): PartialRebookedItineraryDpRebookingCompleteBuyFacts => {
      // finalized payment data
      const finalizedTravelItinerary = finalizedItinerary?.travelItinerary;
      const finalizedDepartureSlice = getSlicesFromTravelItinerary(
        finalizedTravelItinerary as any
      )?.[0];
      const finalizedFirstDepartureSegment =
        finalizedDepartureSlice?.segments[0];
      const finalizedLastDepartureSegment =
        finalizedDepartureSlice?.segments[
          finalizedDepartureSlice.segments.length - 1
        ];
      const finalizedDepartureDate =
        finalizedFirstDepartureSegment?.zonedUpdatedDeparture ??
        finalizedFirstDepartureSegment?.zonedScheduledDeparture ??
        finalizedFirstDepartureSegment?.updatedDeparture ??
        finalizedFirstDepartureSegment?.scheduledDeparture;
      const finalizedCarrier =
        finalizedFirstDepartureSegment?.marketingAirline.code;
      const finalizedOrigin =
        finalizedFirstDepartureSegment?.origin.locationCode;
      const finalizedDestination =
        finalizedLastDepartureSegment?.destination.locationCode;
      const finalizedSegmentsLength = finalizedDepartureSlice?.segments.length;
      // rebookSummary data
      // We only allow rebooking a single slice of a flight, so we can assume slices only contains one slice.
      const departureSlice = selectedTripDetails?.slices[0];
      const departureDate =
        departureSlice?.zonedDepartureTime ?? departureSlice?.departureTime;
      const carrier = departureSlice?.segmentDetails[0]?.marketingAirline.code;
      const origin = departureSlice?.originCode;
      const destination = departureSlice?.destinationCode;
      // active
      const segments =
        finalizedSegmentsLength ?? departureSlice?.segmentDetails.length;
      const stops = finalizedSegmentsLength
        ? finalizedSegmentsLength - 1
        : departureSlice?.stops;

      return {
        advance: dayjs(finalizedDepartureDate ?? departureDate).diff(
          dayjs(),
          "day"
        ),
        carrier: finalizedCarrier ?? carrier,
        rebook_origin: finalizedOrigin ?? origin,
        rebook_destination: finalizedDestination ?? destination,
        ...(isSelectingReturnFlight
          ? {
              return_segments: segments,
              return_stops: stops,
            }
          : {
              outbound_segments: segments,
              outbound_stops: stops,
            }),
      };
    }
  );

export const getRewardsPaymentInFiatCurrency = (state: IStoreState) =>
  state.flightBook.rewardsPaymentInFiatCurrency;

export const getRewardsPaymentInRewardsCurrency = (state: IStoreState) =>
  state.flightBook.rewardsPaymentTotal;

export const getRewardsRemainingAfterPurchase = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInFiatCurrency,
  (paymentAccount, rewardsPaymentAmount) => {
    if (!paymentAccount) return "";

    const {
      rewardsCashEquivalent: { value, currencyCode, currencySymbol },
    } = paymentAccount;

    const remainder = value - (rewardsPaymentAmount?.value || 0);

    return getTotalPriceText({
      price: {
        currencyCode,
        currencySymbol,
        value: remainder,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getPaymentTotalInPrices = createSelector(
  getPriceQuoteWithUpdatedAncillary,
  getTripPricing,
  currentPriceFreezeOfferSelector,
  currentPriceFreezeOfferFlightShopSelector,
  activePriceFreezeQuoteDataSelector,
  getFlightBookType,
  getSeatsTotalPricing,
  getSeatsRewardsPricing,
  getTravelWalletItemsToApply,
  (
    flightPriceQuote,
    tripPricing,
    offer,
    shopOffer,
    priceFreezeQuote,
    flightBookType,
    seatsTotal,
    seatsRewardsPricing,
    travelWalletItemsToApply
  ): Prices | undefined => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        let walletItemsTotal: number = 0;
        if (
          travelWalletItemsToApply.offerToApply ||
          travelWalletItemsToApply.creditToApply
        ) {
          walletItemsTotal =
            travelWalletItemsToApply.offerToApply?.amount.amount || 0;
          if (travelWalletItemsToApply.creditToApply) {
            const maxApplicableCredit =
              travelWalletItemsToApply.offerToApply?.maxApplicableCredit
                ?.amount;
            if (
              maxApplicableCredit &&
              maxApplicableCredit >
                travelWalletItemsToApply.creditToApply.amount.amount
            ) {
              walletItemsTotal += maxApplicableCredit;
            } else {
              walletItemsTotal +=
                travelWalletItemsToApply.creditToApply.amount.amount;
            }
          }
        }
        if (flightPriceQuote) {
          const tripTotal =
            flightPriceQuote.itinerary.sellingPricing.totalPricing.total;
          const rewardsPricingMap = {};

          Object.keys(tripTotal.accountSpecific).forEach((accountId) => {
            const seatTotal = seatsRewardsPricing.reduce(
              (total, seatRewardsPricing) => {
                return total + seatRewardsPricing[accountId].value;
              },
              0
            );
            rewardsPricingMap[accountId] = {
              ...tripTotal.accountSpecific[accountId],
              value: seatTotal + tripTotal.accountSpecific[accountId].value,
            };

            if (
              travelWalletItemsToApply.offerToApply ||
              travelWalletItemsToApply.creditToApply
            ) {
              let rewardTotalValue = rewardsPricingMap[accountId].value;
              let rewardTotalWithWalletItem =
                tripTotal.accountSpecific[accountId].currency === "Cash"
                  ? rewardTotalValue + walletItemsTotal
                  : rewardTotalValue + walletItemsTotal * 100;
              rewardTotalValue =
                rewardTotalWithWalletItem > 0 ? rewardTotalWithWalletItem : 0;
              rewardsPricingMap[accountId] = {
                ...tripTotal.accountSpecific[accountId],
                value: rewardTotalValue,
              };
            }
          });
          if (
            travelWalletItemsToApply.offerToApply ||
            travelWalletItemsToApply.creditToApply
          ) {
            return {
              fiat: {
                ...tripTotal.fiat,
                value:
                  tripTotal.fiat.value + seatsTotal + walletItemsTotal > 0
                    ? tripTotal.fiat.value + seatsTotal + walletItemsTotal
                    : 0,
              },
              accountSpecific: rewardsPricingMap,
            };
          }
          return {
            fiat: {
              ...tripTotal.fiat,
              value: tripTotal.fiat.value + seatsTotal,
            },
            accountSpecific: rewardsPricingMap,
          };
        } else {
          if (
            travelWalletItemsToApply.offerToApply ||
            travelWalletItemsToApply.creditToApply
          ) {
            const tripTotal = tripPricing?.totalPricing.total;
            const rewardsPricingMap = {};

            if (tripTotal) {
              Object.keys(tripTotal.accountSpecific).forEach((accountId) => {
                if (
                  travelWalletItemsToApply.offerToApply ||
                  travelWalletItemsToApply.creditToApply
                ) {
                  let rewardTotalValue =
                    tripTotal.accountSpecific[accountId].value;
                  rewardTotalValue =
                    tripTotal.accountSpecific[accountId].currency === "Cash"
                      ? rewardTotalValue + walletItemsTotal
                      : rewardTotalValue + walletItemsTotal * 100;
                  rewardsPricingMap[accountId] = {
                    ...tripTotal.accountSpecific[accountId],
                    value: rewardTotalValue,
                  };
                }
              });
              return {
                fiat: {
                  ...tripTotal.fiat,
                  value:
                    tripTotal.fiat.value + walletItemsTotal > 0
                      ? tripTotal.fiat.value + walletItemsTotal
                      : 0,
                },
                accountSpecific: rewardsPricingMap,
              };
            }
          }
          return tripPricing?.totalPricing.total;
        }
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        const legacyPrices =
          priceFreezeQuote?.totalAmount ??
          offer?.totalAmount ??
          shopOffer?.totalAmount;
        return {
          fiat: legacyPrices?.fiat,
          accountSpecific: legacyPrices?.rewards,
        } as Prices;
      default:
        return undefined;
    }
  }
);

// TODO: this value should come from the backend
// note: it's needed when no priceQuote has been requested yet

export const getPricingInfoFlightTotalInPrices = createSelector(
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  shopPricingInfoSelector,
  currentPriceFreezeSelector,
  getFlightBookType,
  (
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue,
    pricingInfo,
    priceFreeze,
    flightBookType
  ): { fiat: FiatPrice; rewards: RewardsPrice } | null => {
    const activeRewardsPaymentAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    if (!activeRewardsPaymentAccount) {
      return null;
    }
    const { accountReferenceId } = activeRewardsPaymentAccount;

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
        if (!priceFreeze) {
          return null;
        }
        // note: on start, when none of passengers is selected, it will display the charge price for 'Total'
        const frozenPricing =
          priceFreeze.frozenFare.paxPricings[0].pricing.chargeAmount;

        return {
          fiat: frozenPricing.fiat,
          // the fallback option should never happen unless accountReferenceId cannot be found
          rewards: frozenPricing.rewards[accountReferenceId] ?? {
            value: 0,
            currency: activeRewardsPaymentAccount.rewardsBalance.currency,
          },
        };

      case FlightBookType.DEFAULT:
      default:
        if (!pricingInfo.fare || pricingInfo.fare.length === 0) {
          return null;
        }
        const farePricing = pricingInfo.fare[0].pricing;

        const fiatBaseAmount = farePricing.baseAmount.fiat.value || 0;
        const fiatTaxAmount = farePricing.taxAmount.fiat.value || 0;
        const fiatAdditionalMargin = farePricing.additionalMargin
          ? farePricing.additionalMargin.fiat.value
          : 0;

        const rewardsBaseAmount =
          farePricing.baseAmount.rewards[accountReferenceId]?.value ?? 0;
        const rewardsTaxAmount =
          farePricing.taxAmount.rewards[accountReferenceId]?.value ?? 0;
        const rewardsAdditionalMargin = farePricing.additionalMargin
          ? farePricing.additionalMargin.rewards[accountReferenceId].value
          : 0;

        const fiatTotal = fiatBaseAmount + fiatTaxAmount + fiatAdditionalMargin;
        const rewardsTotal =
          rewardsBaseAmount + rewardsTaxAmount + rewardsAdditionalMargin;

        return {
          fiat: {
            currencyCode: farePricing.baseAmount.fiat.currencyCode,
            currencySymbol: farePricing.baseAmount.fiat.currencySymbol,
            value: fiatTotal,
          },
          rewards: {
            value: rewardsTotal,
            currency:
              activeRewardsPaymentAccount.rewardsBalance.currencyDescription ||
              activeRewardsPaymentAccount.rewardsBalance.currency,
          },
        };
    }
  }
);

export const getIsCreditCardPaymentRequired = createSelector(
  getFlightTotal,
  priceFreezeOfferTotalSelector,
  getRewardsPaymentInFiatCurrency,
  getFlightBookType,
  getPricingInfoFlightTotalInPrices,
  getPaymentTotalInPrices,
  getTravelWalletItemsToApply,
  (
    flightTotal,
    offerTotal,
    rewardsPaymentAmount,
    flightBookType,
    pricingInfoFlightTotalInPrices,
    paymentTotal,
    travelWalletItemsToApply
  ) => {
    let total: number | undefined = undefined;
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        if (flightTotal) {
          total = paymentTotal?.fiat.value;
        } else {
          // flightTotal is 0 until a pax is selected so we need pricing from pricingInfo selector
          total = pricingInfoFlightTotalInPrices?.fiat.value;
          if (
            (travelWalletItemsToApply.offerToApply ||
              travelWalletItemsToApply.creditToApply) &&
            pricingInfoFlightTotalInPrices
          ) {
            const offerToApplyAmount =
              travelWalletItemsToApply.offerToApply?.amount.amount || 0;
            const creditToApplyAmount =
              travelWalletItemsToApply.creditToApply?.amount.amount || 0;
            const totalWithWalletItem =
              pricingInfoFlightTotalInPrices?.fiat.value +
              offerToApplyAmount +
              creditToApplyAmount;
            total = totalWithWalletItem > 0 ? totalWithWalletItem : 0;
          }
        }
        break;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        total = offerTotal;
        break;
      default:
        break;
    }

    if (!total) return true;
    if (!rewardsPaymentAmount) return true;

    return total > rewardsPaymentAmount.value;
  }
);

export const currencySelector = createSelector(
  shopPricingInfoSelector,
  priceFreezeOfferCurrencySelector,
  getFlightBookType,
  (
    pricingInfo,
    priceFreezeCurrency,
    flightBookType
  ): {
    currencyCode: string;
    currencySymbol: string;
  } => {
    // TODO: as Mer has noted, we should remove hard coded currency values
    let currency = {
      currencyCode: Currency.USD,
      currencySymbol: "$",
    };

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        if (pricingInfo.fare && pricingInfo.fare.length > 0) {
          currency = {
            currencyCode: pricingInfo.fare[0].pricing.baseAmount.fiat
              .currencyCode as Currency,
            currencySymbol:
              pricingInfo.fare[0].pricing.baseAmount.fiat.currencySymbol,
          };
        }
        break;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        if (
          priceFreezeCurrency.currencyCode &&
          priceFreezeCurrency.currencySymbol
        ) {
          currency = {
            currencyCode: priceFreezeCurrency.currencyCode as Currency,
            currencySymbol: priceFreezeCurrency.currencySymbol,
          };
        }
        break;
      default:
        break;
    }

    return currency;
  }
);

export const getTotalCreditCardPaymentRequiredInFiatPrice = createSelector(
  getFlightTotal,
  priceFreezeOfferTotalSelector,
  getRewardsPaymentInFiatCurrency,
  currencySelector,
  getFlightBookType,
  getPricingInfoFlightTotalInPrices,
  getTravelWalletItemsToApply,
  getPaymentTotalInPrices,
  getPriceFreezeMaxApplicableCreditAmount,
  getFlightFreezeCreditToApply,
  (
    flightTotal,
    offerTotal,
    rewardsPaymentAmount,
    currency,
    flightBookType,
    pricingInfoFlightTotalInPrices,
    travelWalletItemsToApply,
    paymentTotal,
    priceFreezeCreditToApply,
    priceFreezeMaxCredit
  ): FiatPrice => {
    let total: number | undefined;

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        if (flightTotal) {
          total = paymentTotal?.fiat.value;
        } else {
          // flightTotal is 0 until a pax is selected so we need pricing from pricingInfo selector
          total = pricingInfoFlightTotalInPrices?.fiat.value;
          if (
            (travelWalletItemsToApply.offerToApply ||
              travelWalletItemsToApply.creditToApply) &&
            pricingInfoFlightTotalInPrices
          ) {
            const offerToApplyAmount =
              travelWalletItemsToApply.offerToApply?.amount.amount || 0;
            const creditToApplyAmount =
              travelWalletItemsToApply.creditToApply?.amount.amount || 0;
            const totalWithWalletItem =
              pricingInfoFlightTotalInPrices?.fiat.value +
              offerToApplyAmount +
              creditToApplyAmount;
            total = totalWithWalletItem > 0 ? totalWithWalletItem : 0;
          }
        }

        break;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        if (
          !!priceFreezeCreditToApply &&
          !!offerTotal &&
          !!priceFreezeMaxCredit
        ) {
          total =
            offerTotal -
            Math.min(
              Math.abs(priceFreezeMaxCredit.amount.amount),
              Math.abs(priceFreezeCreditToApply)
            );
          break;
        }

        total = offerTotal;
        break;
      default:
        break;
    }

    if (!total) {
      return {
        ...currency,
        value: 0,
      };
    }

    if (!rewardsPaymentAmount) {
      return {
        ...currency,
        value: total,
      };
    }
    const remainder = total - rewardsPaymentAmount.value;
    const final = remainder <= 0 ? 0 : remainder;
    return {
      ...currency,
      value: final,
    };
  }
);

export const getTotalCreditCardPaymentRequired = createSelector(
  getTotalCreditCardPaymentRequiredInFiatPrice,
  (totalCreditCardPaymentRequired): string => {
    if (totalCreditCardPaymentRequired.value === 0) return "";

    return getTotalPriceText({
      price: totalCreditCardPaymentRequired,
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getTotalCreditCardPaymentRequiredNumber = createSelector(
  getTotalCreditCardPaymentRequiredInFiatPrice,
  (totalCreditCardPaymentRequired): number | null => {
    if (totalCreditCardPaymentRequired.value === 0) return null;

    return totalCreditCardPaymentRequired.value;
  }
);

export const getCreditsByPerson = createSelector(
  getPriceQuoteWithUpdatedAncillary,
  (priceQuote): PersonCredits[] | null => {
    return priceQuote?.creditSummary?.credits.creditsByPerson ?? null;
  }
);

export interface ILineItemProps {
  lineTitle?: string;
  baseAmount?: number;
  taxesAndFees?: number;
  seats?: number;
  frozenPrice?: boolean;
  cfarFee?: number;
  origCfarFee?: number;
  icon?: IconName;
  isTravelOffer?: boolean;
  label?: string;
  disruptionProtectionFee?: number;
}

export const getTravelWalletOfferToApplyAmount = createSelector(
  getTravelWalletItemsToApply,
  getTripPricing,
  getPriceQuoteWithUpdatedAncillary,
  getSeatsTotalPricing,
  getPricingInfoFlightTotalInPrices,
  (
    travelWalletItemsToApply,
    tripPricing,
    priceQuote,
    seatsTotalPricing,
    pricingInfo
  ) => {
    let totalBeforeWalletItem =
      priceQuote && priceQuote?.itinerary.sellingPricing.totalPricing.total
        ? priceQuote?.itinerary.sellingPricing.totalPricing.total.fiat.value +
          seatsTotalPricing
        : tripPricing?.totalPricing?.total.fiat.value
        ? tripPricing?.totalPricing?.total.fiat.value
        : pricingInfo?.fiat.value || 0;
    if (travelWalletItemsToApply.offerToApply) {
      let totalAfterOffer =
        totalBeforeWalletItem +
        travelWalletItemsToApply.offerToApply.amount.amount;
      return totalAfterOffer < 0
        ? totalBeforeWalletItem
        : travelWalletItemsToApply.offerToApply.amount.amount * -1;
    }
    return 0;
  }
);

export const getTravelWalletCreditToApplyAmount = createSelector(
  getTripPricing,
  getPriceQuoteWithUpdatedAncillary,
  getSeatsTotalPricing,
  getTravelWalletItemsToApply,
  getPricingInfoFlightTotalInPrices,
  getTravelWalletOfferToApplyAmount,
  (
    tripPricing,
    priceQuote,
    seatsTotalPricing,
    travelWalletItemsToApply,
    pricingInfo,
    offerAppliedAmount
  ) => {
    let totalBeforeWalletItems =
      priceQuote && priceQuote?.itinerary.sellingPricing.totalPricing.total
        ? priceQuote?.itinerary.sellingPricing.totalPricing.total.fiat.value +
          seatsTotalPricing
        : tripPricing?.totalPricing?.total.fiat.value
        ? tripPricing?.totalPricing?.total.fiat.value
        : pricingInfo?.fiat.value || 0;
    const totalFiatValueWithOfferApplied: number =
      totalBeforeWalletItems - offerAppliedAmount;
    if (
      travelWalletItemsToApply.creditToApply &&
      totalFiatValueWithOfferApplied > 0
    ) {
      const maxApplicableCredit =
        travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;

      let amount =
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
          ? maxApplicableCredit
          : travelWalletItemsToApply.creditToApply.amount.amount;
      if (totalFiatValueWithOfferApplied < amount * -1) {
        if (
          maxApplicableCredit &&
          maxApplicableCredit <
            travelWalletItemsToApply.creditToApply.amount.amount &&
          travelWalletItemsToApply.creditToApply.amount.amount * -1 <
            totalFiatValueWithOfferApplied
        ) {
          return travelWalletItemsToApply.creditToApply.amount.amount * -1;
        }
        return totalFiatValueWithOfferApplied;
      }
      if (
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
      ) {
        return travelWalletItemsToApply.creditToApply.amount.amount * -1;
      }
      return amount * -1;
    } else {
      return 0;
    }
  }
);

export const getMaxApplicableTravelWalletCreditOnlyAmount = createSelector(
  getTripPricing,
  getPriceQuoteWithUpdatedAncillary,
  getSeatsTotalPricing,
  getCredit,
  getPricingInfoFlightTotalInPrices,
  (tripPricing, priceQuote, seatsTotalPricing, credit, pricingInfo) => {
    let totalBeforeWalletItem: number =
      priceQuote && priceQuote?.itinerary.sellingPricing.totalPricing.total
        ? priceQuote?.itinerary.sellingPricing.totalPricing.total.fiat.value +
          seatsTotalPricing
        : tripPricing?.totalPricing?.total.fiat.value
        ? tripPricing?.totalPricing?.total.fiat.value
        : pricingInfo?.fiat.value || 0;
    if (credit && totalBeforeWalletItem) {
      let totalAfterOffer = totalBeforeWalletItem + credit?.amount.amount;
      return totalAfterOffer < 0
        ? totalBeforeWalletItem * -1
        : credit.amount.amount;
    }
    return 0;
  }
);

export const getMaxApplicableTravelWalletCreditAmount = createSelector(
  getTripPricing,
  getPriceQuoteWithUpdatedAncillary,
  getSeatsTotalPricing,
  getCredit,
  getPricingInfoFlightTotalInPrices,
  getTravelWalletOfferToApplyAmount,
  (
    tripPricing,
    priceQuote,
    seatsTotalPricing,
    credit,
    pricingInfo,
    offerAppliedAmount
  ) => {
    let totalBeforeWalletItem: number =
      priceQuote && priceQuote?.itinerary.sellingPricing.totalPricing.total
        ? priceQuote?.itinerary.sellingPricing.totalPricing.total.fiat.value +
          seatsTotalPricing
        : tripPricing?.totalPricing?.total.fiat.value
        ? tripPricing?.totalPricing?.total.fiat.value
        : pricingInfo?.fiat.value || 0;
    if (credit && totalBeforeWalletItem) {
      let totalAfterOffer =
        totalBeforeWalletItem + credit?.amount.amount - offerAppliedAmount;
      return totalAfterOffer < 0
        ? (totalBeforeWalletItem - offerAppliedAmount) * -1
        : credit.amount.amount;
    }
    return 0;
  }
);

export const getCompleteBuyTravelOfferProperties = createSelector(
  getTravelOfferProperties,
  getOfferToApply,
  getTravelWalletOfferToApplyAmount,
  (
    travelOfferProperties,
    offerToApply,
    offerToApplyAmount
  ): ITrackingProperties => ({
    properties: {
      ...travelOfferProperties.properties,
      offer_used: !!offerToApply,
      offer_amount_redeemed_usd:
        !!offerToApply && !!offerToApplyAmount
          ? Math.abs(offerToApplyAmount)
          : 0,
    },
    encryptedProperties: [...travelOfferProperties.encryptedProperties],
  })
);

export const priceQuoteLineItemsSelector = createSelector(
  getPricing,
  shopPricingInfoSelector,
  getPassengerSeatMap,
  getCreditsByPerson,
  getIsPriceFreezeExerciseEnabled,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  getUserPassengers,
  selectedDiscountedCfarOfferPricesSelector,
  (
    tripPricing,
    shopPricingInfo,
    seatMap,
    creditsByPerson,
    isPriceFreezeExerciseEnabled,
    passengersIds,
    infantIds,
    passengersInfo,
    discountedCfar
  ): ILineItemProps[] => {
    let lineItems: ILineItemProps[] = [];

    const selectedPassengers = [...passengersIds, ...infantIds];
    if (tripPricing && tripPricing.pricingByPassenger.length > 0) {
      if (selectedPassengers.length > tripPricing.pricingByPassenger.length) {
        lineItems = selectedPassengers.map((passenger) => {
          const paxPricing = tripPricing.pricingByPassenger.find(
            (pricing) => pricing.person.id === passenger
          );
          if (paxPricing) {
            const hasFrozenPrice =
              isPriceFreezeExerciseEnabled &&
              !!creditsByPerson &&
              !!creditsByPerson.find(
                (credit) => credit.personId === paxPricing.person.id
              );
            //TODO: the actual model has yet to be decided by the BE
            const cfarFee = paxPricing.ancillaries?.find(
              (ancillary: any) => ancillary?.kind == "Cfar"
            )?.premium?.fiat?.value;

            const disruptionProtectionFee = paxPricing.ancillaries?.find(
              (ancillary: any) =>
                ancillary?.kind == "Delay" ||
                ancillary?.kind == "MissedConnection"
            )?.premium?.fiat?.value;

            const lineItem: ILineItemProps = {
              lineTitle: `${paxPricing.person.givenName} ${paxPricing.person.surname}`,
              baseAmount:
                paxPricing.baseWithoutMargin.fiat.value +
                (paxPricing.additionalMargin?.fiat.value ?? 0),
              taxesAndFees: paxPricing.taxes.fiat.value,
              frozenPrice: hasFrozenPrice,
            };
            const seats = seatMap.get(paxPricing.person.id);
            if (seats) {
              lineItem["seats"] = seats;
            }
            if (cfarFee !== undefined) {
              lineItem["cfarFee"] = cfarFee;
            }
            if (discountedCfar !== undefined) {
              lineItem["origCfarFee"] =
                discountedCfar.originalPremiumAmount.fiat.value;
            }
            if (disruptionProtectionFee !== undefined) {
              lineItem["disruptionProtectionFee"] = disruptionProtectionFee;
            }
            return lineItem;
          } else {
            const hasFrozenPrice =
              isPriceFreezeExerciseEnabled &&
              !!creditsByPerson &&
              !!creditsByPerson.find((credit) => credit.personId === passenger);
            const paxInfo = passengersInfo.find((pax) => pax.id === passenger);
            const lineItem: ILineItemProps = {
              lineTitle: `${paxInfo?.givenName} ${paxInfo?.surname}`,
              baseAmount: undefined,
              taxesAndFees: undefined,
              frozenPrice: hasFrozenPrice,
            };
            return lineItem;
          }
        });
      } else {
        lineItems = tripPricing.pricingByPassenger.map((pricing) => {
          // note: determine if a user is affected by the frozen price
          const hasFrozenPrice =
            isPriceFreezeExerciseEnabled &&
            !!creditsByPerson &&
            !!creditsByPerson.find(
              (credit) => credit.personId === pricing.person.id
            );
          const cfarFee = getCfarFee(pricing.ancillaries);
          const disruptionProtectionFee = getDisruptionProtectionFee(
            pricing.ancillaries
          );

          const lineItem: ILineItemProps = {
            lineTitle: `${pricing.person.givenName} ${pricing.person.surname}`,
            baseAmount:
              pricing.baseWithoutMargin.fiat.value +
              (pricing.additionalMargin?.fiat.value ?? 0),
            taxesAndFees: pricing.taxes.fiat.value,
            frozenPrice: hasFrozenPrice,
          };
          const seats = seatMap.get(pricing.person.id);
          if (seats) {
            lineItem["seats"] = seats;
          }
          if (cfarFee !== undefined) {
            lineItem["cfarFee"] = cfarFee;
          }
          if (discountedCfar !== undefined) {
            lineItem["origCfarFee"] =
              discountedCfar.originalPremiumAmount.fiat.value;
          }
          if (disruptionProtectionFee !== undefined) {
            lineItem["disruptionProtectionFee"] = disruptionProtectionFee;
          }
          return lineItem;
        });
      }
    } else if (tripPricing && tripPricing.totalPricing.total.fiat.value > 0) {
      const baseAmount =
        tripPricing.totalPricing.baseWithoutMargin.fiat.value || 0;
      const taxesAndFees = tripPricing.totalPricing.taxes.fiat.value || 0;
      const cfarFee = getCfarFee(tripPricing.totalPricing.ancillaries);

      const origCfarFee = discountedCfar?.originalPremiumAmount.fiat.value;
      const disruptionProtectionFee = getDisruptionProtectionFee(
        tripPricing.totalPricing.ancillaries
      );
      lineItems = [
        {
          lineTitle: "",
          baseAmount,
          taxesAndFees,
          cfarFee,
          disruptionProtectionFee,
          origCfarFee,
        } as ILineItemProps,
      ];
    } else if (shopPricingInfo.fare && shopPricingInfo.fare.length > 0) {
      const baseAmount =
        shopPricingInfo.fare[0].pricing.baseAmount.fiat.value || 0;
      const taxesAndFees =
        shopPricingInfo.fare[0].pricing.taxAmount.fiat.value || 0;
      lineItems = [
        {
          lineTitle: "",
          baseAmount,
          taxesAndFees,
        },
      ];
    }

    return lineItems;
  }
);

export const getFlightTotalInPrices = createSelector(
  getFlightTotal,
  currencySelector,
  getFlightTotalInRewards,
  getPricingInfoFlightTotalInPrices,
  (
    flightTotal,
    currency,
    flightTotalInRewards,
    pricingInfoFlightTotalInPrices
  ): { fiat: FiatPrice; rewards?: RewardsPrice } | null => {
    if (flightTotal) {
      return {
        fiat: {
          ...currency,
          value: flightTotal,
        },
        rewards: flightTotalInRewards ?? undefined,
      };
    } else if (pricingInfoFlightTotalInPrices) {
      return pricingInfoFlightTotalInPrices;
    }
    return null;
  }
);

export const getQuotedRewardsTotal = (state: IStoreState) =>
  state.flightBook.quotedRewardsTotal;

export const getPriceQuoteSuccessTime = (state: IStoreState) =>
  state.flightBook.priceQuoteSuccessTime;

export const priceQuoteSummaryLineItemsSelector = createSelector(
  currencySelector,
  getRewardsPaymentAccount,
  getFlightTotalInPrices,
  getRewardsAccountWithLargestValue,
  getRewardsPaymentAccountReferenceId,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentInRewardsCurrency,
  getUserSelectedPaymentMethod,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getQuotedRewardsTotal,
  getTravelWalletItemsToApply,
  getTravelWalletCreditToApplyAmount,
  getTravelWalletOfferToApplyAmount,
  (
    currency,
    rewardsPaymentAccount,
    flightTotalInPrices,
    rewardsAccountWithLargestValue,
    rewardsPaymentAccountReferenceId,
    rewardsPaymentInFiatCurrency,
    rewardsPaymentInRewardsCurrency,
    userSelectedPaymentMethod,
    totalCreditCardPaymentRequired,
    quotedRewardsTotal,
    travelWalletItemsToApply,
    creditToApplyAmount,
    offerToApplyAmount
  ): ISummaryLineItem[] => {
    let lineItems: ISummaryLineItem[] = [];
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    if (flightTotalInPrices) {
      lineItems.push({
        type: "total",
        fiatPrice: flightTotalInPrices.fiat,
        rewardsPrice:
          activeRewardsAccount?.allowRewardsRedemption ?? true
            ? quotedRewardsTotal || flightTotalInPrices.rewards
            : undefined,
      });
    }
    if (
      flightTotalInPrices &&
      offerToApplyAmount &&
      travelWalletItemsToApply.offerToApply
    ) {
      lineItems.push({
        type: "custom",
        icon: "offer-tag",
        label: textConstants.TRAVEL_OFFER_APPLIED,

        fiatPrice: {
          currencyCode: travelWalletItemsToApply.offerToApply.amount.currency,
          currencySymbol: getCurrencySymbol(
            travelWalletItemsToApply.offerToApply.amount.currency
          ),
          value: offerToApplyAmount * -1,
        },
        className: "travel-wallet-line",
      });
    }
    if (
      flightTotalInPrices &&
      creditToApplyAmount &&
      travelWalletItemsToApply.creditToApply
    ) {
      const breakdownHasStatementCredit =
        !!travelWalletItemsToApply.creditToApply.breakdown?.some(
          (detail) =>
            detail.CreditDetail === "Statement" &&
            Math.abs(detail.usableAmount.amount) > 0
        );
      lineItems.push({
        type: "custom",
        icon: "piggy-bank-icon",
        label: textConstants.TRAVEL_CREDITS_APPLIED,
        fiatPrice: {
          currencyCode: travelWalletItemsToApply.creditToApply.amount.currency,
          currencySymbol: getCurrencySymbol(
            travelWalletItemsToApply.creditToApply.amount.currency
          ),
          value: creditToApplyAmount * -1,
        },
        className: "travel-wallet-line",
        travelCreditBreakdown: breakdownHasStatementCredit
          ? getCheckoutCreditBreakdown(
              travelWalletItemsToApply.creditToApply.breakdown || [],
              creditToApplyAmount,
              travelWalletItemsToApply.creditToApply.amount.currency
            )
          : [],
      });
    }

    if (
      activeRewardsAccount &&
      rewardsPaymentAccountReferenceId &&
      (rewardsPaymentInFiatCurrency?.value || 0) > 0
    ) {
      lineItems.push({
        type: "rewards",
        fiatPrice: rewardsPaymentInFiatCurrency
          ? rewardsPaymentInFiatCurrency
          : {
              ...currency,
              value: 0,
            },
        rewardsAccountName: activeRewardsAccount.productDisplayName,
        rewardsPrice: rewardsPaymentInRewardsCurrency
          ? rewardsPaymentInRewardsCurrency
          : {
              currency: activeRewardsAccount.rewardsBalance.currency,
              value: 0,
            },
      });
    }
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply ||
      (activeRewardsAccount && rewardsPaymentAccountReferenceId)
    ) {
      lineItems.push({
        type: "payment",
        fiatPrice: totalCreditCardPaymentRequired,
        lastFour: userSelectedPaymentMethod?.last4,
      });
    }

    return lineItems;
  }
);

export const getIsTravelWalletOfferPaymentOnly = createSelector(
  getPricing,
  shopPricingInfoSelector,
  getPaymentTotalInPrices,
  getTravelWalletItemsToApply,
  getPollPriceQuoteCallState,
  getPriceQuoteErrors,
  getPriceDifferenceAcknowledged,
  (
    tripPricing,
    shopPricing,
    total,
    travelWalletItemsToApply,
    priceQuoteCallState,
    priceQuoteErrors,
    priceDiffAcknowledged
  ) => {
    if (
      travelWalletItemsToApply.offerToApply &&
      !travelWalletItemsToApply.creditToApply &&
      (priceQuoteCallState === CallState.Success || !!priceDiffAcknowledged) && // on price diff acknowledged, booking errors are reset, causing PQ callstate to also reset, so it checks if pricediff was acknowledged
      priceQuoteErrors.length < 1 &&
      (tripPricing?.totalPricing.total.fiat.value === 0
        ? Math.abs(travelWalletItemsToApply.offerToApply?.amount.amount) >=
          (shopPricing?.fare?.[0]?.pricing.total?.fiat.value || 0)
        : total?.fiat.value === 0)
    ) {
      return true;
    }
    return false;
  }
);

export const getIsTravelWalletCreditPaymentOnly = createSelector(
  getPricing,
  shopPricingInfoSelector,
  getPaymentTotalInPrices,
  getTravelWalletItemsToApply,
  getPollPriceQuoteCallState,
  getPriceQuoteErrors,
  getPriceDifferenceAcknowledged,
  (
    tripPricing,
    shopPricing,
    total,
    travelWalletItemsToApply,
    priceQuoteCallState,
    priceQuoteErrors,
    priceDiffAcknowledged
  ) => {
    if (
      travelWalletItemsToApply.creditToApply &&
      !travelWalletItemsToApply.offerToApply &&
      (priceQuoteCallState === CallState.Success || !!priceDiffAcknowledged) && // on price diff acknowledged, booking errors are reset, causing PQ callstate to also reset, so it checks if pricediff was acknowledged
      priceQuoteErrors.length < 1 &&
      (tripPricing?.totalPricing.total.fiat.value === 0
        ? Math.abs(travelWalletItemsToApply.creditToApply?.amount.amount) >=
          (shopPricing?.fare?.[0]?.pricing.total?.fiat.value || 0)
        : total?.fiat.value === 0)
    ) {
      return true;
    }
    return false;
  }
);

export const getIsStackedTravelWalletPaymentOnly = createSelector(
  getPricing,
  shopPricingInfoSelector,
  getPaymentTotalInPrices,
  getTravelWalletItemsToApply,
  getPollPriceQuoteCallState,
  getPriceQuoteErrors,
  getPriceDifferenceAcknowledged,
  (
    tripPricing,
    shopPricing,
    total,
    travelWalletItemsToApply,
    priceQuoteCallState,
    priceQuoteErrors,
    priceDiffAcknowledged
  ) => {
    if (
      travelWalletItemsToApply.creditToApply &&
      travelWalletItemsToApply.offerToApply &&
      (priceQuoteCallState === CallState.Success || !!priceDiffAcknowledged) && // on price diff acknowledged, booking errors are reset, causing PQ callstate to also reset, so it checks if pricediff was acknowledged
      priceQuoteErrors.length < 1 &&
      (tripPricing?.totalPricing.total.fiat.value === 0
        ? Math.abs(
            travelWalletItemsToApply.creditToApply?.amount.amount +
              travelWalletItemsToApply.offerToApply.amount.amount
          ) >= (shopPricing?.fare?.[0]?.pricing.total?.fiat.value || 0)
        : total?.fiat.value === 0)
    ) {
      return true;
    }
    return false;
  }
);

export const getIsTravelWalletPaymentOnly = createSelector(
  getIsTravelWalletOfferPaymentOnly,
  getIsTravelWalletCreditPaymentOnly,
  getIsStackedTravelWalletPaymentOnly,
  (
    isTravelWalletOfferPaymentOnly,
    isTravelWalletCreditPaymentOnly,
    isStackedTravelWalletPaymentOnly
  ) => {
    if (
      isTravelWalletOfferPaymentOnly ||
      isTravelWalletCreditPaymentOnly ||
      isStackedTravelWalletPaymentOnly
    ) {
      return true;
    }
    return false;
  }
);

export enum Progress {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

const getAddTravelersProgress = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  isFlightBookWithAncillariesActiveSelector,
  getFlightBookType,
  (
    email,
    phoneNumber,
    travelers,
    lapInfants,
    isFlightBookWithAncillariesActive,
    flightBookType
  ):
    | {
        status: Progress;
        name: string;
      }
    | undefined => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT: {
        return {
          name: isFlightBookWithAncillariesActive
            ? textConstants.ADD_TRAVELERS_SHORTENED
            : textConstants.ADD_TRAVELERS,
          status:
            travelers.length + lapInfants.length > 0 &&
            email &&
            phoneNumber &&
            emailRegex.test(email) &&
            phoneRegex.test(phoneNumber)
              ? Progress.COMPLETED
              : Progress.IN_PROGRESS,
        };
      }
      case FlightBookType.PRICE_FREEZE_PURCHASE: {
        return {
          name: textConstants.TRAVELERS,
          status:
            email && emailRegex.test(email)
              ? Progress.COMPLETED
              : Progress.IN_PROGRESS,
        };
      }
      default:
        return undefined;
    }
  }
);

const getSeatingProgress = createSelector(
  getSelectedSeats,
  getSeatMapHtml,
  getSkipSeatSelection,
  getFlightBookType,
  (
    seats,
    seatMapHtml,
    skipSeatSelection,
    flightBookType
  ):
    | {
        status: Progress;
        name: string;
      }
    | undefined => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT: {
        return {
          name: textConstants.SEATING,
          status:
            (skipSeatSelection && Progress.COMPLETED) || !!seatMapHtml
              ? seats.length > 0 || skipSeatSelection
                ? Progress.COMPLETED
                : Progress.IN_PROGRESS
              : Progress.NOT_STARTED,
        };
      }
      case FlightBookType.PRICE_FREEZE_PURCHASE:
      default:
        return undefined;
    }
  }
);

export const hasCfarBeenAttachedSelector = createSelector(
  selectedCfarIdSelector,
  (selectedCfarId): boolean => {
    if (selectedCfarId) {
      return (
        selectedCfarId.policyId !== DO_NOT_APPLY_OPTION_KEY &&
        selectedCfarId.productId !== DO_NOT_APPLY_OPTION_KEY
      );
    }

    return false;
  }
);

export const hasChfarBeenAttachedSelector = createSelector(
  selectedChfarIdSelector,
  (selectedChfarId): boolean => {
    if (selectedChfarId) {
      return (
        selectedChfarId.policyId !== DO_NOT_APPLY_OPTION_KEY &&
        selectedChfarId.productId !== DO_NOT_APPLY_OPTION_KEY
      );
    }

    return false;
  }
);

export const hasDisruptionProtectionBeenAttachedSelector = createSelector(
  selectedDisruptionProtectionIdSelector,
  (selectedDisruptionProtectionId): boolean => {
    if (selectedDisruptionProtectionId) {
      return (
        selectedDisruptionProtectionId.policyId !== DO_NOT_APPLY_OPTION_KEY &&
        selectedDisruptionProtectionId.productId !== DO_NOT_APPLY_OPTION_KEY
      );
    }

    return false;
  }
);

export const hasAddOnBeenAttachedSelector = createSelector(
  hasCfarBeenAttachedSelector,
  hasChfarBeenAttachedSelector,
  hasDisruptionProtectionBeenAttachedSelector,
  (
    hasCfarBeenAttached,
    hasChfarBeenAttached,
    hasDisruptionProtectionBeenAttached
  ): boolean => {
    return (
      hasCfarBeenAttached ||
      hasChfarBeenAttached ||
      hasDisruptionProtectionBeenAttached
    );
  }
);

export const hasSMSBeenSelected = (state: IStoreState) => {
  return state.flightBook.disruptionProtectionAlertOptIn.SMS;
};

export const hasAppNotifBeenSelected = (state: IStoreState) => {
  return state.flightBook.disruptionProtectionAlertOptIn.appNotif;
};

/*
  note: the isSeatSelectionGroupEnabled prop is a workaround to avoid adding the seat experiment to redux through the current pattern;
  once https://hopper-jira.atlassian.net/browse/BF-874 is handled, it will have to be addressed accordingly.
*/
export const getDpOptionSelectionProgressSelector = createSelector(
  isDpOptionSelectionCompleteSelector,
  isDisruptionProtectionAvailableSelector,
  getSeatingProgress,
  getAddTravelersProgress,
  getFlightBookType,
  (
    isDpOptionSelectionComplete,
    isDisruptionProtectionAvailable,
    seatingProgress,
    addTravelersProgress,
    flightBookType
  ): ((isSeatSelectionGroupEnabled: boolean) =>
    | {
        status: Progress;
        name: string;
      }
    | undefined) => {
    return (isSeatSelectionGroupEnabled: boolean) => {
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
        case FlightBookType.DEFAULT: {
          if (isDisruptionProtectionAvailable) {
            const dpStatus = (() => {
              if (isDpOptionSelectionComplete) {
                return Progress.COMPLETED;
              } else if (
                (isSeatSelectionGroupEnabled &&
                  seatingProgress?.status === Progress.COMPLETED) ||
                (!isSeatSelectionGroupEnabled &&
                  addTravelersProgress?.status === Progress.COMPLETED)
              ) {
                return Progress.IN_PROGRESS;
              } else {
                return Progress.NOT_STARTED;
              }
            })();

            return {
              name: textConstants.DISRUPTION_SUPPORT,
              status: dpStatus,
            };
          } else {
            return undefined;
          }
        }
        case FlightBookType.PRICE_FREEZE_PURCHASE:
        default:
          return undefined;
      }
    };
  }
);

export const getCfarOptionSelectionProgressSelector = createSelector(
  isCfarOptionSelectionCompleteSelector,
  isCfarAvailableSelector,
  isDisruptionProtectionAvailableSelector,
  getDpOptionSelectionProgressSelector,
  getSeatingProgress,
  getAddTravelersProgress,
  getFlightBookType,
  (
    isCfarOptionSelectionComplete,
    isCfarAvailable,
    isDisruptionProtectionAvailable,
    getDpOptionSelectionProgress,
    seatingProgress,
    addTravelersProgress,
    flightBookType
  ): ((isSeatSelectionGroupEnabled: boolean) =>
    | {
        status: Progress;
        name: string;
      }
    | undefined) => {
    return (isSeatSelectionGroupEnabled: boolean) => {
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
        case FlightBookType.DEFAULT: {
          if (isCfarAvailable) {
            const cfarStatus = (() => {
              if (isCfarOptionSelectionComplete) {
                return Progress.COMPLETED;
              } else if (
                (isDisruptionProtectionAvailable &&
                  getDpOptionSelectionProgress(isSeatSelectionGroupEnabled)
                    ?.status === Progress.COMPLETED) ||
                (!isDisruptionProtectionAvailable &&
                  ((isSeatSelectionGroupEnabled &&
                    seatingProgress?.status === Progress.COMPLETED) ||
                    (!isSeatSelectionGroupEnabled &&
                      addTravelersProgress?.status === Progress.COMPLETED)))
              ) {
                return Progress.IN_PROGRESS;
              } else {
                return Progress.NOT_STARTED;
              }
            })();

            return {
              name: textConstants.CFAR,
              status: cfarStatus,
            };
          } else {
            return undefined;
          }
        }
        case FlightBookType.PRICE_FREEZE_PURCHASE:
        default:
          return undefined;
      }
    };
  }
);

export type BookingProgress = {
  [key in CheckoutSteps | PriceFreezePurchaseSteps]?: {
    status: Progress;
    name: string;
  };
};

export const getBookingProgress = createSelector(
  getAddTravelersProgress,
  getSeatingProgress,
  getDpOptionSelectionProgressSelector,
  getCfarOptionSelectionProgressSelector,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getIsCreditCardPaymentRequired,
  isPriceFreezeDurationActiveSelector,
  isPriceFreezeDurationPopupEnabledSelector,
  isFlightBookWithAncillariesActiveSelector,
  getFlightBookType,
  getAllowRewardsWithPolicy,
  (
    addTravelersProgress,
    seatingProgress,
    getDpOptionSelectionProgress,
    getCfarOptionSelectionProgress,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired,
    isPriceFreezeDurationActive,
    isPriceFreezeDurationPopupEnabled,
    isFlightBookWithAncillariesActive,
    flightBookType,
    canRedeemRewards
  ): BookingProgress => {
    const paymentStatus = (() => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      } else if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      } else {
        return Progress.COMPLETED;
      }
    })();

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT: {
        const progress = {
          [CheckoutSteps.REWARDS_AND_PAYMENT]: {
            name:
              isFlightBookWithAncillariesActive || !canRedeemRewards
                ? textConstants.REWARDS_AND_PAYMENT_SHORTENED
                : textConstants.REWARDS_AND_PAYMENT,
            status: paymentStatus,
          },
        };

        if (addTravelersProgress) {
          progress[CheckoutSteps.ADD_TRAVELERS] = addTravelersProgress;
        }

        if (seatingProgress) {
          progress[CheckoutSteps.SEATING] = seatingProgress;
        }

        if (isFlightBookWithAncillariesActive) {
          /*
            note: the seat selection experiment is 100% available on production; the isSeatSelectionGroupEnabled param is a workaround for now,
            once https://hopper-jira.atlassian.net/browse/BF-874 is handled, this will have to be addressed accordingly.
          */
          const dpOptionSelectionProgress = getDpOptionSelectionProgress(true);
          const cfarOptionSelectionProgress =
            getCfarOptionSelectionProgress(true);

          if (dpOptionSelectionProgress) {
            progress[CheckoutSteps.DISRUPTION_SUPPORT] =
              dpOptionSelectionProgress;
          }
          if (cfarOptionSelectionProgress) {
            progress[CheckoutSteps.CFAR] = cfarOptionSelectionProgress;
          }
        }

        return progress;
      }
      case FlightBookType.PRICE_FREEZE_PURCHASE: {
        const rewardsStatus =
          addTravelersProgress?.status === Progress.COMPLETED
            ? paymentStatus
            : Progress.NOT_STARTED;

        const bookingProgress = {
          [PriceFreezePurchaseSteps.REWARDS_AND_PAYMENT]: {
            name: textConstants.REWARDS_AND_PAYMENT,
            status: rewardsStatus,
          },
          [PriceFreezePurchaseSteps.REVIEW_ITINERARY]: {
            name: textConstants.REVIEW_ITINERARY,
            status: Progress.COMPLETED,
          },
        };

        if (addTravelersProgress) {
          bookingProgress[PriceFreezePurchaseSteps.ADD_TRAVELERS] =
            addTravelersProgress;
        }

        if (isPriceFreezeDurationActive && !isPriceFreezeDurationPopupEnabled) {
          bookingProgress[PriceFreezePurchaseSteps.CHOOSE_DURATION] = {
            name: textConstants.CHOOSE_DURATION,
            status: Progress.COMPLETED,
          };
        }

        return bookingProgress;
      }
      default:
        return {};
    }
  }
);

export const getBookingProgressList = createSelector(
  getBookingProgress,
  getFlightBookType,
  (
    bookingProgress,
    flightBookType
  ): {
    status: Progress;
    name: string;
    type: CheckoutSteps | PriceFreezePurchaseSteps;
  }[] => {
    let bookingProgressList: {
      status: Progress;
      name: string;
      type: CheckoutSteps | PriceFreezePurchaseSteps;
    }[] = [];

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        Object.keys(CheckoutSteps).map((key) => {
          const value = CheckoutSteps[key];
          const statusName = bookingProgress[value];
          if (statusName) {
            bookingProgressList.push({
              ...statusName,
              type: value,
            });
          }
        });
        break;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        Object.keys(PriceFreezePurchaseSteps).map((key) => {
          const value = PriceFreezePurchaseSteps[key];
          const statusName = bookingProgress[value];
          if (statusName) {
            bookingProgressList.push({
              ...statusName,
              type: value,
            });
          }
        });
        break;
      default:
        break;
    }

    return bookingProgressList;
  }
);

export const getCheckoutStepNumberSelector = createSelector(
  getBookingProgressList,
  (
    bookingProgressList
  ): ((step: CheckoutSteps | PriceFreezePurchaseSteps) => number) => {
    return (step: CheckoutSteps | PriceFreezePurchaseSteps) => {
      return (
        bookingProgressList.findIndex((progress) => progress.type === step) + 1
      );
    };
  }
);

// note: when a different order of checkout steps needs to be implemented,
// simply change the enum here, which will sort by their order in the enum.
export enum CheckoutSteps {
  ADD_TRAVELERS,
  SEATING,
  DISRUPTION_SUPPORT,
  CFAR,
  REWARDS_AND_PAYMENT,
}

export enum PriceFreezePurchaseSteps {
  REVIEW_ITINERARY,
  CHOOSE_DURATION,
  ADD_TRAVELERS,
  REWARDS_AND_PAYMENT,
}

export const isTravelerStepCompleteSelector = createSelector(
  getBookingProgress,
  getFlightBookType,
  (checkoutProgress, flightBookType) => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        return (
          checkoutProgress[CheckoutSteps.ADD_TRAVELERS]?.status ===
          Progress.COMPLETED
        );
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        return (
          checkoutProgress[PriceFreezePurchaseSteps.ADD_TRAVELERS]?.status ===
          Progress.COMPLETED
        );
      default:
        return false;
    }
  }
);

export const areAllStepsCompletedInCheckout = createSelector(
  getBookingProgress,
  getFlightBookType,
  getIsTravelWalletPaymentOnly,
  (checkoutProgress, flightBookType, isTravelWalletPaymentOnly) => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        return (
          checkoutProgress[CheckoutSteps.ADD_TRAVELERS]?.status ===
            Progress.COMPLETED &&
          (!isTravelWalletPaymentOnly
            ? checkoutProgress[CheckoutSteps.REWARDS_AND_PAYMENT]?.status ===
              Progress.COMPLETED
            : true)
        );
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        return (
          checkoutProgress[PriceFreezePurchaseSteps.ADD_TRAVELERS]?.status ===
            Progress.COMPLETED &&
          checkoutProgress[PriceFreezePurchaseSteps.REWARDS_AND_PAYMENT]
            ?.status === Progress.COMPLETED
        );
      default:
        return false;
    }
  }
);

export const getIsBookingValid = createSelector(
  getPassengersValid,
  getPriceDifference,
  getPriceDifferenceAcknowledged,
  getIsCreditCardPaymentRequired,
  getSelectedPaymentMethodId,
  getUserSelectedPassengerIds,
  bookingInProgressSelector,
  isTravelerStepCompleteSelector,
  getFlightBookType,
  getIsTravelWalletPaymentOnly,
  getIsPriceFreezeTravelCreditPaymentOnly,
  (
    passengersValid,
    priceDifference,
    priceDifferenceAcknowledged,
    creditCardRequired,
    selectedPaymentMethodId,
    travelerIds,
    bookingInProgress,
    isTravelerStepComplete,
    flightBookType,
    isTravelWalletPaymentOnly,
    isPriceFreezeTravelCreditPaymentOnly
  ) => {
    const paymentUnsettled = isPriceFreezeTravelCreditPaymentOnly
      ? false
      : creditCardRequired &&
        !selectedPaymentMethodId &&
        !isTravelWalletPaymentOnly;

    let isFlightBookReady: boolean;

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        isFlightBookReady = !!passengersValid && travelerIds.length >= 1;
        break;
      default:
        isFlightBookReady = true;
        break;
    }

    if (
      (priceDifference.hasDifference && !priceDifferenceAcknowledged) ||
      paymentUnsettled ||
      bookingInProgress ||
      !isTravelerStepComplete ||
      !isFlightBookReady
    ) {
      return false;
    } else {
      return true;
    }
  }
);

export const getEarn = (state: IStoreState) =>
  state.flightBook.productEarnValue;

const getPaymentRequestType = createSelector(
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  (
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    selectedPaymentMethodId
  ) => {
    if (selectedPaymentMethodId && !rewardsPayment) {
      return PaymentSplitRequestEnum.PaymentCardRequest;
    } else if (
      rewardsAccountId &&
      rewardsPayment &&
      rewardsFiatPayment &&
      creditCardPayment.value === 0
    ) {
      return PaymentSplitRequestEnum.PaymentRewardsRequest;
    } else if (rewardsPayment && rewardsAccountId && rewardsFiatPayment) {
      return PaymentSplitRequestEnum.PaymentCardRewardsRequest;
    } else {
      return null;
    }
  }
);

export const getEarnedString = createSelector(
  getEarn,
  getCardPaymentRewardsAccount,
  getAllowRewardsWithPolicy,
  (earn, account, canRedeemRewards) => {
    if (!earn || !canRedeemRewards) return "";
    return textConstants.EARNED_STRING(
      earn,
      account?.rewardsBalance.currencyDescription
    );
  }
);

export const getPaymentRequest = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getSession,
  getSelectedSeats,
  getFlightBookType,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    session,
    selectedSeats,
    flightBookType
  ): PaymentSplitRequest | null => {
    let amount: PaymentType | null = null;
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const cardPaymentType: UserCardPaymentType = {
          accountReferenceId,
          paymentId: paymentId || "",
          paymentAmount: {
            currency: creditCardPayment.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment.value),
            PaymentAmount: PaymentAmountEnum.FiatAmount,
          },
          Payment: TypeOfPaymentEnum.UserCard,
        };
        amount = cardPaymentType;
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitPaymentType: SplitPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            fiatAmount: {
              currency: creditCardPayment.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment.value),
              PaymentAmount: PaymentAmountEnum.FiatAmount,
            },
            rewardsAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: rewardsPayment!.value,
                currency: rewardsPayment!.currency,
              },
              PaymentAmount: PaymentAmountEnum.RewardsAmount,
            },
            PaymentAmount: PaymentAmountEnum.SplitAmount,
          },
          Payment: TypeOfPaymentEnum.Split,
        };
        amount = splitPaymentType;
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: RewardsPaymentType = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
            PaymentAmount: PaymentAmountEnum.RewardsAmount,
          },
          Payment: TypeOfPaymentEnum.Rewards,
        };
        amount = rewardsPaymentType;
        break;
      default:
        return null;
    }

    if (amount && session) {
      let ancillaries: PostQuoteAncillaries[] = [];
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
        case FlightBookType.DEFAULT:
          if (selectedSeats.length > 0) {
            ancillaries.push({
              seats: {
                seats: selectedSeats,
              },
              PostQuoteAncillaries: PostQuoteAncillariesEnum.Seats,
            });
          }
          break;
        default:
          break;
      }

      const request = {
        token: session,
        payment: amount,
        ancillaries,
      };
      return request;
    } else {
      return null;
    }
  }
);

export const getDisruptionProtectionRebookPayments = createSelector(
  getPriceQuoteWithUpdatedAncillary,
  singleFlightItineraryIdSelector,
  (priceQuote, singleFlightItineraryId): PaymentOpaqueValue[] | null => {
    const totalPrices = priceQuote?.itinerary.sellingPricing.totalPricing.total;
    const totalFiat = totalPrices?.fiat;

    if (totalFiat && singleFlightItineraryId) {
      return [
        {
          type: Payment.DisruptionProtectionCredit,
          value: {
            paymentAmount: {
              currency: totalFiat.currencyCode,
              amount: roundToTwoDecimals(totalFiat.value),
            },
            originalItineraryId: singleFlightItineraryId,
            PaymentV2: PaymentV2Enum.DisruptionProtectionCredit,
          },
        },
      ];
    }

    return null;
  }
);

export const priceFreezeOfferTotalInRewards = createSelector(
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  activePriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  (rewardsPaymentAccount, rewardsAccountWithLargestValue, quoteData, offer) => {
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    if ((!quoteData && !offer) || !activeRewardsAccount) return null;

    const { accountReferenceId } = activeRewardsAccount;
    return (
      quoteData?.totalAmount.rewards[accountReferenceId] ??
      offer?.totalAmount.rewards[accountReferenceId]
    );
  }
);

export const priceFreezeOfferTotalAmountSelector = createSelector(
  activePriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  priceFreezeOfferTotalInRewards,
  (
    quoteData,
    offer,
    freezeTotalInRewards
  ): { fiat: FiatPrice; rewards: RewardsPrice } | null => {
    const fiat = quoteData?.totalAmount.fiat ?? offer?.totalAmount.fiat;

    if (fiat && freezeTotalInRewards) {
      return {
        fiat,
        rewards: freezeTotalInRewards,
      };
    }
    return null;
  }
);

export const getPriceFreezeRewardsPaymentInRewardsCurrency = createSelector(
  getFlightFreezeCreditToApply,
  getRewardsPaymentInRewardsCurrency,
  priceFreezeOfferTotalAmountSelector,
  (
    pfCreditToApply,
    rewardsPaymentInCurrency,
    pfOfferTotal
  ): RewardsPrice | null => {
    if (pfCreditToApply && rewardsPaymentInCurrency && pfOfferTotal) {
      let differenceToCover =
        pfOfferTotal.fiat.value - Math.abs(pfCreditToApply.amount.amount);
      let value;

      if (rewardsPaymentInCurrency.value / 100 > differenceToCover) {
        value = differenceToCover * 100;
      } else {
        value = rewardsPaymentInCurrency.value;
      }

      return {
        currency: rewardsPaymentInCurrency.currency,
        value: value,
        currencyDescription: rewardsPaymentInCurrency.currencyDescription,
      };
    }
    if (rewardsPaymentInCurrency && pfOfferTotal) {
      return {
        currency: rewardsPaymentInCurrency.currency,
        value: rewardsPaymentInCurrency.value,
        currencyDescription: rewardsPaymentInCurrency.currencyDescription,
      };
    }
    return null;
  }
);

export const getPriceFreezeRewardsPaymentInFiatCurrency = createSelector(
  getFlightFreezeCreditToApply,
  getRewardsPaymentInFiatCurrency,
  priceFreezeOfferTotalAmountSelector,
  (
    pfCreditToApply,
    rewardsPaymentInFiatCurrency,
    pfOfferTotal
  ): FiatPrice | null => {
    if (pfCreditToApply && rewardsPaymentInFiatCurrency && pfOfferTotal) {
      let difference =
        pfOfferTotal.fiat.value - Math.abs(pfCreditToApply.amount.amount);
      let value;
      if (rewardsPaymentInFiatCurrency.value > difference) {
        value = difference;
      } else {
        value = rewardsPaymentInFiatCurrency.value;
      }
      return {
        currencyCode: rewardsPaymentInFiatCurrency.currencyCode,
        value: value,
        currencySymbol: rewardsPaymentInFiatCurrency.currencySymbol,
      };
    }
    if (rewardsPaymentInFiatCurrency && pfOfferTotal) {
      return {
        currencyCode: rewardsPaymentInFiatCurrency.currencyCode,
        value: rewardsPaymentInFiatCurrency.value,
        currencySymbol: rewardsPaymentInFiatCurrency.currencySymbol,
      };
    }
    return null;
  }
);

export const getPaymentsV2 = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getTravelWalletItemsToApply,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  getFlightFreezeCreditToApply,
  getPriceFreezeRewardsPaymentInFiatCurrency,
  getPriceFreezeRewardsPaymentInRewardsCurrency,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    travelWalletItemsToApply,
    offerToApplyAmount,
    creditToApplyAmount,
    flightFreezeCreditToApply,
    pfRewardsPaymentInFiatCurrency,
    pfRewardsPaymentInRewardsCurrency
  ): PaymentOpaqueValue[] | null => {
    let amount: PaymentOpaqueValue[] = [];
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      if (travelWalletItemsToApply.offerToApply) {
        amount.push({
          type: Payment.TravelWalletOffer,
          value: {
            offerId: travelWalletItemsToApply.offerToApply.id,
            description:
              travelWalletItemsToApply.offerToApply?.descriptions[0] || "",
            paymentAmount: {
              fiatValue: {
                amount: offerToApplyAmount,
                currency: travelWalletItemsToApply.offerToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletOffer,
          } as any,
        });
      }
      if (travelWalletItemsToApply.creditToApply && creditToApplyAmount > 0) {
        amount.push({
          type: Payment.TravelWalletCredit,
          value: {
            offerId: travelWalletItemsToApply.creditToApply.id,
            description: "TravelWalletCredit", // creditToApply doesn't have a description
            paymentAmount: {
              fiatValue: {
                amount: roundToTwoDecimals(creditToApplyAmount),
                currency:
                  travelWalletItemsToApply.creditToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletCredit,
          } as any,
        });
      }
    }
    if (!travelWalletItemsToApply.creditToApply && flightFreezeCreditToApply) {
      amount.push({
        type: Payment.TravelWalletCredit,
        value: {
          offerId: flightFreezeCreditToApply.id,
          description: "TravelWalletCredit", // creditToApply doesn't have a description
          paymentAmount: {
            fiatValue: {
              amount: roundToTwoDecimals(
                Math.abs(flightFreezeCreditToApply.amount.amount)
              ),
              currency: flightFreezeCreditToApply.amount.currency,
            },
          },
          PaymentV2: PaymentV2Enum.TravelWalletCredit,
        } as any,
      });
    }
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        amount.push({
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId: accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          } as any,
        });
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        amount.push(
          {
            type: Payment.Card,
            value: {
              paymentId: paymentId || "",
              accountReferenceId: accountReferenceId,
              paymentAmount: {
                currency: creditCardPayment!.currencyCode,
                amount: roundToTwoDecimals(creditCardPayment!.value),
              },
              PaymentV2: PaymentV2Enum.UserCard,
            } as any,
          },
          {
            type: Payment.Rewards,
            value: {
              paymentAmount: {
                rewardsAccountId: rewardsAccountId,
                fiatValue: {
                  amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                  currency: rewardsFiatPayment!.currencyCode,
                },
                rewardsPrice: {
                  value: roundToTwoDecimals(rewardsPayment!.value),
                  currency: rewardsPayment!.currency,
                },
              },
              PaymentV2: PaymentV2Enum.Rewards,
            } as any,
          }
        );
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        if (
          !!flightFreezeCreditToApply &&
          pfRewardsPaymentInFiatCurrency &&
          pfRewardsPaymentInRewardsCurrency
        ) {
          amount.push({
            type: Payment.Rewards,
            value: {
              paymentAmount: {
                rewardsAccountId: rewardsAccountId,
                fiatValue: {
                  amount: roundToTwoDecimals(
                    pfRewardsPaymentInFiatCurrency!.value
                  ),
                  currency: rewardsFiatPayment!.currencyCode,
                },
                rewardsPrice: {
                  value: roundToTwoDecimals(
                    pfRewardsPaymentInRewardsCurrency!.value
                  ),
                  currency: rewardsPayment!.currency,
                },
              },
              PaymentV2: PaymentV2Enum.Rewards,
            } as any,
          });
        } else {
          amount.push({
            type: Payment.Rewards,
            value: {
              paymentAmount: {
                rewardsAccountId: rewardsAccountId,
                fiatValue: {
                  amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                  currency: rewardsFiatPayment!.currencyCode,
                },
                rewardsPrice: {
                  value: roundToTwoDecimals(rewardsPayment!.value),
                  currency: rewardsPayment!.currency,
                },
              },
              PaymentV2: PaymentV2Enum.Rewards,
            } as any,
          });
        }
        break;
      default:
        break;
    }
    return amount;
  }
);

export const getAncillaries = createSelector(
  getSelectedSeats,
  getFlightBookType,
  (selectedSeats, flightBookType): AncillaryOpaqueValue[] | null => {
    let ancillaries: AncillaryOpaqueValue[] = [];
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        if (selectedSeats.length > 0) {
          ancillaries.push({
            type: Ancillary.Seats,
            value: {
              seats: selectedSeats,
            } as any,
          });
        }
        break;
      default:
        break;
    }
    return ancillaries;
  }
);

export const getCardPaymentAccount = createSelector(
  getRewardsAccounts,
  getPaymentRequest,
  (rewardsAccounts, paymentRequest: PaymentSplitRequest | null) => {
    switch (paymentRequest?.payment.Payment) {
      case TypeOfPaymentEnum.UserCard:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as UserCardPaymentType).accountReferenceId
        );
      case TypeOfPaymentEnum.Split:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as SplitPaymentType).accountReferenceId
        );
      default:
        return null;
    }
  }
);
export const rewardsAccountMinimumRequirementStateSelector = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccountMinimumRequirementState => {
    return getRewardsAccountMinimumRequirementState(rewardsAccounts);
  }
);

export const getTripFinalFlightDate = createSelector(
  selectedTripSelector,
  tripDetailsByIdSelector,
  (selectedTrip, tripDetailsById): string => {
    const selectedTripId = selectedTrip?.tripId || "";
    const trip = tripDetailsById[selectedTripId];
    const lastFlightIndex = trip.slices.length - 1;
    const latestFlightDate = trip.slices[lastFlightIndex].departureTime;
    return latestFlightDate;
  }
);

interface ReviewFlightDetailsWithAddOnProperties
  extends ReviewFlightDetailsProperties,
    CfarAttachProperties {}

export const getReviewFlightDetailsProperties = createSelector(
  getViewedTripSummaryProperties,
  selectedTripSelector,
  tripDetailsByIdSelector,
  getCfarAttachProperties,
  dpOfferPropertiesSelector,
  getTripCategory,
  cfarDiscountPropertiesSelector,
  getCorporateInfo,
  getShopExperiments,
  getPotentialCrossSellOffers,
  getPaymentMethods,
  (
    viewedTripSummaryProperties,
    selectedTrip,
    tripDetailsById,
    cfarAttachProperties,
    dpOfferProperties,
    tripCategory,
    cfarDiscountProperties,
    corporateInfo,
    experiments,
    potentialCrossSellOffers,
    paymentMethods
  ): ReviewFlightDetailsWithAddOnProperties => {
    const trip = tripDetailsById[selectedTrip?.tripId!];
    if (trip && trip.slices.length > 0) {
      const stops = trip.slices.reduce((stops, slice) => {
        return stops + slice.stops;
      }, 0);
      const selectedOWRTrip = selectedTrip as ISelectedTrip;
      const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
      const fare = trip.fareDetails.find(
        (f) =>
          f.id === selectedOWRTrip.outgoingFareId ||
          f.id === selectedOWRTrip.returnFareId ||
          f.id === selectedMcTrip.departureFareId
      );
      const brand: FareBrand | undefined =
        fare &&
        fare.slices.length > 0 &&
        fare.slices[0].fareDetails.segments.length > 0
          ? fare?.slices[0].fareDetails.segments[0].brand
          : undefined;
      const carrier: string | undefined =
        fare &&
        fare.slices.length > 0 &&
        fare.slices[0].fareDetails.segments.length > 0
          ? fare?.slices[0].fareDetails.segments[0].validatingCarrierCode
          : undefined;
      let provider = "";
      if (brand) {
        if (brand.sabreFareBrand) {
          provider = "Sabre";
        } else if (brand.travelportFareBrand) {
          provider = "Travelport";
        } else if (brand.amadeusFareBrand) {
          provider = "Amadeus";
        } else if (brand.gdxFareBrand) {
          provider = "GDX";
        } else if (brand.travelFusionFareBrand) {
          provider = "TravelFusion";
        }
      }

      const isHotelCrossSellV3Experiment =
        experiments?.[HOTEL_CROSS_SELL_V3_EXPERIMENT] !== CONTROL;

      return {
        stops,
        provider,
        advance: dayjs(trip.slices[0].departureTime).diff(dayjs(), "day"),
        ...viewedTripSummaryProperties,
        ...cfarAttachProperties,
        ...dpOfferProperties,
        carrier: (viewedTripSummaryProperties.carrier || carrier) ?? "",
        departure_date: dayjs(trip.slices[0].departureTime).format(
          "YYYY-MM-DD"
        ),
        destination: viewedTripSummaryProperties.destination
          ? viewedTripSummaryProperties.destination
          : trip.slices[0].destinationName,
        destination_country_code:
          viewedTripSummaryProperties.destination_country_code
            ? viewedTripSummaryProperties.destination_country_code
            : trip.slices[0].destinationCode,
        fare_class: fare
          ? getHopperFareRatingName(fare?.slices[0].fareShelf?.rating)
          : "",
        origin: viewedTripSummaryProperties.origin
          ? viewedTripSummaryProperties.origin
          : trip.slices[0].originName,
        origin_country_code: viewedTripSummaryProperties.origin_country_code
          ? viewedTripSummaryProperties.origin_country_code
          : trip.slices[0].originCode,
        return_date:
          trip.slices.length > 1
            ? dayjs(trip.slices[1].departureTime).format("YYYY-MM-DD")
            : "",
        trip_type: tripCategory,
        is_multi_ticket: viewedTripSummaryProperties.is_multi_ticket,
        multi_ticket_type: viewedTripSummaryProperties.multi_ticket_type,
        business_loyalty_programs:
          corporateInfo?.businessLoyaltyPrograms?.join(", "),
        slice_count: trip.slices.length,
        cross_sell_displayed:
          isHotelCrossSellV3Experiment && potentialCrossSellOffers.length > 0,
        card_on_file: paymentMethods.length > 0,
        ...{
          cfar_discount_original_premium:
            cfarDiscountProperties.original_premium,
          cfar_discount_percentage: cfarDiscountProperties.discount_percentage,
        },
      };
    } else {
      return {
        stops: 0,
        provider: "",
        advance: 0,
        ...viewedTripSummaryProperties,
        carrier: viewedTripSummaryProperties.carrier || "",
        business_loyalty_programs:
          corporateInfo?.businessLoyaltyPrograms?.join(", "),
        slice_count: undefined,
      };
    }
  }
);

export const getViewItineraryRestrictionsProperties = createSelector(
  getReviewFlightDetailsProperties,
  selectedTripSelector,
  tripDetailsByIdSelector,
  (reviewFlightDetailsProperties, selectedTrip, tripDetailsById): any => {
    const selectedTripId = selectedTrip?.tripId || "";
    const trip = tripDetailsById[selectedTripId];
    if (!trip) {
      return {};
    }
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
    const fare = trip.fareDetails.find(
      (f) =>
        f.id === selectedOWRTrip.outgoingFareId ||
        f.id === selectedOWRTrip.returnFareId ||
        f.id === selectedMcTrip.departureFareId
    );
    const getRestriction = (uta: DullesUta) =>
      uta.headline == "Checked or Carry-on Bags"
        ? `${uta.headline} - ${uta.description}`
        : `${uta.description}`;
    const outboundRestrictions =
      fare?.slices[0].amenitiesUtas?.utas.utas.reduce((restrictions, uta) => {
        restrictions[`outbound_${uta.category}`] = getRestriction(uta);
        return restrictions;
      }, {});
    const returnRestrictions =
      fare?.slices.length && fare.slices.length > 1
        ? fare?.slices[1].amenitiesUtas?.utas.utas.reduce(
            (restrictions, uta) => {
              restrictions[`return_${uta.category}`] = getRestriction(uta);
              return restrictions;
            },
            {}
          )
        : {};
    return {
      ...reviewFlightDetailsProperties,
      ...outboundRestrictions,
      ...returnRestrictions,
    };
  }
);

export const getSelectedPaymentCardType = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  getSelectedPaymentMethodId,
  (accounts, paymentMethods, id): string => {
    const paymentMethod = paymentMethods.find((p) => p.id === id);
    return (
      accounts.find(
        (a) =>
          a.lastFour === paymentMethod?.last4 ||
          a.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4 || "")
      )?.productDisplayName || ""
    );
  }
);

export const getNumberofTravelers = createSelector(
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  (pass, lapInfants) => pass.length + lapInfants.length
);

export const getPriceQuoteAirProperties = createSelector(
  getReviewFlightDetailsProperties,
  getNumberofTravelers,
  getSession,
  getSelectedFlightIndex,
  (
    reviewFlightDetailsProperties,
    numberOfTravelers,
    session,
    selectedFlightIndex
  ): PriceQuoteAirProperties | null => {
    if (!reviewFlightDetailsProperties) {
      return null;
    }

    return {
      success: false,
      ...reviewFlightDetailsProperties,
      pax_total: numberOfTravelers,
      booking_session_id: session?.value || "",
      flight_list_ranking: selectedFlightIndex || undefined,
    };
  }
);

export const getIsPaymentMethodVCN = createSelector(
  getPaymentMethodRewardsAccountId,
  getPaymentMethod,
  getRewardsAccounts,
  (paymentRewardsAccountId, paymentMethod, rewardsAccounts) => {
    if (!paymentRewardsAccountId || !paymentMethod || !rewardsAccounts)
      return false;

    const account = rewardsAccounts.find(
      (account) => account.accountReferenceId === paymentRewardsAccountId
    );

    return !!(
      account?.lastFourVirtualCardNumbers &&
      paymentMethod?.last4 &&
      account?.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4)
    );
  }
);

export const getFlightShopRecommendedBasedOnPreferences = (
  state: IStoreState
) => state.flightShop.recommendedBasedOnPreferences;

export const getCompleteBuyAirProperties = createSelector(
  getReviewFlightDetailsProperties,
  getRewardsPaymentInFiatCurrency,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getCardPaymentAccount,
  getRewardsPaymentInRewardsCurrency,
  getNumberofTravelers,
  getRewardsPaymentAccount,
  getPriceQuoteWithUpdatedAncillary,
  getSelectedSeats,
  getSeatsTotalPricing,
  getSelectedFlightIndex,
  getCreditToApply,
  getTravelWalletCreditToApplyAmount,
  getIsPaymentMethodVCN,
  getFlightShopEntryPoint,
  getFlightShopRecommendedBasedOnPreferences,
  (
    reviewFlightDetailsProperties,
    rewardsPaymentFiat,
    creditCardPayment,
    paymentMethodRewardsAccount,
    rewardsPaymentCurrency,
    numberOfTravelers,
    rewardsPaymentAccount,
    priceQuote,
    seats,
    seatsPricing,
    selectedFlightIndex,
    creditToApply,
    creditAmountToApply,
    isPaymentMethodVCN,
    flightShopEntryPoint,
    recommendedBasedOnPreferences
  ): CompleteBuyAirProperties | null => {
    if (!reviewFlightDetailsProperties) {
      return null;
    }

    return {
      success: false,
      total_rewards_amount_usd: rewardsPaymentFiat?.value || 0,
      total_card_amount_usd: creditCardPayment.value || 0,
      card_product_used: paymentMethodRewardsAccount?.productDisplayName || "",
      rewards_product_used: rewardsPaymentAccount?.productDisplayName || "",
      rewards_currency: rewardsPaymentCurrency?.currency || "",
      agent_book_fee_amount_usd: 0,
      agent_locator: "", // this is overwritten in the pollFinalizedSaga
      /*
        TODO: CFAR data from reviewFlightDetailsProperties gets expanded here;
        ideally, some of those properties should be overrode by the /purchase/pollFinalized response.
      */
      ...reviewFlightDetailsProperties,
      pax_total: numberOfTravelers,
      booking_session_id: "",
      seats_selection_available:
        priceQuote?.seatMap?.SeatMapResponse ===
        SeatMapResponseEnum.SeatMapAvailable,
      seats_selected_seats: seats?.length > 0,
      seats_selected_count: seats?.length,
      seats_selected_paid: seats?.filter((s) => s.price_object.total > 0)
        ?.length,
      seats_selected_free: seats?.filter((s) => s.price_object.total === 0)
        ?.length,
      seats_selected_price_usd: seatsPricing,
      lowest_price_seat: 0, // TODO add when cheapest seat work is in
      flight_list_ranking: selectedFlightIndex || undefined,
      credit_redeemed: !!creditToApply?.amount?.amount,
      credit_amt_redeemed:
        !!creditToApply && !!creditAmountToApply
          ? Math.abs(creditAmountToApply)
          : 0,
      is_vcn: isPaymentMethodVCN,
      entry_type: flightShopEntryPoint,
      preferences_recommended: recommendedBasedOnPreferences,
    };
  }
);

export const firstPersonWithFrozenPriceSelector = createSelector(
  getPriceQuoteWithUpdatedAncillary,
  (priceQuote) => priceQuote?.creditSummary?.credits.creditsByPerson[0]
);

export const getCompleteBuyAirPriceFreezeProperties = createSelector(
  currentPriceFreezeSelector,
  getPriceQuoteWithUpdatedAncillary,
  firstPersonWithFrozenPriceSelector,
  getCreditToApply,
  getTravelWalletCreditToApplyAmount,
  getIsPaymentMethodVCN,
  getFlightBookType,
  (
    priceFreeze,
    priceQuote,
    firstPersonWithFrozenPrice,
    creditToApply,
    creditAmountToApply,
    isPaymentMethodVCN,
    flightBookType
  ): CompleteBuyAirPriceFreezeProperties | undefined => {
    if (flightBookType !== FlightBookType.PRICE_FREEZE_EXERCISE) {
      return undefined;
    }
    const paxPricings = priceFreeze?.frozenFare?.paxPricings;
    let totalOtm = 0;
    if (paxPricings != undefined && paxPricings.length) {
      paxPricings.forEach((paxPricing) => {
        totalOtm += paxPricing.pricing.savingsAmount.fiat.value;
      });
    }
    const priceFreezeShownOTMTotalUsd =
      priceFreeze?.frozenFare.totalPricing?.savingsAmount?.fiat?.value ||
      totalOtm;
    const savingsAmountInFiat =
      firstPersonWithFrozenPrice?.priceFreezeExerciseCredit?.creditAmount?.fiat
        ?.value || 0;
    const offerPerPaxCapInFiat =
      priceQuote?.creditSummary?.credits.totalCredits.priceFreezeExerciseCredit
        ?.offerPerPaxCap?.fiat?.value || 0;
    const isFareOverCap = savingsAmountInFiat >= offerPerPaxCapInFiat;
    const totalTravelers = getTotalPassengerFromPaxPricing(paxPricings || []);

    return {
      price_freeze_flow: true,
      price_freeze_id: priceFreeze?.priceFreeze?.id || "",
      price_freeze_shown_OTM_total_usd: priceFreezeShownOTMTotalUsd,
      price_freeze_cap_hit: isFareOverCap,
      price_freeze_duration:
        priceFreeze?.priceFreeze.offer?.timeToLive?.inSeconds || 0,
      price_freeze_pax_total: totalTravelers,
      price_freeze_pax_adults: getTotalPassengerFromPaxPricing(
        paxPricings?.filter((pax) => pax.paxType === PassengerTypes.Adult) || []
      ),
      price_freeze_pax_children: getTotalPassengerFromPaxPricing(
        paxPricings?.filter((pax) => pax.paxType === PassengerTypes.Child) || []
      ),
      price_freeze_pax_lap_infants: getTotalPassengerFromPaxPricing(
        paxPricings?.filter(
          (pax) => pax.paxType === PassengerTypes.InfantInLap
        ) || []
      ),
      price_freeze_pax_seat_infants: getTotalPassengerFromPaxPricing(
        paxPricings?.filter(
          (pax) => pax.paxType === PassengerTypes.InfantInSeat
        ) || []
      ),
      price_freeze_entry: "PF_exercise", // TODO: Add "flight_search" option
      frozen_price_total_usd:
        priceFreeze?.frozenFare.totalPricing?.originalAmount.fiat.value || 0,
      price_freeze_total_cost:
        priceQuote?.itinerary.sellingPricing.totalPricing.total.fiat.value || 0,
      price_freeze_otm_cap_usd:
        priceFreeze?.priceFreeze.offer?.cap.value.amount || 0,
      price_freeze_cost_per_pax:
        priceQuote?.itinerary.sellingPricing.pricingByPassenger.find(
          (pricing) =>
            pricing.person.id === firstPersonWithFrozenPrice?.personId
        )?.total.fiat.value || 0,
      credit_redeemed: !!creditToApply?.amount?.amount,
      credit_amt_redeemed:
        !!creditToApply && !!creditAmountToApply
          ? Math.abs(creditAmountToApply)
          : 0,
      is_vcn: isPaymentMethodVCN,
    };
  }
);

export const getPriceDropProperties = createSelector(
  getFlightBookPriceDropProtection,
  getPriceDropRefundTypeSelector,
  predictionSelector,
  (
    priceDropProtection,
    priceDropRefundType,
    prediction
  ): Omit<PriceDropViewedProperties, "page"> => {
    const eligible =
      priceDropProtection?.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible;
    return {
      price_drop_offer_duration: eligible
        ? (priceDropProtection as IsEligible).monitoringDuration.inSeconds
        : 0,
      price_drop_offer_max_cap: eligible
        ? (priceDropProtection as IsEligible).maximumRefund.amount.amount
        : 0,
      price_drop_offer_min_cap: eligible
        ? (priceDropProtection as IsEligible).minimumRefund.amount.amount
        : 0,
      price_bucket: prediction
        ? getPriceBucketValueFromDealness(prediction.dealness)
        : null,
      refund_type: eligible ? priceDropRefundType : null,
    };
  }
);

export const getIsPollQuoteOnly = (state: IStoreState) => {
  return state.flightBook.pollQuoteOnly;
};

export const getIsConfirmAndBookReady = createSelector(
  getIsBookingValid,
  areAllStepsCompletedInCheckout,
  getIsPollQuoteOnly,
  (isBookingValid, allStepsCompleted, isPollQuoteOnly): boolean => {
    return isBookingValid || (allStepsCompleted && !isPollQuoteOnly);
  }
);

export const totalPassengersCountSelector = createSelector(
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  priceFreezePassengerCountsSelector,
  getFlightBookType,
  (
    selectedTravelers,
    selectedLapInfants,
    offerCounts,
    flightBookType
  ): number => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        return selectedTravelers.length + selectedLapInfants.length;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        return (
          offerCounts.adultsCount +
          offerCounts.childrenCount +
          offerCounts.infantsInSeatCount +
          offerCounts.infantsOnLapCount
        );
      default:
        return 1;
    }
  }
);

const getFlightBookErrorTitle = createSelector(
  getTripPricingErrors,
  getPassengersValidErrors,
  (tripPricingErrors, passengerErrors): ErrorTitles | null => {
    if (tripPricingErrors.length > 0) {
      return {
        icon: textConstants.UNABLED_ICON,
        title: tripPricingErrors[0].code,
        subtitle:
          tripPricingErrors[0].details || textConstants.GENERIC_ERROR_SUBTITLE,
        primaryButtonText: textConstants.UPDATE_TRAVELERS,
      };
    }

    if (passengerErrors.length > 0) {
      return {
        title: "Invalid Passengers",
        subtitle: passengerErrors[0].message || "",
        primaryButtonText: textConstants.UPDATE_TRAVELERS,
        icon: textConstants.UNABLED_ICON,
      };
    }

    return null;
  }
);

export const priceFreezeTotalPassengerSelector = createSelector(
  currentPriceFreezeSelector,
  (priceFreeze) => {
    return priceFreeze
      ? getTotalPassengerFromPaxPricing(priceFreeze?.frozenFare.paxPricings)
      : undefined;
  }
);

const getFlightFreezeErrorTitle = createSelector(
  priceFreezeOfferCallStateSelector,
  generateCustomPriceFreezeOfferCallStateSelector,
  priceFreezeFareQuotePerPassengerTotalPricingSelector,
  priceFreezeFareQuoteCallStateSelector,
  priceFreezeFareQuoteErrorTitlesTypeSelector,
  priceFreezeFareQuoteAcknowledgedSelector,
  totalPassengersCountSelector,
  (
    priceFreezeOfferCallState,
    generateCustomPriceFreezeOfferCallState,
    priceFreezeFareQuotePerPassengerTotalPricing,
    priceFreezeFareQuoteCallState,
    priceFreezeFareQuoteErrorTitlesType,
    priceFreezeFareQuoteAcknowledged,
    totalPassengersCount
  ): ErrorTitles | null => {
    if (
      priceFreezeOfferCallState === CallState.Failed ||
      priceFreezeFareQuoteCallState === CallState.Failed
    ) {
      return {
        title: textConstants.GENERIC_ERROR_TITLE,
        subtitle: textConstants.GENERIC_ERROR_SUBTITLE,
        icon: textConstants.ERROR_ICON,
        primaryButtonText: textConstants.TRY_AGAIN,
        type: ErrorModalType.PRICE_FREEZE_PURCHASE_SET_BOOK_PARAMS_FAILED,
      };
    } else if (generateCustomPriceFreezeOfferCallState === CallState.Failed) {
      return {
        title: textConstants.PRICE_FREEZE_GENERATE_CUSTOM_OFFER_FAILED_TITLE,
        subtitle:
          textConstants.PRICE_FREEZE_GENERATE_CUSTOM_OFFER_FAILED_SUBTITLE_WITH_LINE_BREAK,
        mobileSubtitle:
          textConstants.PRICE_FREEZE_GENERATE_CUSTOM_OFFER_FAILED_SUBTITLE,
        icon: textConstants.ERROR_ICON,
        primaryButtonText: textConstants.RETRY,
        secondaryButtonText: textConstants.SEARCH_AGAIN,
        useHtml: true,
        type: ErrorModalType.PRICE_FREEZE_PURCHASE_GENERATE_CUSTOM_OFFER_FAILED,
      };
    } else if (
      priceFreezeFareQuoteErrorTitlesType &&
      !priceFreezeFareQuoteAcknowledged
    ) {
      const totalPrice = priceFreezeFareQuotePerPassengerTotalPricing
        ? getTotalPriceText({
            price: priceFreezeFareQuotePerPassengerTotalPricing.fiat,
          })
        : "";

      switch (priceFreezeFareQuoteErrorTitlesType) {
        case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED:
        case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED_DURING_PAYMENT:
          return {
            title:
              textConstants.PRICE_FREEZE_FROZEN_PRICE_INCREASE_TITLE(
                totalPrice
              ),
            subtitle:
              textConstants.PRICE_FREEZE_FROZEN_PRICE_CHANGE_SUBTITLE(true),
            icon: textConstants.PRICE_INCREASE_ICON,
            primaryButtonText: textConstants.CONTINUE_TO_FREEZE,
            secondaryButtonText: textConstants.SEARCH_AGAIN,
            useHtml: true,
            type: priceFreezeFareQuoteErrorTitlesType,
          };
        case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED:
        case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED_DURING_PAYMENT:
          return {
            title:
              textConstants.PRICE_FREEZE_FROZEN_PRICE_DECREASE_TITLE(
                totalPrice
              ),
            subtitle:
              textConstants.PRICE_FREEZE_FROZEN_PRICE_CHANGE_SUBTITLE(false),
            icon: textConstants.PRICE_DECREASE_ICON,
            primaryButtonText: textConstants.CONTINUE_TO_FREEZE,
            useHtml: true,
            type: priceFreezeFareQuoteErrorTitlesType,
          };
        case ErrorModalType.PRICE_FREEZE_PURCHASE_HAS_NO_AVAILABILITY:
          return {
            title: textConstants.getNoAvailabilityTitle(totalPassengersCount),
            subtitle:
              textConstants.getNoAvailabilitySubtitle(totalPassengersCount),
            icon: textConstants.UNABLED_ICON,
            primaryButtonText: textConstants.SEARCH_AGAIN,
            secondaryButtonText:
              totalPassengersCount > 1
                ? textConstants.NO_AVAILABILITY_EDIT_TRAVELER_TEXT
                : undefined,
            type: priceFreezeFareQuoteErrorTitlesType,
          };
        default:
          return null;
      }
    }

    return null;
  }
);

const getPriceQuoteAndFinalizedError = createSelector(
  getPriceQuoteErrors,
  getFinalizedErrors,
  totalPassengersCountSelector,
  currentPriceFreezeSelector,
  fetchSimilarFlightsCallStateSelector,
  getIsSimilarFlightsEnabled,
  getFlightBookType,
  getSelectedSeats,
  (
    priceQuoteErrors,
    finalizedErrors,
    totalPassengersCount,
    priceFreeze,
    fetchSimilarFlightsCallState,
    isSimilarFlightsEnabled,
    flightBookType,
    selectedSeats
  ) => {
    const errors =
      finalizedErrors.length > 0
        ? finalizedErrors
        : priceQuoteErrors.length > 0
        ? priceQuoteErrors
        : null;
    const totalTravelers = priceFreeze
      ? getTotalPassengerFromPaxPricing(priceFreeze?.frozenFare.paxPricings)
      : undefined;
    const priceFreezePerPaxPaid =
      priceFreeze?.priceFreeze.offer.perPaxAmount.fiat;
    // note: multiplication logic will be removed once the BE is ready
    const priceFreezePaid = priceFreezePerPaxPaid
      ? {
          ...priceFreezePerPaxPaid,
          value:
            priceFreezePerPaxPaid.value * (totalTravelers ? totalTravelers : 1),
        }
      : undefined;

    if (errors) {
      return getErrorTitlesFromPaymentError({
        errors,
        numTravelers: totalPassengersCount,
        flightBookType,
        priceFreezePaid,
        priceFreezeTravelers: totalTravelers,
        fetchSimilarFlightsCallState,
        isSimilarFlightsEnabled,
        hasSelectedSeats: selectedSeats.length > 0,
        priceFreeze,
      });
    }

    return null;
  }
);

export const getErrorTitles = createSelector(
  getHasError,
  getHasFlightFreezeError,
  getPriceDifference,
  getSchedulePaymentError,
  getSchedulePriceQuoteError,
  getVerifyPaymentMethodResult,
  getFlightBookErrorTitle,
  getSetFlightBookQueryParamsCallState,
  getFlightFreezeErrorTitle,
  getPriceQuoteAndFinalizedError,
  getFlightBookType,
  getInfantOnlyError,
  getTravelWalletItemsToApply,
  hasAddOnBeenAttachedSelector,
  (
    hasError,
    hasFlightFreezeError,
    getPriceDifference,
    schedulePaymentError,
    schedulePriceQuoteError,
    verifyPaymentMethodResult,
    flightBookErrorTitle,
    setFlightBookQueryParamsCallState,
    flightFreezeErrorTitle,
    priceQuoteAndFinalizedError,
    flightBookType,
    infantOnlyError,
    travelWalletItemsToApply,
    hasAddOnBeenAttached
  ): ErrorTitles => {
    if (!hasError && !hasFlightFreezeError) return { title: "", subtitle: "" };
    const {
      amount,
      amountPerTraveler,
      currency,
      hasDifference,
      isIncrease,
      predictedTotal,
      priceQuoteTotal,
    } = getPriceDifference;

    if (hasDifference && amount && amountPerTraveler) {
      const primaryButtonText = textConstants.CONTINUE;
      const offerToApplyAmount =
        travelWalletItemsToApply.offerToApply?.amount.amount || 0;
      const creditToApplyAmount =
        travelWalletItemsToApply.creditToApply?.amount.amount || 0;

      const walletItemCoverageChanged =
        (travelWalletItemsToApply.offerToApply ||
          travelWalletItemsToApply.creditToApply) &&
        Math.abs(offerToApplyAmount + creditToApplyAmount) >= predictedTotal &&
        Math.abs(offerToApplyAmount + creditToApplyAmount) < priceQuoteTotal;

      if (walletItemCoverageChanged) {
        return {
          title: travelWalletItemsToApply.creditToApply
            ? textConstants.PRICE_INCREASE_CREDITS_COVERAGE_TITLE
            : textConstants.PRICE_INCREASE_OFFER_COVERAGE_TITLE,
          subtitle: textConstants.PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE,
          primaryButtonText,
          secondaryButtonText: textConstants.CHOOSE_ANOTHER_FLIGHT,
          icon: isIncrease
            ? textConstants.PRICE_INCREASE_ICON
            : textConstants.PRICE_DECREASE_ICON,
          type: ErrorModalType.FLIGHTS_PRICE_QUOTE_HAS_PRICE_DIFFERENCE,
        };
      }

      if (
        isIncrease &&
        hasAddOnBeenAttached &&
        (flightBookType === FlightBookType.DEFAULT ||
          flightBookType === FlightBookType.PRICE_FREEZE_EXERCISE)
      ) {
        return {
          title: textConstants.getPriceDifferenceTitle({
            isIncrease: true,
            amount: amountPerTraveler,
            currency,
            showPerTraveler: true,
          }),
          subtitle: textConstants.PRICE_INCREASE_WITH_ADD_ON_SUBTITLE,
          primaryButtonText,
          secondaryButtonText:
            flightBookType === FlightBookType.DEFAULT
              ? textConstants.CHOOSE_FLIGHT
              : undefined,
          icon: textConstants.PRICE_INCREASE_ICON,
          useHtml: true,
          type: ErrorModalType.FLIGHTS_PRICE_QUOTE_WITH_ADD_ON_HAS_PRICE_DIFFERENCE,
        };
      }

      return {
        title: textConstants.getPriceDifferenceTitle({
          isIncrease,
          amount,
          currency,
        }),
        subtitle: isIncrease
          ? textConstants.PRICE_INCREASE_SUBTITLE
          : textConstants.PRICE_DECREASE_SUBTITLE,
        primaryButtonText,
        icon: isIncrease
          ? textConstants.PRICE_INCREASE_ICON
          : textConstants.PRICE_DECREASE_ICON,
        type: ErrorModalType.FLIGHTS_PRICE_QUOTE_HAS_PRICE_DIFFERENCE,
      };
    }

    if (schedulePaymentError) {
      return {
        title: textConstants.GENERIC_ERROR_TITLE,
        subtitle: textConstants.GENERIC_ERROR_SUBTITLE,
        icon: textConstants.ERROR_ICON,
      };
    }

    if (schedulePriceQuoteError) {
      return {
        title: textConstants.GENERIC_ERROR_TITLE,
        subtitle: textConstants.GENERIC_ERROR_SUBTITLE,
        icon: textConstants.ERROR_ICON,
      };
    }

    if (priceQuoteAndFinalizedError) {
      return priceQuoteAndFinalizedError;
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      const error = verifyPaymentMethodResult.toString();
      switch (error) {
        case "InvalidCVV":
          return {
            agentSubtitle: getAgentErrorSubtitle(error),
            agentTitle: getAgentErrorTitle(error),
            icon: textConstants.ERROR_ICON,
            title: "CVV Error",
            subtitle:
              "Ensure your CVV / Security Code is entered correctly and try again.",
            primaryButtonText: textConstants.EDIT_PAYMENT_INFO,
          };
        case "InvalidAddress":
          return {
            agentSubtitle: getAgentErrorSubtitle(error),
            agentTitle: getAgentErrorTitle(error),
            icon: textConstants.ERROR_ICON,
            title: "Address Verification Error",
            subtitle:
              "Ensure your Zip Code and Country is entered correctly and try again.",
            primaryButtonText: textConstants.EDIT_PAYMENT_INFO,
          };
        default:
        case "GenericError":
          return {
            agentSubtitle: getAgentErrorSubtitle(error),
            agentTitle: getAgentErrorTitle(error),
            icon: textConstants.ERROR_ICON,
            title: "Error Adding Payment Method",
            subtitle:
              "We were unable to add your payment method. Please ensure all of your information is correct and try again.",
            primaryButtonText: textConstants.EDIT_PAYMENT_INFO,
          };
      }
    }

    if (infantOnlyError) {
      return {
        title: textConstants.INVALID_PASSENGERS,
        subtitle: textConstants.INFANT_ONLY_SUBTITLE,
        icon: textConstants.ERROR_ICON,
        primaryButtonText: textConstants.UPDATE_TRAVELERS,
      };
    }

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        if (flightFreezeErrorTitle) {
          return flightFreezeErrorTitle;
        }
        break;
      case FlightBookType.PRICE_FREEZE_EXERCISE:
        if (setFlightBookQueryParamsCallState === CallState.Failed) {
          return {
            title: textConstants.GENERIC_ERROR_TITLE,
            subtitle: textConstants.GENERIC_ERROR_SUBTITLE,
            icon: textConstants.ERROR_ICON,
            primaryButtonText: textConstants.TRY_AGAIN,
            type: ErrorModalType.PRICE_FREEZE_EXERCISE_SET_BOOK_PARAMS_FAILED,
          };
        } else {
          if (flightBookErrorTitle) {
            return flightBookErrorTitle;
          }
        }
        break;
      case FlightBookType.DEFAULT:
        if (flightBookErrorTitle) {
          return flightBookErrorTitle;
        }
        break;
      default:
        break;
    }

    return {
      title: textConstants.GENERIC_ERROR_TITLE,
      subtitle: textConstants.GENERIC_ERROR_SUBTITLE,
      icon: textConstants.ERROR_ICON,
    };
  }
);

export const getModalEventType = createSelector(
  getPriceDifference,
  getPriceQuoteErrors,
  getFinalizedErrors,
  getPassengersValidErrors,
  getSchedulePaymentError,
  getSchedulePriceQuoteError,
  getTripPricingErrors,
  getVerifyPaymentMethodResult,
  // getIsConfirmAndBookReady,
  getFlightBookType,
  (
    getPriceDifference,
    priceQuoteErrors: (RedmondPurchaseError | FlightError)[],
    finalizedErrors: (RedmondPurchaseError | FlightError)[],
    passengerErrors: PassengerError[],
    schedulePaymentError,
    schedulePriceQuoteError,
    tripPricingErrors,
    verifyPaymentMethodResult,
    // isConfirmAndBookReady
    flightBookType
  ): string => {
    if (getPriceDifference.hasDifference && getPriceDifference.amount) {
      return "flight_price_difference";
    }

    if (schedulePaymentError) {
      return "schedule_payment_error";
    }

    if (schedulePriceQuoteError) {
      return "schedule_price_quote_error";
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      return "payment_verification_failed";
    }

    if (finalizedErrors.length > 0) {
      return "poll_finalized_failed";
    }

    if (priceQuoteErrors.length > 0) {
      return "poll_price_quote_failed";
    }

    if (flightBookType === FlightBookType.DEFAULT) {
      if (passengerErrors.length > 0) {
        return "invalid_passengers";
      }

      if (tripPricingErrors.length > 0) {
        return "trip_pricing_failed";
      }
    }

    return "";
  }
);

// note: price freeze only selectors
export const priceFreezePriceQuoteParamsSelector = createSelector(
  selectedTripSelector,
  currentPriceFreezeOfferSelector,
  passengerCountSelector,
  getContactEmail,
  (
    selectedTrip,
    offer,
    counts,
    email
  ): PriceFreezeSchedulePriceQuoteRequest => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    let fareId = selectedOWRTrip.returnFareId
      ? selectedOWRTrip.returnFareId
      : selectedOWRTrip.outgoingFareId;

    const params: PriceFreezeSchedulePriceQuoteRequest = {
      offerId: offer?.id || "",
      itinerary: {
        tripId: selectedTrip.tripId || "",
        fareId: fareId || "",
        Itinerary: PriceFreezeItineraryEnum.SingleItinerary,
      },
      passengers: counts,
      emailAddress: email || "",
      // TODO: phone number is hard coded in the FE for now; see https://hopchat.slack.com/archives/C02FB9LK0G7/p1633102094087400;
      // remove it once the BE is ready
      phoneNumber: "+14165550134",
    };
    return params;
  }
);

export const priceFreezeOfferPerPassengerInRewards = createSelector(
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  activePriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  (rewardsPaymentAccount, rewardsAccountWithLargestValue, quoteData, offer) => {
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    if ((!quoteData && !offer) || !activeRewardsAccount) return null;

    const { accountReferenceId } = activeRewardsAccount;
    return (
      quoteData?.perPaxAmount.rewards[accountReferenceId] ??
      offer?.perPaxAmount.rewards[accountReferenceId]
    );
  }
);

export const priceFreezeOfferPerPassengerAmountSelector = createSelector(
  activePriceFreezeQuoteDataSelector,
  currentPriceFreezeOfferSelector,
  priceFreezeOfferPerPassengerInRewards,
  (
    quoteData,
    offer,
    freezePerPassengerInRewards
  ): { fiat: FiatPrice; rewards: RewardsPrice } | null => {
    const fiat = quoteData?.perPaxAmount.fiat ?? offer?.perPaxAmount.fiat;

    if (fiat && freezePerPassengerInRewards) {
      return {
        fiat,
        rewards: freezePerPassengerInRewards,
      };
    }
    return null;
  }
);

export const priceFreezeOfferTotalPassengerSelector = createSelector(
  priceFreezeFrozenPricingSelector,
  currentPriceFreezeOfferSelector,
  (frozenPricing, offer): number | null => {
    if (frozenPricing) {
      return frozenPricing.passengerQuantity;
    } else if (offer) {
      // note: the offer part of a price freeze is immutable, so it will always be returning
      // the same number of passengers as that of the shop summary response (which is based on flight search input)
      return getTotalPassenger(offer.passengers);
    }
    return null;
  }
);

export const priceFreezeQuoteSummaryLineItemsSelector = createSelector(
  priceFreezeOfferTotalAmountSelector,
  priceFreezeOfferPerPassengerAmountSelector,
  priceFreezeOfferCurrencySelector,
  getRewardsPaymentAccountIfRedemptionEnabled,
  getRewardsPaymentAccountReferenceId,
  getRewardsPaymentInFiatCurrency,
  getUserSelectedPaymentMethod,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  priceFreezeOfferTotalPassengerSelector,
  getFlightFreezeCreditToApply,
  getPriceFreezeRewardsPaymentInFiatCurrency,
  getPriceFreezeRewardsPaymentInRewardsCurrency,
  (
    freezeTotalInPrices,
    freezePerPassengerInPrices,
    currency,
    rewardsPaymentAccount,
    rewardsPaymentAccountReferenceId,
    rewardsPaymentInFiatCurrency,
    userSelectedPaymentMethod,
    totalCreditCardPaymentRequired,
    totalPassengersCount,
    creditToApply,
    pfRewardsPaymentInFiatCurrency,
    pfRewardsPaymentInRewardsCurrency
  ): ISummaryLineItem[] => {
    let lineItems: ISummaryLineItem[] = [];
    const activeRewardsAccount = rewardsPaymentAccount;

    const rewardsActive =
      activeRewardsAccount &&
      rewardsPaymentAccountReferenceId &&
      (rewardsPaymentInFiatCurrency?.value || 0) > 0;

    const creditsActive = freezeTotalInPrices && creditToApply;

    if (freezePerPassengerInPrices) {
      lineItems.push({
        type: "custom",
        label: textConstants.FEE_PER_TRAVELLER,
        fiatPrice: freezePerPassengerInPrices.fiat,
      });
    }

    if (freezeTotalInPrices) {
      lineItems.push({
        type: "total",
        fiatPrice: freezeTotalInPrices.fiat,
        rewardsPrice: !!activeRewardsAccount
          ? freezeTotalInPrices.rewards
          : undefined,
        label: textConstants.TOTAL_AMOUNT_LABEL(totalPassengersCount ?? 0),
      });
    }

    if (creditsActive) {
      const breakdownHasStatementCredit = !!creditToApply.breakdown?.some(
        (detail) =>
          detail.CreditDetail === "Statement" &&
          Math.abs(detail.usableAmount.amount) > 0
      );

      const creditsAmount =
        Math.abs(creditToApply.amount.amount) > freezeTotalInPrices.fiat.value
          ? freezeTotalInPrices.fiat.value * -1
          : creditToApply.amount.amount;

      lineItems.push({
        type: "custom",
        icon: "piggy-bank-icon",
        label: textConstants.TRAVEL_CREDITS_APPLIED,
        fiatPrice: {
          currencyCode: creditToApply.amount.currency,
          currencySymbol: getCurrencySymbol(creditToApply.amount.currency),
          value: creditsAmount,
        },
        className: "travel-wallet-line",
        travelCreditBreakdown: breakdownHasStatementCredit
          ? getCheckoutCreditBreakdown(
              creditToApply.breakdown || [],
              creditsAmount,
              creditToApply.amount.currency
            )
          : [],
      });

      if (!rewardsActive) {
        lineItems.push({
          type: "payment",
          fiatPrice: totalCreditCardPaymentRequired,
          lastFour: userSelectedPaymentMethod?.last4,
        });
      }
    }

    if (rewardsActive) {
      let fiatPrice: FiatPrice;
      let rewardsPrice: RewardsPrice;

      fiatPrice = pfRewardsPaymentInFiatCurrency
        ? pfRewardsPaymentInFiatCurrency
        : {
            ...currency,
            value: 0,
          };
      rewardsPrice = pfRewardsPaymentInRewardsCurrency
        ? pfRewardsPaymentInRewardsCurrency
        : {
            currency: activeRewardsAccount.rewardsBalance.currency,
            value: 0,
          };

      lineItems.push({
        type: "rewards",
        fiatPrice: fiatPrice,
        rewardsAccountName: activeRewardsAccount.productDisplayName,
        rewardsPrice: rewardsPrice,
      });

      if (!creditsActive) {
        lineItems.push({
          type: "payment",
          fiatPrice: totalCreditCardPaymentRequired,
          lastFour: userSelectedPaymentMethod?.last4,
        });
      }
    }

    if (creditsActive && rewardsActive) {
      lineItems.push({
        type: "payment",
        fiatPrice: totalCreditCardPaymentRequired,
        lastFour: userSelectedPaymentMethod?.last4,
      });
    }

    return lineItems;
  }
);

export const getIsSettingUpFlightFreezeParams = createSelector(
  setUpFlightFreezeParamsCallStateSelector,
  (setUpFlightFreezeParamsCallState) =>
    setUpFlightFreezeParamsCallState === CallState.InProcess ||
    // note: the loading popover is supposed to show up on start
    setUpFlightFreezeParamsCallState === CallState.NotCalled
);

export const getPriceFreezeConfirmationProps = createSelector(
  getFinalizedPriceFreeze,
  getFinalizedPriceFreezeTripDetails,
  finalizedPriceFreezeFare,
  getFinalizedUserEmail,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  (
    priceFreeze,
    priceFreezeTripDetails,
    priceFreezeFrozenFare,
    finalizedUserEmail,
    selectedRewardsAccountId
  ) => {
    let priceFreezeFlightPricePerPax = { fiat: "", rewards: "" };
    let priceFreezePerPax = { fiat: "", rewards: "" };
    if (priceFreeze && priceFreezeTripDetails) {
      //Trip price

      //TODO: select the correct fare instead of the first one
      const priceFreezeFlightPrice =
        priceFreezeFrozenFare.paxPricings[0].pricing.totalAmount;
      const currentRewardsForFlight = selectedRewardsAccountId
        ? priceFreezeFlightPrice?.rewards[selectedRewardsAccountId]
        : undefined;
      priceFreezeFlightPricePerPax = {
        fiat: priceFreezeFlightPrice
          ? getTotalPriceText({
              price: priceFreezeFlightPrice?.fiat,
            })
          : "",
        rewards: currentRewardsForFlight
          ? getRewardText({ reward: currentRewardsForFlight })
          : "",
      };

      //Price Freeze price
      const priceFreezePaxPrice = priceFreeze.offer.perPaxAmount;
      const currentRewardsForPriceFreeze = selectedRewardsAccountId
        ? priceFreezePaxPrice?.rewards[selectedRewardsAccountId]
        : undefined;
      priceFreezePerPax = {
        fiat: priceFreezePaxPrice
          ? getTotalPriceText({
              price: priceFreezePaxPrice?.fiat,
            })
          : "",
        rewards: currentRewardsForPriceFreeze
          ? getRewardText({ reward: currentRewardsForPriceFreeze })
          : "",
      };
    }
    return {
      priceFreezeTripSlices: priceFreezeTripDetails?.slices,
      totalTravelers: priceFreezeTripDetails?.fareDetails[0]
        ? getTotalPassengerFromPaxPricing(
            priceFreezeTripDetails.fareDetails[0].paxPricings
          )
        : 0,
      expiresAt: priceFreeze?.expiresAt,
      frozenPriceDuration: priceFreeze?.offer.timeToLive,
      priceFreezeFlightPerPax: priceFreezeFlightPricePerPax,
      priceFreezePerPax: priceFreezePerPax,
      priceFreezeExternalId:
        typeof priceFreeze?.externalId === "string"
          ? priceFreeze?.externalId
          : priceFreeze?.externalId.value,
      userEmail: finalizedUserEmail,
      isRoundTrip: priceFreezeTripDetails?.slices
        ? priceFreezeTripDetails?.slices.length > 1
        : false,
    };
  }
);

export const selectedPriceFreezePurchasePropertiesSelector = createSelector(
  changedPriceFreezeDurationPropertiesSelector,
  getFinalizedPriceFreeze,
  (
    changedPriceFreezeDurationProperties,
    priceFreeze
  ): SelectedPriceFreezePurchaseProperties => {
    return {
      ...changedPriceFreezeDurationProperties,
      price_freeze_id: priceFreeze?.id,
    };
  }
);

interface IFrozenPriceDifferenceByPassenger {
  userId: string;
  exercisePrices: {
    afterCreditsApplied: Prices;
    beforeCreditsApplied?: Prices;
  };
}

export const frozenPriceDifferenceByPassengerSelector = createSelector(
  getPriceQuoteWithUpdatedAncillary,
  (priceQuote): IFrozenPriceDifferenceByPassenger[] => {
    if (!priceQuote || !priceQuote.creditSummary) {
      return [];
    }

    const afterExercisePricings =
      priceQuote.itinerary.sellingPricing.pricingByPassenger;

    const beforeExercisePricings =
      priceQuote.creditSummary?.pricingBeforeCredits.pricingByPassenger;

    const frozenPriceDifferenceByPassenger = afterExercisePricings.map(
      (afterExercisePricing) => {
        const personId = afterExercisePricing.person.id;
        const beforeExercisePricing = beforeExercisePricings.find(
          (pricing) => pricing.person.id === personId
        );

        return {
          userId: personId,
          exercisePrices: {
            afterCreditsApplied: afterExercisePricing.total,
            beforeCreditsApplied: beforeExercisePricing?.total,
          },
        };
      }
    );

    return frozenPriceDifferenceByPassenger;
  }
);

interface IFrozenPriceNotification {
  html: string;
  type: CurrentFareVersusCapEnum;
}

export const frozenPriceSummaryNotificationFromPriceFreezeSelector =
  createSelector(
    currentPriceFreezeSelector,
    (priceFreeze): IFrozenPriceNotification | null => {
      if (!priceFreeze) {
        return null;
      }

      const {
        chargeAmount,
        savingsAmount,
        versusCap,
        currentAmount,
        originalAmount,
      } = priceFreeze.frozenFare.paxPricings[0].pricing;

      const priceDropAmountFiat = {
        ...currentAmount.fiat,
        // note: how much the current flight shop price has dropped comparing to the frozen price
        value: originalAmount.fiat.value - currentAmount.fiat.value,
      };

      const newSavingsAmountFiat =
        savingsAmount.fiat.value > 0 ? savingsAmount.fiat : priceDropAmountFiat;

      if (newSavingsAmountFiat.value <= 0) {
        return null;
      }

      switch (versusCap.CurrentFareVersusCap) {
        case CurrentFareVersusCapEnum.FareOverCap:
          return {
            html: textConstants.EXCEEDED_MAX_CAP_NOTIFICATION(
              newSavingsAmountFiat,
              chargeAmount.fiat,
              currentAmount.fiat
            ),
            type: versusCap.CurrentFareVersusCap,
          };
        case CurrentFareVersusCapEnum.FareUnderCap:
          return {
            html: textConstants.PRICE_DROP_NOTIFICATION(
              newSavingsAmountFiat,
              chargeAmount.fiat
            ),
            type: versusCap.CurrentFareVersusCap,
          };
        default:
          return null;
      }
    }
  );

export const frozenPriceSummaryNotificationFromPriceQuoteSelector =
  createSelector(
    getPriceQuoteWithUpdatedAncillary,
    currentPriceFreezeSelector,
    firstPersonWithFrozenPriceSelector,
    (
      priceQuote,
      priceFreeze,
      firstPersonWithFrozenPrice
    ): IFrozenPriceNotification | null => {
      if (!firstPersonWithFrozenPrice) {
        return null;
      }

      const chargeAmountInFiat =
        priceQuote?.itinerary.sellingPricing.pricingByPassenger.find(
          (pricing) => pricing.person.id === firstPersonWithFrozenPrice.personId
        )?.total.fiat;
      const savingsAmountInFiat =
        firstPersonWithFrozenPrice.priceFreezeExerciseCredit?.creditAmount.fiat;
      const chargeAmountBeforeCreditsPerPaxInFiat =
        priceQuote?.creditSummary?.pricingBeforeCredits.pricingByPassenger.find(
          (pricing) => pricing.person.id === firstPersonWithFrozenPrice.personId
        )?.total.fiat;
      const frozenPricePerPaxInFiat =
        priceFreeze?.priceFreeze.offer.perPaxAmount.fiat;
      const offerPerPaxCapInFiat =
        priceQuote?.creditSummary?.credits.totalCredits
          .priceFreezeExerciseCredit?.offerPerPaxCap?.fiat;

      if (
        chargeAmountInFiat &&
        savingsAmountInFiat &&
        chargeAmountBeforeCreditsPerPaxInFiat &&
        frozenPricePerPaxInFiat &&
        offerPerPaxCapInFiat
      ) {
        const priceDropAmountFiat = {
          ...chargeAmountBeforeCreditsPerPaxInFiat,
          // note: how much the current price quote price (before applying credits) has dropped comparing to the frozen price
          value:
            frozenPricePerPaxInFiat.value -
            chargeAmountBeforeCreditsPerPaxInFiat.value,
        };

        // note: when savingsAmountInFiat.value is <= 0, it has to be that the current price is <= the frozen price
        const newSavingsAmountFiat =
          savingsAmountInFiat.value > 0
            ? savingsAmountInFiat
            : priceDropAmountFiat;

        // note: priceFreezeExerciseCredit.creditAmount can not be greater than the cap, so it's basically checking for equality
        const isFareOverCap =
          savingsAmountInFiat.value >= offerPerPaxCapInFiat.value;

        if (isFareOverCap) {
          return {
            html: textConstants.EXCEEDED_MAX_CAP_NOTIFICATION(
              newSavingsAmountFiat,
              chargeAmountInFiat,
              chargeAmountBeforeCreditsPerPaxInFiat
            ),
            type: CurrentFareVersusCapEnum.FareOverCap,
          };
        } else if (newSavingsAmountFiat.value > 0) {
          return {
            html: textConstants.PRICE_DROP_NOTIFICATION(
              newSavingsAmountFiat,
              chargeAmountInFiat
            ),
            type: CurrentFareVersusCapEnum.FareUnderCap,
          };
        }
      }

      return null;
    }
  );

export const frozenPriceSummaryNotificationSelector = createSelector(
  frozenPriceSummaryNotificationFromPriceFreezeSelector,
  frozenPriceSummaryNotificationFromPriceQuoteSelector,
  firstPersonWithFrozenPriceSelector,
  (
    frozenPriceSummaryNotificationFromPriceFreeze,
    frozenPriceSummaryNotificationFromPriceQuote,
    firstPersonWithFrozenPrice
  ): IFrozenPriceNotification | null => {
    return firstPersonWithFrozenPrice
      ? frozenPriceSummaryNotificationFromPriceQuote
      : frozenPriceSummaryNotificationFromPriceFreeze;
  }
);

export const getIsTripInternational = createSelector(
  flightsByFlightShopTypeSelector,
  selectedTripSelector,
  tripDetailsByIdSelector,
  (flights, selectedTrip, tripDetailsById) => {
    if (selectedTrip.tripId) {
      const tripDetails = tripDetailsById[selectedTrip.tripId];
      const airportMap = flights?.airports;
      const airportCodes = tripDetails.slices.flatMap((slice) => [
        slice.originCode,
        slice.destinationCode,
      ]);

      return airportCodes.some(
        (code) =>
          airportMap?.[code]?.geography.countryCode !==
          airportMap?.[airportCodes[0]]?.geography.countryCode
      );
    } else {
      return false;
    }
  }
);

export const getIsTripVoidWindowEligible = createSelector(
  flightsByFlightShopTypeSelector,
  selectedTripSelector,
  tripDetailsByIdSelector,
  (flights, selectedTrip, tripDetailsById) => {
    if (selectedTrip.tripId) {
      const tripDetails = tripDetailsById[selectedTrip.tripId];
      const airportMap = flights?.airports;
      const airportCodes = tripDetails.slices.flatMap((slice) => [
        slice.originCode,
        slice.destinationCode,
      ]);

      const outgoingSlice = tripDetails.slices.find((slice) => slice.outgoing);

      const hasUSAirport = airportCodes.some(
        (code) => airportMap?.[code]?.geography.countryCode === "US"
      );
      const isWeekBeforeDeparture =
        dayjs(outgoingSlice?.departureTime).diff(new Date(), "days") >= 7;

      const hasTravelFusionSegment = tripDetails.fareDetails.some((detail) =>
        detail.slices.some((slice) =>
          slice.fareDetails.segments.some(
            (segment) => !!segment.brand?.travelFusionFareBrand
          )
        )
      );

      return hasUSAirport && isWeekBeforeDeparture && !hasTravelFusionSegment;
    }
    return false;
  }
);

export const getShopSummarySearchString = createSelector(
  getTripCategory,
  selectedTripSelector,
  tripDetailsByIdSelector,
  (tripCategory, selectedTrip, tripDetailsById) => {
    if (selectedTrip.tripId) {
      const tripDetails = tripDetailsById[selectedTrip.tripId];

      const isMulticity = tripCategory === TripCategory.MULTI_CITY;

      let queryParams = {};

      if (isMulticity && tripDetails) {
        const departureDetails = getMulticityParamsFromTripDetails(tripDetails);

        queryParams = {
          tripCategory,
          ...departureDetails,
        };
      }

      if (!isMulticity && tripDetails) {
        const departureSlice =
          tripDetails.slices[getSliceIndex(true, tripDetails)];
        const returnSlice =
          tripDetails.slices[getSliceIndex(false, tripDetails)];

        queryParams = {
          tripCategory,
          origin: departureSlice.originCode,
          destination: departureSlice.destinationCode,
          departureDate: dayjs(departureSlice.departureTime).format(
            "YYYY-MM-DD"
          ),
          returnDate:
            tripCategory === TripCategory.ROUND_TRIP && returnSlice
              ? dayjs(returnSlice.arrivalTime).format("YYYY-MM-DD")
              : undefined,
        };
      }
      return queryStringParser.stringify(queryParams);
    }
    return "";
  }
);

export const getPurchaseErrorFromLegacy = (
  error: LegacyPaymentError
): RedmondPurchaseError => {
  switch (error.PaymentError) {
    case PaymentErrorEnum.NoAvailability:
      return GeneralPurchaseErrorEnum.NoAvailability;
    case PaymentErrorEnum.FraudAutoReject:
      return GeneralPurchaseErrorEnum.FraudAutoReject;
    default:
      return {
        title: error.PaymentError,
        subtitle: error.code || error.message || error.msg || error.cause || "",
        code: error.code,
      } as UncategorizedPurchaseError;
  }
};

// parse errors and put into intermediate type
export const getPurchaseError = (
  error: PurchaseError
): RedmondPurchaseError | FlightError => {
  switch (error.Error) {
    case PurchaseErrorEnum.ErrorCode:
      const errorCode = error as ErrorCode;
      return {
        code: errorCode.code,
        title: errorCode?.code?.match(/[A-Z][a-z]+/g)?.join(" "),
        subtitle: errorCode.message || errorCode.cause || "",
      } as UncategorizedPurchaseError;
    case PurchaseErrorEnum.FraudAutoReject:
      return GeneralPurchaseErrorEnum.FraudAutoReject;
    case PurchaseErrorEnum.NoAvailability:
      return GeneralPurchaseErrorEnum.NoAvailability;
    case PurchaseErrorEnum.InActivity:
      return GeneralPurchaseErrorEnum.InActivity;
    case PurchaseErrorEnum.InvalidSession:
      return GeneralPurchaseErrorEnum.InvalidSession;
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      switch (paymentError.value.type) {
        case Payment.Rewards:
          const rewardsError =
            paymentError.value.value.RewardsError ||
            (paymentError.value.value as LegacyPaymentError).PaymentError;
          switch (rewardsError) {
            case PaymentErrorEnum.RedemptionFailure:
              return PaymentPurchaseErrorEnum.RedemptionFailure;
            default:
              return getPurchaseErrorFromLegacy(rewardsError);
          }
        case Payment.Card:
          const cardError =
            paymentError.value.value.CardError ||
            (paymentError.value.value as LegacyPaymentError).PaymentError;
          switch (cardError) {
            case PaymentErrorEnum.CardDeclined:
              return PaymentPurchaseErrorEnum.CardDeclined;
            default:
              return getPurchaseErrorFromLegacy(cardError);
          }
        default:
          throw new Error("Unknown Payment Error");
      }
    case PurchaseErrorEnum.ProductError:
      const purchaseError = error as ProductError;
      switch (purchaseError.value.type) {
        case Product.AirPriceFreeze:
          const priceFreezeError = purchaseError.value
            .value as LegacyPaymentError;
          // todo check for price freeze specific errors
          return getPurchaseErrorFromLegacy(priceFreezeError);
        case Product.Flight:
          return purchaseError.value.value as FlightError;
        default:
          throw new Error("Unknown Product Error");
      }
    default:
      throw new Error(`Unexpected error type: ${error.Error}`);
  }
};

// copy of [[errorHelpers]] implementation but targetted towards flights
export const getAgentErrorTitleFromPurchaseError = (
  error: RedmondPurchaseError | FlightError
): string => {
  if ((error as UncategorizedPurchaseError).code) {
    return (error as UncategorizedPurchaseError).title;
  }
  switch (error) {
    case PaymentPurchaseErrorEnum.CardDeclined:
      return "Card Declined";
    case FlightError.DuplicateBooking:
      return "Duplicate Booking Found";
    case FlightError.InvalidPassengers:
    case FlightError.ExpiredPassport:
    case FlightError.IllegalLapInfantKTN:
    case FlightError.IllegalLapInfantRedress:
    case FlightError.InvalidCustomer:
    case FlightError.InvalidPassengers:
    case FlightError.LapInfantTooOld:
    case FlightError.LapInfantsUnsupported:
    case FlightError.MalformedKnownTravelerNumber:
    case FlightError.MalformedRedressNumber:
    case FlightError.MissingPassport:
    case FlightError.NoAdultPassenger:
    case FlightError.NoContactInformation:
    case FlightError.SeatedInfantsUnsupported:
    case FlightError.TooManyLapInfants:
    case FlightError.TooManyPassengers:
      return "Invalid Passengers";
    case FlightError.MissingAirlineLocator:
      return "Locator Failed To Generate";
    case GeneralPurchaseErrorEnum.NoAvailability:
      return "No Availability";
    case FlightError.TransientServiceError:
      return "Transient GDS Error";
    case FlightError.UnknownSabreAppError:
      return "Sabre Error";
    case FlightError.CheckInMinimumAgeNotMet:
      return "The hotel did not accept your booking";
    case GeneralPurchaseErrorEnum.FraudAutoReject:
    case GeneralPurchaseErrorEnum.LikelyFraud:
      return "Likely Fraud (Do Not Inform Customer)";
    case PaymentPurchaseErrorEnum.RedemptionFailure:
      return "Issue Redeeming Rewards";
    default:
      return GENERIC_ERR_TITLE;
  }
};

export const getAgentErrorSubtitleFromPurchaseError = (
  error: RedmondPurchaseError | FlightError
): string => {
  if ((error as UncategorizedPurchaseError).code) {
    return (error as UncategorizedPurchaseError).subtitle;
  }
  switch (error) {
    case PaymentPurchaseErrorEnum.CardDeclined:
      return "Reconfirm that details are correct or try a different card.";
    case FlightError.DuplicateBooking:
      return "Identical booking found, confirm whether this booking is already completed.";
    case FlightError.InvalidPassengers:
      return "Confirm whether booking contains unsupported passengers, e.g. infants, solo young travellers.";
    case FlightError.MissingAirlineLocator:
      return "Inventory no longer available, try a different booking.";
    case GeneralPurchaseErrorEnum.NoAvailability:
      return "Inventory no longer available, try a different booking.";
    case FlightError.NoTicketlessResponse:
      return "This could be due to inventory no longer available. Please try again, if unsuccessful confirm availability.";
    case FlightError.ProviderError:
      return "This is typically an issue with Travelfusion";
    case FlightError.UnknownSabreAppError:
      return "Unknown Sabre issue, try again later.";
    case FlightError.CheckInMinimumAgeNotMet:
      return "The reservation holder did not meet the minimum age requirement of the hotel";
    case FlightError.ExpiredPassport:
      return "Passport is expired";
    case FlightError.IllegalLapInfantKTN:
      return "The lap infant's KTN is invalid";
    case FlightError.IllegalLapInfantRedress:
      return "The lap infant's Redress Number is invalid";
    case FlightError.InvalidCustomer:
      return "At least one adult traveler is required";
    case FlightError.LapInfantTooOld:
      return "The lap infant is too old to qualify";
    case FlightError.LapInfantsUnsupported:
      return "The lap infant is unsupported";
    case GeneralPurchaseErrorEnum.FraudAutoReject:
    case GeneralPurchaseErrorEnum.LikelyFraud:
      return "Advise Customer - There is an issue with you account, please contact Capital One Support";
    case FlightError.MalformedKnownTravelerNumber:
      return "A passenger's KTN is malformed";
    case FlightError.MalformedRedressNumber:
      return "A passenger's Redress Number is malformed";
    case FlightError.MissingPassport:
      return "Passport is missing";
    case FlightError.NoAdultPassenger:
      return "An adult passenger is required";
    case FlightError.NoContactInformation:
      return "Contact information is required";
    case PaymentPurchaseErrorEnum.RedemptionFailure:
      return "We were unable to redeem your rewards for this transaction";
    case FlightError.SeatedInfantsUnsupported:
      return "Seated infants are unsupported";
    case FlightError.TooManyLapInfants:
      return "Too many lap infants";
    case FlightError.TooManyPassengers:
      return "Too many passengers";
    default:
      return GENERIC_ERR_SUBTITLE;
  }
};

// Corporate Travel

export const getPolicyLimit = (state: IStoreState) =>
  state.flightShop.policyLimit;

export const getSubmitForApprovalCallState = (state: IStoreState) =>
  state.flightBook.submitForApprovalCallState;

export const getApprovalRequestReason = (state: IStoreState) =>
  state.flightBook.approvalRequestReason;
