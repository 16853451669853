import React, { useEffect, useState, useMemo, useContext } from "react";
import { Box, Typography, useScrollTrigger } from "@material-ui/core";
import { B2BLoadingPopup, useDeviceTypes } from "halifax";
import clsx from "clsx";
import {
  FLIGHT_PRICES_TEXT,
  REVIEW_ITINERARY_SUBTITLE_TEXT,
  REVIEW_ITINERARY_TITLE_TEXT,
  SEARCHING_FOR_FLIGHTS,
  SEARCHING_FOR_FLIGHTS_SECONDARY_MESSAGE,
  EARN_ENHANCEMENT_SUBTITLE,
  CHOOSING_MULTICITY_FLIGHT_TEXT,
  PARADISE_SUBTITLE,
} from "../constants";
import { FlightShopConnectorProps } from "./container";
import { PATH_HOME, PATH_SHOP } from "../../../utils/urlPaths";
import { RouteComponentProps, useHistory } from "react-router";
import {
  DesktopRewardsHeader,
  RewardsAccountSelection,
} from "../../rewards/components";
import { FlightShopSearchControl } from "../../search/components/FlightShopSearchControlV2";
import {
  FlightShopHeader,
  MobileFareDetails,
  FlightShopReviewItinerary,
  FlightShopProgressHeader,
  AddOnHeader,
  MobileAddOnCustomize,
  AddOnCustomizeStepAlias,
} from "../components";
import { SortOptionSelection } from "../components/FlightShopHeader/components/SortOptionSelection";
import { FareClassOptionSelection } from "../components/FlightShopHeader/components/FareClassOptionSelection";
import { FlightList } from "./components";
import { MulticityFlightShopStep } from "../reducer";
import {
  RegionType,
  SelectedTravelOfferScreen,
  SELECTED_MULTICITY_FLIGHT,
  VIEWED_MULTICITY_FLIGHT_LIST,
  VIEWED_MULTICITY_SLICE,
  TripCategory,
  FareDetails,
  SliceStopCountFilter,
  FlightShopType,
  FiatPrice,
} from "redmond";
import {
  getShowLaunchBanner,
  isCaponeTenant,
  isCorpTenant,
} from "@capone/common";
import {
  IMulticityFlightShopParsedQuery,
  parseQueryString,
  pushToPathWithExistingQueryParams,
  updateMulticityFlightShopStep,
} from "../utils/parseQueryString";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { FlightShopSearchFilter } from "../../search/components/FlightShopSearchControlV2/components";
import { initialFilterOptions } from "../../search/reducer";
import {
  AVAILABLE,
  getExperimentVariant,
  useExperiments,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT,
  AIR_MULTICITY_EXPERIMENT,
  SEATS_UX_OPTIMIZATION,
  AIR_CX_V3_1,
  AIR_CX_V3_1_VARIANTS,
  CONTROL,
  getExperimentVariantCustomVariants,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
} from "../../../context/experiments";
import "./styles.scss";
import { TravelWalletDetailsBanner } from "../../travel-wallet/components/TravelWalletDetailsBanner";
import { TravelWalletDrawer } from "../../travel-wallet/components";
import { isEmpty } from "lodash-es";
import { getSliceFareDetails } from "../utils/helpers";
import { getQueryStringValuesFromMcRoutes } from "../../search/reducer/utils/multicityParams";
import {
  FARE_DETAILS_SUBTITLE,
  FARE_DETAILS_TITLE,
} from "../components/FlightShopReviewItinerary/constants";
import { FlightCustomize } from "../v2/components/FlightCustomize";
import { ClientContext } from "../../../App";
import { trackEngagementEvent } from "../../../api/v0/engagement-data/trackEngagementEvent";
import { config } from "../../../api/config";
import { useExperimentIsVariant } from "@capone/experiments";

export interface IMulticityFlightShopProps
  extends FlightShopConnectorProps,
    RouteComponentProps {}

export const MulticityFlightShop = (props: IMulticityFlightShopProps) => {
  const {
    setTripCategory,
    fetchTripSummaries,
    stopFetchTripSummaries,
    tripSummariesLoading,
    flightList,
    invertedStopsFilterFlightList,
    multicityFlights,
    fetchTripDetails,
    rewardsKey,
    fareClassFilter,
    multicityFlightShopProgress,
    setMulticityFlightShopProgress,
    origin,
    tripDetailsById,
    setChosenDepartureSlice,
    selectedMulticityTrips,
    hasFlightsError,
    flightsErrorCode,
    viewedMulticityFlightlistProperties,
    selectedMulticitySliceProperties,
    hasAppliedFareClassFilter,
    hasAppliedNonFareclassFilter,
    setOpenFlightShopCalendarDesktop,
    setAirlineFilter,
    setStopsOption,
    setFlightNumberFilter,
    setOutboundArrivalTimeRange,
    setOutboundDepartureTimeRange,
    multicityRoutes,
    setMaxPriceFilter,
    setFareclassOptionFilter,
    setAirportFilter,
    populateFlightBookQueryParams,
    flightShopType,
    setOpenFlightShopCalendarMobile,
    setSelectedFlightIndex,
    maxFlightPriceFilter,
    populateFlightShopQueryParams,
    bestOfferOverall,
    offersByTripId,
    travelOfferProperties,
    isCustomizePageMarketplaceEnabled,
    fetchTravelWalletDetails,
    fetchRewardsAccounts,
    setFlightShopType,
    credit,
    largestValueAccount,
    sortOption,
    setSortOption,
    hasSetMaxPriceFilter,
    isCfarEnabled,
    airports,
    stopsOption,
    setSelectedMarketingAirlineCodes,
    setSelectedOperatingAirlineCodes,
    isSpiritOrFrontierAirlinesSelected,
    minFlightPrice,
    fetchPotentialCrossSellOffers,
    potentialCrossSellOffers,
    isInPolicyFilter,
    corporateTravelProperties,
    setPolicyLimit,
  } = props;
  const { searchImage, sessionInfo, isAutoApprovalEnabled } =
    useContext(ClientContext);

  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();

  const [currentCustomizeStep, setCurrentCustomizeStep] =
    useState<AddOnCustomizeStepAlias>(AddOnCustomizeStepAlias.AddOn1);
  const [isEditMulticitySearchModalOpen, setIsEditMulticitySearchModalOpen] =
    useState(false);
  const [allFiltersModalOpen, setAllFiltersModalOpen] =
    React.useState<boolean>(false);

  const history = useHistory();

  // TODO: is this needed for anything other than mobile price prediction?
  // const [filtersOpen, setFiltersOpen] = useState(false);

  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;

  const isInChooseDeparture0Step =
    multicityFlightShopProgress === MulticityFlightShopStep.ChooseDeparture0;
  const isInChooseDeparture1Step =
    multicityFlightShopProgress === MulticityFlightShopStep.ChooseDeparture1;
  const isInChooseDeparture2Step =
    multicityFlightShopProgress === MulticityFlightShopStep.ChooseDeparture2;
  const isInChooseDeparture3Step =
    multicityFlightShopProgress === MulticityFlightShopStep.ChooseDeparture3;
  const isInChooseDeparture4Step =
    multicityFlightShopProgress === MulticityFlightShopStep.ChooseDeparture4;
  const isInReviewStep =
    multicityFlightShopProgress === MulticityFlightShopStep.ReviewItinerary;
  const isInFareDetailsStep =
    multicityFlightShopProgress === MulticityFlightShopStep.FareDetails;
  const isInCustomizeStep =
    multicityFlightShopProgress === MulticityFlightShopStep.Customize;

  const isInChooseDeparturesSteps =
    isInChooseDeparture0Step ||
    isInChooseDeparture1Step ||
    isInChooseDeparture2Step ||
    isInChooseDeparture3Step ||
    isInChooseDeparture4Step;

  const isSelectingLastSlice = useMemo(() => {
    return (
      multicityFlightShopProgress ===
      MulticityFlightShopStep[`ChooseDeparture${multicityRoutes.length - 1}`]
    );
  }, [multicityRoutes, multicityFlightShopProgress]);

  const [currentUrl, setCurrentUrl] = useState(history.location.search);

  const [openMobileFlightDetailsModal, setOpenMobileFlightDetailsModal] =
    useState(false);

  // a state used for delaying the firing of certain events
  const [trackingEventControl, setTrackingEventControl] = useState<{
    [key in string]?:
      | { properties: any; encryptedProperties?: string[] | undefined }
      | undefined;
  }>({});

  const [fareTrips, setFareTrips] = useState([]);

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const resetAllFilters = () => {
    setAirlineFilter(initialFilterOptions.airlineFilter);
    setStopsOption(initialFilterOptions.stopsOption);
    setFlightNumberFilter(initialFilterOptions.flightNumberFilter);
    setOutboundArrivalTimeRange(initialFilterOptions.outboundArrivalTimeRange);
    setOutboundDepartureTimeRange(
      initialFilterOptions.outboundDepartureTimeRange
    );
    setMaxPriceFilter(initialFilterOptions.maxPriceFilter);
    setFareclassOptionFilter(initialFilterOptions.fareclassOptionFilter);
    setAirportFilter(initialFilterOptions.airportFilter);
  };

  const expState = useExperiments();

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const isFlightListOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const isMultiCityEnabledExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, AIR_MULTICITY_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const isMultiCityEnabled =
    isMultiCityEnabledExperiment &&
    (isCaponeTenant(config.TENANT) ||
      isAutoApprovalEnabled ||
      isApprovalsV2Enabled);

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );

  const airCXV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        AIR_CX_V3_1,
        AIR_CX_V3_1_VARIANTS
      ),
    [expState]
  );

  const isAirCXV3Experiment = airCXV3Variant !== CONTROL;

  const isGlobalMobileNavExperiment =
    getExperimentVariant(expState.experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  const hotelCrossSellV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        HOTEL_CROSS_SELL_V3_EXPERIMENT,
        HOTEL_CROSS_SELL_V3_VARIANTS
      ),
    [expState.experiments]
  );

  const isHotelCrossSellV3Experiment = hotelCrossSellV3Variant !== CONTROL;

  // if experiments length is 0 then it's in the default state
  // once experiments have been loaded the length will be greater than 0
  // this is a workaround since we do not have a loading state for experiments
  useEffect(() => {
    if (!!expState.experiments.length && !isMultiCityEnabled) {
      setTripCategory(TripCategory.ROUND_TRIP);
      stopFetchTripSummaries();
      history.push(PATH_HOME);
    }
  }, [expState, isMultiCityEnabled]);

  useEffect(() => {
    return () => {
      // If user navigates away from Flight Shop, we will stop the rest of the FetchTripSummaries action.
      // Reason: If the rest of the fetchTripSummaries saga is not stopped, the state will eventually be populated.
      // Populating multicityFlights in the state can cause the next fetchTripSummaries to not run as we only call fetchTripSummaries
      // when multicityFlights in the state is set to empty or when we detect changes in the search params URL.
      // TODO - make sure the state does not get populated when navigating away
      stopFetchTripSummaries();
    };
  }, []);

  useEffect(() => {
    // fetchTripSummaries on every initial render
    fetchTripSummaries(history, matchesMobile);
  }, []);

  useEffect(() => {
    // we fetch trip summaries on new search since the currentUrl won't match (and on back button click)
    if (
      currentUrl !== history.location.search &&
      multicityFlightShopProgress === MulticityFlightShopStep.ChooseDeparture0
    ) {
      fetchTripSummaries(history, matchesMobile);
      setCurrentUrl(history.location.search);
    }
  }, [history.location.search]);

  useEffect(() => {
    if (isHotelCrossSellV3Experiment) {
      fetchPotentialCrossSellOffers();
    }
  }, [isHotelCrossSellV3Experiment, multicityRoutes]);

  //Update state depending on query param
  useEffect(() => {
    const queryString = parseQueryString(
      history
    ) as IMulticityFlightShopParsedQuery;
    if (multicityFlights && !!queryString.multicityFlightShopProgress) {
      setMulticityFlightShopProgress(queryString.multicityFlightShopProgress);
    }
  }, [multicityFlights, history.location.search]);

  useEffect(() => {
    if (!isEmpty(multicityFlights) && isInChooseDeparturesSteps) {
      trackEvent({
        eventName: VIEWED_MULTICITY_FLIGHT_LIST,
        properties: {
          ...viewedMulticityFlightlistProperties,
          ...travelOfferProperties?.properties,
        },
        encryptedProperties: [
          ...(travelOfferProperties?.encryptedProperties || []),
        ],
      });
    }
  }, [multicityFlights, multicityFlightShopProgress]);

  //Update query string if multicityFlightShopProgress does not match state
  useEffect(() => {
    const queryString = parseQueryString(
      history
    ) as IMulticityFlightShopParsedQuery;
    if (
      queryString.multicityFlightShopProgress !== multicityFlightShopProgress
    ) {
      pushToPathWithExistingQueryParams(
        history,
        PATH_SHOP,
        {
          multicityFlightShopProgress,
        },
        false
      );
    }
    if (
      matchesMobile &&
      (multicityFlightShopProgress ===
        MulticityFlightShopStep.ReviewItinerary ||
        multicityFlightShopProgress === MulticityFlightShopStep.FareDetails)
    ) {
      window.scrollTo(0, 0);
    }
  }, [multicityFlightShopProgress]);

  // note: this useEffect helps to sync up multicityFlightShopProgress in desktop & mobile flows
  useEffect(() => {
    if (
      matchesDesktop &&
      multicityFlightShopProgress === MulticityFlightShopStep.FareDetails
    ) {
      setMulticityFlightShopProgress(MulticityFlightShopStep.ReviewItinerary);
    }
  }, [
    multicityFlightShopProgress,
    matchesDesktop,
    isCustomizePageMarketplaceEnabled,
  ]);

  /*
    TODO: the flight search param is supposed to be ALWAYS populated in the query (while staying in the flight shop funnel) by design; however,
    given the current complexity of fetchTripSummariesV2Saga, it's difficult to reason with why sometimes the query isn't updated, and how we can
    force it to populate the query while keeping everything else unaffected. Here's a workaround (to keep the query updated) until it's reworked.
  */
  useEffect(() => {
    //  TODO: make this work with N params
    const { origin0, origin1, destination0, destination1 } =
      getQueryStringValuesFromMcRoutes(multicityRoutes);

    const {
      origin0: origin0FromQuery,
      origin1: origin1FromQuery,
      destination0: destination0FromQuery,
      destination1: destination1FromQuery,
    } = parseQueryString(history) as IMulticityFlightShopParsedQuery;

    //  // note: when `origin` and `destination` are not populated in the query, try populateFlightShopQueryParams so the page can be refreshed
    if (
      !!origin0 &&
      !origin0FromQuery &&
      !!origin1 &&
      !origin1FromQuery &&
      !!destination0 &&
      !destination0FromQuery &&
      !!destination1 &&
      !destination1FromQuery
    ) {
      populateFlightShopQueryParams({
        history,
        forceQueryUpdate: true,
        newQueryParams: {
          multicityFlightShopProgress: MulticityFlightShopStep.ChooseDeparture0,
        },
      });
    }
  }, [multicityRoutes, history, flightShopType]);

  useEffect(() => {
    const { flightShopType: flightShopTypeFromQuery } =
      parseQueryString(history);

    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });

    fetchTravelWalletDetails();
    fetchRewardsAccounts();
    if (
      flightShopTypeFromQuery !== undefined &&
      flightShopType !== flightShopTypeFromQuery
    ) {
      setFlightShopType(flightShopTypeFromQuery);
    }
  }, []);

  const handleFareSelect = (
    flight: any,
    fareId: string,
    idx: number,
    limit?: FiatPrice | null
  ) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const selectedFareIndex = flight.fares.findIndex(
      (fare: any) => fare.example.fare === fareId
    );
    if (!isEmpty(multicityFlights)) {
      const tripId = flight.fares[selectedFareIndex].example.trip;

      const tripDetails = tripDetailsById[tripId];
      const fareDetails: FareDetails | undefined =
        tripDetails?.fareDetails.find((fare) => fare.id == fareId);
      setChosenDepartureSlice({
        departureFareId: fareId,
        departureSliceId: flight.slice,
        departureFareRating:
          fareDetails?.slices[multicityFlightShopProgress].fareShelf?.rating,
        departureSliceIndex: multicityFlightShopProgress,
        tripId,
      });

      if (isSelectingLastSlice) {
        setMulticityFlightShopProgress(MulticityFlightShopStep.ReviewItinerary);
        updateMulticityFlightShopStep(
          MulticityFlightShopStep.ReviewItinerary,
          false,
          history
        );
      } else {
        const nextShopStepNumber = multicityFlightShopProgress + 1;
        setMulticityFlightShopProgress(
          MulticityFlightShopStep[`ChooseDeparture${nextShopStepNumber}`]
        );
        updateMulticityFlightShopStep(
          MulticityFlightShopStep[`ChooseDeparture${nextShopStepNumber}`],
          false,
          history
        );
      }
      if (airCXV3Variant === CONTROL) {
        resetAllFilters();
      }
      setSelectedFlightIndex(idx + 1);
      setTrackingEventControl((control) => {
        return {
          ...control,
          [SELECTED_MULTICITY_FLIGHT]: {
            properties: {
              ...selectedMulticitySliceProperties,
              ...travelOfferProperties?.properties,
              flight_list_ranking: idx,
            },
            encryptedProperties: [
              ...(travelOfferProperties?.encryptedProperties || []),
            ],
          },
        };
      });
    } else {
      setOpenMobileFlightDetailsModal(false);
    }

    setPolicyLimit(limit);
  };

  const handleFlightSelect = (fareTrips: any, clickedFareClass: string) => {
    const uniqueTripIds: string[] = Array.from(
      new Set(fareTrips.map((fareTrip: any) => fareTrip.trip))
    );
    uniqueTripIds.map((fareTrip: string) => {
      fetchTripDetails({ tripId: fareTrip });
    });

    setFareTrips(fareTrips);

    setTrackingEventControl((control) => {
      return {
        ...control,
        [VIEWED_MULTICITY_SLICE]: {
          properties: {
            ...viewedMulticityFlightlistProperties,
            fare_class: clickedFareClass || "",
          },
        },
      };
    });
  };

  const expandedFareDetails = React.useMemo(() => {
    const fetchedAllFareDetails =
      !!fareTrips.length &&
      fareTrips.every((fareTrip: any) => !!tripDetailsById[fareTrip.trip]);

    return fetchedAllFareDetails
      ? getSliceFareDetails({
          tripDetailsById,
          fareTrips,
        })
      : null;
  }, [fareTrips, tripDetailsById, multicityFlightShopProgress]);

  // delayed firing of VIEWED_MULTICITY_SLICE
  useEffect(() => {
    if (!!trackingEventControl[VIEWED_MULTICITY_SLICE]) {
      trackEvent({
        eventName: VIEWED_MULTICITY_SLICE,
        properties: {
          ...trackingEventControl[VIEWED_MULTICITY_SLICE]?.properties,
        },
      });
      setTrackingEventControl((control) => {
        return {
          ...control,
          [VIEWED_MULTICITY_SLICE]: undefined,
        };
      });
    }
  }, [trackingEventControl[VIEWED_MULTICITY_SLICE]]);

  // delayed firing of SELECTED_MULTICITY_FLIGHT
  useEffect(() => {
    if (!!trackingEventControl[SELECTED_MULTICITY_FLIGHT]) {
      trackEvent({
        eventName: SELECTED_MULTICITY_FLIGHT,
        properties: {
          ...trackingEventControl[SELECTED_MULTICITY_FLIGHT]?.properties,
          ...(isCorpTenant(config.TENANT) && corporateTravelProperties),
        },
      });
      setTrackingEventControl((control) => {
        return {
          ...control,
          [SELECTED_MULTICITY_FLIGHT]: undefined,
        };
      });
    }
  }, [trackingEventControl[SELECTED_MULTICITY_FLIGHT]]);

  const renderDesktopFlightShopHeader = () => {
    const getTitleCopy = () => {
      if (isInReviewStep) {
        return REVIEW_ITINERARY_TITLE_TEXT;
      } else if (
        !isEmpty(multicityRoutes) &&
        (multicityFlightShopProgress ===
          MulticityFlightShopStep.ChooseDeparture0 ||
          multicityFlightShopProgress ===
            MulticityFlightShopStep.ChooseDeparture1 ||
          multicityFlightShopProgress ===
            MulticityFlightShopStep.ChooseDeparture2 ||
          multicityFlightShopProgress ===
            MulticityFlightShopStep.ChooseDeparture3 ||
          multicityFlightShopProgress ===
            MulticityFlightShopStep.ChooseDeparture4)
      ) {
        return CHOOSING_MULTICITY_FLIGHT_TEXT(
          multicityFlightShopProgress,
          multicityRoutes
        );
      } else {
        return "";
      }
    };
    const getSubtitleCopy = () => {
      if (isInReviewStep) {
        return REVIEW_ITINERARY_SUBTITLE_TEXT;
      } else {
        return FLIGHT_PRICES_TEXT(TripCategory.MULTI_CITY);
      }
    };

    return (
      <DesktopRewardsHeader
        title={getTitleCopy()}
        subtitle={getSubtitleCopy()}
      />
    );
  };

  const flightListProps = useMemo(() => {
    return {
      tripSummariesLoading,
      flightsToRender: flightList,
      multicityFlights,
      rewardsKey,
      handleFareSelect,
      fareClassFilter,
      handleFlightSelect,
      expandedFareDetails,
      hasFlightsError,
      flightsErrorCode,
      selectedTrip: selectedMulticityTrips[multicityFlightShopProgress],
      multicityFlightShopProgress,
      maxFlightPriceFilter,
      hasAppliedFareClassFilter,
      hasAppliedNonFareclassFilter,
      setOpenFlightShopCalendarDesktop,
      setOpenFlightShopCalendarMobile,
      resetAllFilters,
      openMobileFlightDetailsModal,
      setOpenMobileFlightDetailsModal,
      history,
      populateFlightBookQueryParams,
      flightShopType,
      offersByTripId,
      credit,
      largestValueAccount,
      sortOption,
      setSortOption,
      hasSetMaxPriceFilter,
      setIsEditMulticitySearchModalOpen,
      airCXV3Variant,
      stopsOption,
      invertedStopsFilterFlightList,
      setSelectedMarketingAirlineCodes,
      setSelectedOperatingAirlineCodes,
      isSpiritOrFrontierAirlinesSelected,
      potentialCrossSellOffers,
      isInPolicyFilter,
    };
  }, [flightList, handleFareSelect]);

  const [showOfferBanner, setShowOfferBanner] = useState(false);

  useEffect(() => {
    setShowOfferBanner(
      (!isInReviewStep &&
        isTravelWalletOfferExperiment &&
        bestOfferOverall &&
        (credit &&
        Math.abs(credit.amount.amount) ===
          Math.abs(bestOfferOverall.amount.amount)
          ? true
          : !!bestOfferOverall.shopPageBanner)) ||
        false
    );
  }, [credit, bestOfferOverall]);

  useEffect(() => {
    if (!tripSummariesLoading && flightShopType === FlightShopType.DEFAULT) {
      const { origin, destination, departureDate } = multicityRoutes[0];

      const selectedFareClasses = Object.keys(fareClassFilter).filter(
        (fareClassKey) => !!fareClassFilter[fareClassKey]
      );

      trackEngagementEvent({
        event: {
          event_type: "flight_search",
          origin_location: origin?.label,
          destination_location: destination?.label,
          trip_type: TripCategory.MULTI_CITY,
          nonstop_only: stopsOption === SliceStopCountFilter.NONE,
          departure_date_timestamp: departureDate?.getTime(),
          lowest_price: minFlightPrice,
          flight_class: selectedFareClasses.length
            ? selectedFareClasses
            : undefined,
        },
      });
    }
  }, [tripSummariesLoading]);

  const showCorpLaunchBanner = getShowLaunchBanner(sessionInfo);

  const renderDesktopFlightShop = () => {
    return (
      <>
        {renderDesktopFlightShopHeader()}
        {showOfferBanner && (
          <TravelWalletDetailsBanner
            offer={bestOfferOverall!}
            showButton
            variant="full-width"
            screen={SelectedTravelOfferScreen.FLIGHT_SHOP}
            onDismiss={() => setShowOfferBanner(false)}
          />
        )}
        <Box
          className={clsx("flight-shop-result-container", {
            "has-offer": showOfferBanner,
            "use-grey-background":
              multicityFlightShopProgress ===
                MulticityFlightShopStep.ReviewItinerary && matchesDesktop,
            "not-live": showCorpLaunchBanner,
          })}
        >
          {!isInReviewStep && !isInCustomizeStep && (
            <FlightShopSearchControl
              isFlightListOptimizationExperiment={
                isFlightListOptimizationExperiment
              }
              isMultiCityEnabled
              isEditMulticitySearchModalOpen={isEditMulticitySearchModalOpen}
              setIsEditMulticitySearchModalOpen={
                setIsEditMulticitySearchModalOpen
              }
              airCXV3Variant={airCXV3Variant}
              allFiltersModalOpen={allFiltersModalOpen}
              setAllFiltersModalOpen={setAllFiltersModalOpen}
            />
          )}
          {isInReviewStep && (
            <Box className="flight-shop-price-freeze-header">
              <Box className="flight-shop-price-freeze-header-details">
                <Box className="flight-shop-price-freeze-header-title">
                  <Typography className="title-text">
                    {FARE_DETAILS_TITLE}
                  </Typography>
                </Box>
                <Box className="flight-shop-price-freeze-header-subtitle">
                  <Typography className="subtitle-text">
                    {FARE_DETAILS_SUBTITLE}
                  </Typography>
                </Box>
              </Box>
              <Box className="flight-shop-price-freeze-header-image"></Box>
            </Box>
          )}
          {isInCustomizeStep && <AddOnHeader />}
          {!isEmpty(multicityFlights) && (
            <FlightShopHeader
              isMobile={false}
              airports={airports}
              isMediumDesktop={matchesMediumDesktopOnly}
              useCustomizeStep={isCustomizePageMarketplaceEnabled}
              history={history}
              isFlightListOptimizationExperiment={
                isFlightListOptimizationExperiment
              }
              isAirCXV3Experiment={isAirCXV3Experiment}
            />
          )}
          {!tripSummariesLoading && !!isInChooseDeparturesSteps && (
            <FlightList {...flightListProps} />
          )}
          {isInReviewStep && (
            <FlightShopReviewItinerary
              isMobile={false}
              useLockPriceLanguage={false}
              useDetailsPrefix={false}
            />
          )}
          {isInCustomizeStep && (
            <FlightCustomize isDisruptionProtectionEnabled={false} />
          )}
        </Box>
      </>
    );
  };

  const renderMobileFlightShop = () => {
    return (
      <>
        <Box
          className={clsx("progress-header-menu-wrapper", {
            scrolled: scrollTrigger,
          })}
        >
          <FlightShopProgressHeader
            // TODO: filters onClick
            // onFiltersClick={() => setFiltersOpen(true)}
            onGoBackCustomize={() =>
              setCurrentCustomizeStep((step) => Math.max(step - 1, 0))
            }
            onFiltersClick={() => {}}
            className="mobile-shop-header"
            isFlightListOptimizationExperiment={
              isFlightListOptimizationExperiment
            }
            isMultiCityEnabled={isMultiCityEnabled}
            isEditMulticitySearchModalOpen={isEditMulticitySearchModalOpen}
            setIsEditMulticitySearchModalOpen={
              setIsEditMulticitySearchModalOpen
            }
            allFiltersModalOpen={allFiltersModalOpen}
            setAllFiltersModalOpen={setAllFiltersModalOpen}
          />
        </Box>
        {isGlobalMobileNavExperiment &&
          !(isInReviewStep || isInFareDetailsStep || isInCustomizeStep) &&
          flightList.length > 0 && (
            <Box className="mobile-flight-shop-rewards-account-contents">
              <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
            </Box>
          )}
        {isInChooseDeparturesSteps && (
          <>
            {flightList.length > 0 && !isFlightListOptimizationExperiment && (
              <Box className="mobile-flight-shop-search-filters-and-sort-section">
                <FlightShopSearchFilter
                  hideAirportFilter={
                    origin?.id.code.regionType === RegionType.Airport
                  }
                />
                <SortOptionSelection
                  sortOption={sortOption}
                  setSortOption={setSortOption}
                />
              </Box>
            )}
            {showOfferBanner && (
              <TravelWalletDetailsBanner
                offer={bestOfferOverall!}
                variant="default"
                screen={SelectedTravelOfferScreen.FLIGHT_SHOP}
                onDismiss={() => setShowOfferBanner(false)}
              />
            )}
            {(flightList.length > 0 || hasAppliedFareClassFilter) && (
              <FlightShopHeader
                isMobile={true}
                airports={airports}
                history={history}
              />
            )}

            {(flightList.length > 0 || hasAppliedFareClassFilter) && (
              <FareClassOptionSelection />
            )}
            <FlightList {...flightListProps} />
          </>
        )}
        {isInReviewStep && (
          <>
            <FlightShopHeader
              isMobile={true}
              airports={airports}
              useLockPriceLanguage={false}
              history={history}
            />
            <FlightShopReviewItinerary isMobile />
          </>
        )}
        {isInFareDetailsStep && (
          <MobileFareDetails
            hasActiveRefundableFare={false}
            isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
          />
        )}
        {isCfarEnabled && isInCustomizeStep && (
          <MobileAddOnCustomize
            goBack={() =>
              setMulticityFlightShopProgress(
                MulticityFlightShopStep.FareDetails
              )
            }
            currentStep={currentCustomizeStep}
            setCurrentStep={setCurrentCustomizeStep}
            isCfarEnabled={isCfarEnabled}
            isChfarEnabled={false}
            isDisruptionProtectionEnabled={false}
          />
        )}
        {isTravelWalletCreditsExperiment && (
          <TravelWalletDrawer className={clsx({ hidden: isInReviewStep })} />
        )}
      </>
    );
  };

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.flightsMultiplier;

  return (
    <>
      <Box
        className={clsx(
          "multicity",
          "flight-shop-root",
          {
            mobile: matchesMobile,
            "global-mobile-nav": isGlobalMobileNavExperiment,
          },
          "v2"
        )}
      >
        <Box className="flight-shop-container">
          {matchesDesktop && renderDesktopFlightShop()}
          {matchesMobile && renderMobileFlightShop()}
          {tripSummariesLoading && (
            <B2BLoadingPopup
              open={tripSummariesLoading}
              message={SEARCHING_FOR_FLIGHTS}
              secondaryMessage={
                showEarnEnhancement
                  ? EARN_ENHANCEMENT_SUBTITLE(
                      largestValueAccount.earn.flightsMultiplier,
                      largestValueAccount.productDisplayName
                    )
                  : largestValueAccount?.productDisplayName
                      .toLowerCase()
                      .includes("paradise")
                  ? PARADISE_SUBTITLE
                  : SEARCHING_FOR_FLIGHTS_SECONDARY_MESSAGE
              }
              image={searchImage ?? ""}
              className="flight-search-loading-popup"
              popupSize={matchesMobile ? "mobile" : "desktop"}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
