import { PostBookingConfirmationConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import { AncillaryPostBookingConfirmation as AncillaryPostBookingConfirmationInterface } from "redmond";
import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import * as textConstants from "./constants";
import { PATH_FLIGHTS } from "../../constants";
import { PATH_TRIPS } from "../../../../utils/urlPaths";
import { ClientContext } from "../../../../App";
import clsx from "clsx";

export interface IPostBookingConfirmationConnectorPropsProps
  extends PostBookingConfirmationConnectorProps,
    RouteComponentProps {
  offerPurchased: AncillaryPostBookingConfirmationInterface;
}

export const AncillaryPostBookingConfirmation = (
  props: IPostBookingConfirmationConnectorPropsProps
) => {
  const { offerPurchased, history } = props;
  const clientContext = useContext(ClientContext);
  const { sessionInfo } = clientContext;
  const { matchesMobile } = useDeviceTypes();

  function subtitleEarn() {
    if (!!offerPurchased.response.rewardsPrice) {
      return (
        textConstants.SUBTITLE_EARN(offerPurchased.response.rewardsPrice) + "  "
      );
    } else {
      return "";
    }
  }

  function subtitleEmail() {
    return textConstants.SUBTITLE_EMAIL(
      offerPurchased.response.emailAddress ?? sessionInfo?.userInfo?.email ?? ""
    );
  }

  return (
    <Box
      className={clsx("post-booking-confirmation-root", {
        "pbc-mobile-root": matchesMobile,
      })}
    >
      <Icon className="checkmark" name={IconName.CheckCircleBorder} />
      <Typography variant="h1" className="title">
        {textConstants.TITLE}
      </Typography>
      <Typography
        variant="h5"
        className="subtitle"
        dangerouslySetInnerHTML={{
          __html: subtitleEarn() + subtitleEmail(),
        }}
      />

      {matchesMobile ? (
        <ActionButton
          defaultStyle="h4r-primary"
          className="view-details-button"
          onClick={() => {
            history.push(
              `${PATH_TRIPS}?tripId=${offerPurchased.response.itineraryId}`
            );
          }}
          message={textConstants.VIEW_TRIP_DETAILS}
        />
      ) : (
        <>
          <ActionButton
            defaultStyle="h4r-primary"
            className="search-button"
            onClick={() => {
              history.push(PATH_FLIGHTS);
            }}
            message={textConstants.START_NEW_SEARCH}
          />
          <ActionLink
            className="view-details-link"
            content={
              <Typography variant="h4" className="view-details">
                {textConstants.VIEW_TRIP_DETAILS}
              </Typography>
            }
            onClick={() => {
              history.push(
                `${PATH_TRIPS}?tripId=${offerPurchased.response.itineraryId}`
              );
            }}
          ></ActionLink>
        </>
      )}

      <div className="confirmation-offer-divider" />

      <Typography variant="h4" className="whats-next">
        {textConstants.WHATS_NEXT}
      </Typography>

      <Box className="step-item-container mt-20">
        <Box className="circle-bullet-container">
          <Typography variant="h3" className="circle-bullet">
            1
          </Typography>
        </Box>
        <Typography variant="h4" className="step-title">
          {textConstants.STEP_ONE}
        </Typography>
      </Box>

      <Box className="step-item-container mt-20">
        <Box className="circle-bullet-container">
          <Typography variant="h3" className="circle-bullet">
            2
          </Typography>
        </Box>
        <Typography variant="h4" className="step-title">
          {textConstants.STEP_TWO}
        </Typography>
      </Box>

      <Box className="step-item-container">
        <Box className="circle-bullet-base" />
        <Typography variant="h4" className="step-body">
          {textConstants.STEP_TWO_BODY}
        </Typography>
      </Box>
    </Box>
  );
};
