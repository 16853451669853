import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  PriceFreezeOfferDataWithRewards,
  CHANGED_DURATION,
  ChangedPriceFreezeDurationProperties,
} from "redmond";
import {
  BoxedRadioGroup,
  DiscreteSlider,
  GenericDetailsCardComponentEnum,
  IDiscretePoint,
  ToggleSwitch,
  useDeviceTypes,
  IBoxedRadio,
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
} from "halifax";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import {
  convertSecondsToDays,
  PriceFreezeDurationSelectionMethod,
} from "../../../utils";
import { getPriceFreezeDurationSelectionPageProperty } from "../utils";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import { PriceFreezeDurationSelectionConnectorProps } from "./container";
import {
  PRICE_FREEZE_DURATION_ARIA_LABEL,
  USE_MULTI_PAGE_THRESHOLD,
  FOR_SEPARATOR,
  OR_SEPARATOR,
  FEE_PER_TRAVELER_COPY,
  FROZEN_PRICE_DURATION_COPY,
  getDurationValueText,
  getFormattedDurationObject,
  getPricesText,
  PRICE_FREEZE_DURATION_BUTTONS_RADIO_GROUP_NAME,
  ALLOWED_BUTTON_DURATIONS,
  VOID_WINDOW_TEXT,
} from "./constants";
import "./styles.scss";
import dayjs from "dayjs";
import { PRICE_FREEZE_VOID_WINDOW_CONTEXTUAL_DISCLOSURE } from "../../../../../context/experiments";

export interface IPriceFreezeDurationSelectionProps
  extends PriceFreezeDurationSelectionConnectorProps,
  RouteComponentProps {
  selectionMethod: PriceFreezeDurationSelectionMethod;
  textSize?: "small" | "large" | undefined;
  useMultiPage?: boolean;
  hideDetailsSectionTop: boolean;
  hideDetailsSectionBottom: boolean;
  /*
    note: this boolean allows the component to skip the generateCustomPriceFreezeOffer action on changing the duration;
    there are certain workflows (e.g, mostly on mobile) where we want to deplay the custom offer request until the user
    has exited the duration selection modal, so setting generateCustomPriceFreezeOffer to true is necessary in such cases.
  */
  disableGenerateOfferOnChange?: boolean;
}

export const PriceFreezeDurationSelection = (
  props: IPriceFreezeDurationSelectionProps
) => {
  const {
    history,
    textSize,
    useMultiPage,
    hideDetailsSectionTop,
    hideDetailsSectionBottom,
    priceFreezeOfferPrices,
    priceFreezeOfferDataList,
    selectedPriceFreezeOfferTtl,
    disableGenerateOfferOnChange,
    selectedPriceFreezeOfferDataIndex,
    changedPriceFreezeDurationProperties,
    isPriceFreezeDurationPopupEnabled,
    setSelectedPriceFreezeOfferDataIndex,
    generateCustomPriceFreezeOffer,
    selectedAccountReferenceId,
    selectionMethod,
    priceFreezeVoidWindow,
    priceFreezeVoidWindowEnd,
    isPriceFreezeDurationShortActive,
    setCreditToApply,
  } = props;
  const { matchesDesktop } = useDeviceTypes();

  if (
    !priceFreezeOfferDataList ||
    !priceFreezeOfferPrices ||
    selectedPriceFreezeOfferDataIndex === null
  ) {
    return null;
  }

  const allowedOptionsForButtons = ALLOWED_BUTTON_DURATIONS.map(
    ({ timeAmount, timeUnit }) =>
      dayjs.duration(timeAmount, timeUnit).asSeconds()
  );

  // For Buttons variant, we only select certain hard-coded allowed options.
  // We filter out the rest, and we also save the original index since that
  // is used for determining the selected price freeze offer in the Redux state.
  const displayedPriceFreezeOfferDataList =
    selectionMethod === PriceFreezeDurationSelectionMethod.Buttons
      ? priceFreezeOfferDataList
        .map((offerData, originalListIndex) => {
          return { offerData, originalListIndex };
        })
        .filter(({ offerData }) =>
          allowedOptionsForButtons.find(
            (allowedOption) => offerData.secondsTtl === allowedOption
          )
        )
      : priceFreezeOfferDataList.map((offerData, originalListIndex) => {
        return { offerData, originalListIndex };
      });

  const getDisplayedListIndex = (originalListIndex: number): number => {
    return displayedPriceFreezeOfferDataList.findIndex(
      ({ originalListIndex: index }) => index === originalListIndex
    );
  };

  const selectedDisplayedListIndex = getDisplayedListIndex(
    selectedPriceFreezeOfferDataIndex
  );

  const getSelectedOriginalListIndex = (displayedListIndex: number): number => {
    return (
      displayedPriceFreezeOfferDataList[displayedListIndex]
        ?.originalListIndex ?? null
    );
  };

  const selectedPriceFreezeOfferTtlDays = convertSecondsToDays(selectedPriceFreezeOfferTtl ?? 0)

  const selectedDuration = getDurationValueText(
    convertSecondsToDays(selectedPriceFreezeOfferTtl ?? 0)
  );

  const getValueTextFromIndex = (displayedListIndex: number) => {
    const offerData =
      priceFreezeOfferDataList[
      getSelectedOriginalListIndex(displayedListIndex)
      ];

    return getDurationValueText(
      convertSecondsToDays(offerData?.secondsTtl ?? 0)
    );
  };

  const handleGeneratePriceFreezeOffer = (index: number) => {
    const selectedOfferData = priceFreezeOfferDataList[index];

    if (selectedOfferData) {
      if (!disableGenerateOfferOnChange) {
        generateCustomPriceFreezeOffer(selectedOfferData, history, false);
      }
      trackEvent({
        eventName: CHANGED_DURATION,
        properties: {
          ...changedPriceFreezeDurationProperties,
          ...(matchesDesktop && isPriceFreezeDurationPopupEnabled
            ? getPriceFreezeDurationSelectionPageProperty(history)
            : undefined),
          // note: on change committed, the event is fired prior to the state gets updated; the selectedOfferData (from this callback) represents the current offer
          selected_duration: selectedOfferData.secondsTtl,
          selected_fee_price_per_traveler_usd:
            selectedOfferData.price.fiat.value,
        } as ChangedPriceFreezeDurationProperties,
      });
    }
  };

  let showVoidWindowBanner;
  if(priceFreezeVoidWindowEnd){
    if(priceFreezeVoidWindowEnd === "Unavailable"){
      showVoidWindowBanner = false;
    } else {
      showVoidWindowBanner = dayjs().isBefore(dayjs(priceFreezeVoidWindowEnd))    
    }
  }

  return (
    <Box
      className={clsx("price-freeze-duration-selection-root", {
        "use-toggle":
          selectionMethod === PriceFreezeDurationSelectionMethod.Toggle,
        "use-buttons":
          selectionMethod === PriceFreezeDurationSelectionMethod.Buttons,
      })}
    >
      <Box className="price-freeze-duration-selection-container">
        {!hideDetailsSectionTop && (
          <Box className={clsx("details-section", "top")}>
            <Typography className="details-body" variant="body1">
              <Typography className="duration" variant="inherit">
                {selectedDuration}
              </Typography>
              <Typography className="separator" variant="inherit">
                {FOR_SEPARATOR}
              </Typography>
              <Typography
                className="prices"
                variant="inherit"
                dangerouslySetInnerHTML={{
                  __html: getPricesText({
                    fiat: priceFreezeOfferPrices.fiat,
                    reward: priceFreezeOfferPrices.reward,
                    bold: true,
                    addAsterisk: true,
                  }),
                }}
              />
            </Typography>
          </Box>
        )}
        <Box className="slider-section">
          {selectionMethod === PriceFreezeDurationSelectionMethod.Slider && (
            <>
              <DiscreteSlider
                points={getDiscreteSliderPoints({
                  offerDataList: displayedPriceFreezeOfferDataList.map(
                    ({ offerData }) => offerData
                  ),
                  useMultiPage,
                })}
                textSize={textSize}
                getAriaValueText={getValueTextFromIndex}
                ariaLabel={PRICE_FREEZE_DURATION_ARIA_LABEL}
                selectedValue={selectedDisplayedListIndex}
                setSelectedValue={(selectedIndex: number) => {
                  setSelectedPriceFreezeOfferDataIndex(
                    getSelectedOriginalListIndex(selectedIndex)
                  );
                }}
                onChangeCommitted={(selectedIndex?: number) => {
                  if (selectedIndex !== undefined) {
                    setCreditToApply(undefined);
                    handleGeneratePriceFreezeOffer(
                      getSelectedOriginalListIndex(selectedIndex)
                    );
                  }
                }}
              />
              {matchesDesktop &&
                selectedPriceFreezeOfferTtlDays < 2 &&
                isPriceFreezeDurationShortActive &&
                (selectedDisplayedListIndex === 1 ||
                  selectedDisplayedListIndex === 0) &&
                priceFreezeVoidWindow ===
                PRICE_FREEZE_VOID_WINDOW_CONTEXTUAL_DISCLOSURE &&
                showVoidWindowBanner && (
                  <NotificationBanner
                    className="void-window-banner"
                    severity={BannerSeverity.NOTICE}
                    icon={<Icon name={IconName.AlertIcon} />}
                    content={
                      <Typography
                        className="void-window-banner-label"
                        variant="caption"
                      >
                        <span className="label-copy">{VOID_WINDOW_TEXT}</span>
                      </Typography>
                    }
                  />
                )}
            </>
          )}
          {selectionMethod === PriceFreezeDurationSelectionMethod.Buttons && (
            <BoxedRadioGroup
              contentOnly={false}
              component={GenericDetailsCardComponentEnum.BoxedRadioGroup}
              className="price-freeze-duration-selection-radio-group"
              radioButtons={getRadioOptions({
                offerDataList: displayedPriceFreezeOfferDataList.map(
                  ({ offerData }) => offerData
                ),
                selectedAccountReferenceId,
              })}
              selectedValue={selectedDisplayedListIndex}
              setSelectedValue={(selectedIndex: number) => {
                setSelectedPriceFreezeOfferDataIndex(
                  getSelectedOriginalListIndex(selectedIndex)
                );
                handleGeneratePriceFreezeOffer(
                  getSelectedOriginalListIndex(selectedIndex)
                );
              }}
              radioGroupName={PRICE_FREEZE_DURATION_BUTTONS_RADIO_GROUP_NAME}
            ></BoxedRadioGroup>
          )}
          {selectionMethod === PriceFreezeDurationSelectionMethod.Toggle && (
            <ToggleSwitch
              className="price-freeze-duration-selection-toggle-switch"
              ariaLabel={PRICE_FREEZE_DURATION_ARIA_LABEL}
              values={displayedPriceFreezeOfferDataList.map(
                (_, index) => index
              )}
              selectedValue={selectedDisplayedListIndex}
              setSelectedValue={(selectedIndex: number) => {
                setSelectedPriceFreezeOfferDataIndex(
                  getSelectedOriginalListIndex(selectedIndex)
                );
                handleGeneratePriceFreezeOffer(
                  getSelectedOriginalListIndex(selectedIndex)
                );
              }}
              getValueText={getValueTextFromIndex}
              getAriaValueText={getValueTextFromIndex}
            />
          )}
          {matchesDesktop &&
          selectedPriceFreezeOfferTtlDays < 2 &&
            (selectedDisplayedListIndex === 1 ||
              selectedDisplayedListIndex === 0) &&
            priceFreezeVoidWindow ===
            PRICE_FREEZE_VOID_WINDOW_CONTEXTUAL_DISCLOSURE &&
            showVoidWindowBanner && (
              <NotificationBanner
                className="void-window-banner"
                severity={BannerSeverity.NOTICE}
                icon={<Icon name={IconName.AlertIcon} />}
                content={
                  <Typography
                    className="void-window-banner-label"
                    variant="caption"
                  >
                    <span className="label-copy">{VOID_WINDOW_TEXT}</span>
                  </Typography>
                }
              />
            )}
        </Box>
        {!hideDetailsSectionBottom && (
          <Box className={clsx("details-section", "bottom")}>
            <Box className="details-grid-view">
              <Box className={clsx("details-grid", "duration")}>
                <Typography className="subtitle-copy" variant="subtitle1">
                  {FROZEN_PRICE_DURATION_COPY}
                </Typography>
                <Typography className="body-copy" variant="body1">
                  {selectedDuration}
                </Typography>
              </Box>
              <Box className="separator" />
              <Box className={clsx("details-grid", "fee")}>
                <Typography className="subtitle-copy" variant="subtitle1">
                  {FEE_PER_TRAVELER_COPY}
                </Typography>
                <Typography
                  className="body-copy"
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: getPricesText({
                      fiat: priceFreezeOfferPrices.fiat,
                      reward: priceFreezeOfferPrices.reward,
                      separator: OR_SEPARATOR,
                      bold: true,
                    }),
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const getRadioOptions = ({
  offerDataList,
  selectedAccountReferenceId,
}: {
  offerDataList: PriceFreezeOfferDataWithRewards[];
  selectedAccountReferenceId: string | undefined;
}): IBoxedRadio[] => {
  const getRadioOption = (
    offerData: PriceFreezeOfferDataWithRewards,
    index: number
  ): IBoxedRadio => {
    const duration = getFormattedDurationObject(
      convertSecondsToDays(offerData.secondsTtl)
    );

    const title = `${duration.displayValue}-${duration.unitNameSingular} freeze`;

    const copy = getPricesText({
      fiat: offerData.price.fiat,
      reward: selectedAccountReferenceId
        ? offerData.price.rewards[selectedAccountReferenceId]
        : undefined,
      bold: true,
      addAsterisk: true,
    });

    return {
      value: index,
      title: title,
      copy: copy,
    };
  };

  return offerDataList.map((o, index) => getRadioOption(o, index));
};

const getDiscreteSliderPoints = ({
  offerDataList,
  useMultiPage,
}: {
  offerDataList: PriceFreezeOfferDataWithRewards[];
  useMultiPage?: boolean;
}): IDiscretePoint[][] => {
  // note: index needs to be passed in as value, so that points on the slider are evenly spread out
  const getDiscretePoint = (
    offerData: PriceFreezeOfferDataWithRewards,
    value: number
  ) => {
    const duration = getFormattedDurationObject(
      convertSecondsToDays(offerData.secondsTtl)
    );
    return {
      value,
      label: `${duration.displayValue}${duration.unit}`,
    };
  };

  if (useMultiPage && offerDataList.length > USE_MULTI_PAGE_THRESHOLD) {
    const firstSegmentLength = Math.floor(offerDataList.length / 2);

    return [
      offerDataList
        .slice(0, firstSegmentLength)
        .map((offerData, index) => getDiscretePoint(offerData, index)),
      offerDataList
        .slice(firstSegmentLength, offerDataList.length)
        .map((offerData, index) =>
          getDiscretePoint(offerData, firstSegmentLength + index)
        ),
    ];
  } else {
    return [
      offerDataList.map((offerData, index) =>
        getDiscretePoint(offerData, index)
      ),
    ];
  }
};
