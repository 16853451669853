import React, { useEffect, useState, useMemo, useContext } from "react";

import { Box, Typography, useScrollTrigger } from "@material-ui/core";
import {
  B2BLoadingPopup,
  BackToTopButton,
  FloatingMenuPill,
  GenericFlightSummary,
  getTimeWithUtcOffset,
  IconName,
  IFareCustomization,
  LaunchBanner,
  TravelSalesEventBanner,
  useDeviceTypes,
  usePrevious,
  UserPreferencesAppliedBanner,
  useTrackingEventControl,
} from "halifax";
import clsx from "clsx";
import { isEmpty } from "lodash";
import {
  CHOOSING_FLIGHT_TEXT,
  FLIGHT_PRICES_TEXT,
  REVIEW_ITINERARY_SUBTITLE_TEXT,
  REVIEW_ITINERARY_TITLE_TEXT,
  SEARCHING_FOR_FLIGHTS,
  SEARCHING_FOR_FLIGHTS_SECONDARY_MESSAGE,
  CUSTOMIZE_SUBTITLE_TEXT,
  CUSTOMIZE_TITLE_TEXT,
  FLIGHT_SHOP_REBOOK_TITLE_TEXT,
  FLIGHT_SHOP_REBOOK_SUBTITLE_TEXT,
  REVIEW_ITINERARY_REBOOK_TITLE_TEXT_NEW,
  REVIEW_ITINERARY_REBOOK_TITLE_TEXT_OLD,
  REVIEW_ITINERARY_REBOOK_SUBTITLE_TEXT,
  EARN_ENHANCEMENT_SUBTITLE,
  CREDIT_NOT_AVAILABLE_BANNER,
  CREDIT_AVAILABLE_WITH_FEES_BANNER,
  CREDIT_AVAILABLE_WITH_NO_FEES_BANNER,
  TRAVEL_SALES_EVENT_ACTIVE_CTA,
  TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
  PARADISE_SUBTITLE,
} from "../constants";
import { FlightShopConnectorProps } from "./container";
import {
  PATH_SHOP,
  PATH_FREEZE,
  PATH_TRAVEL_SALE,
} from "../../../utils/urlPaths";
import { RouteComponentProps } from "react-router";
import {
  DesktopRewardsHeader,
  RewardsAccountSelection,
} from "../../rewards/components";
import { FlightShopSearchControl } from "../../search/components/FlightShopSearchControlV2";
import {
  FlightShopHeader,
  MobilePricePredictionSection,
  MobileFareDetails,
  FlightShopReviewItinerary,
  FlightShopProgressHeader,
  MobileAddOnCustomize,
  AddOnCustomizeStepAlias,
  AddOnHeader,
} from "../components";
import { SortOptionSelection } from "../components/FlightShopHeader/components/SortOptionSelection";
import { FareClassOptionSelection } from "../components/FlightShopHeader/components/FareClassOptionSelection";
import { CfarDetails } from "../components/addOnComponents";
import { FlightList, FlightCustomize } from "./components";
import { FlightShopStep, ISelectedTrip } from "../reducer";
import {
  FareDetails,
  FlightShopType,
  RegionType,
  SelectedTravelOfferScreen,
  SELECTED_FLIGHT,
  VIEWED_FLIGHT_LIST,
  VIEWED_SLICE,
  Cap1DpExerciseItineraryFactsProperties,
  Cap1DpExerciseFlightsListFactsProperties,
  CAP1_VIEWED_REBOOKING_FLIGHT_LIST,
  CAP1_REBOOKING_VIEWED_SLICE,
  CallState,
  CAP1_REBOOKING_CONFIRMED_SLICE,
  Cap1DpExerciseFactsProperties,
  FetchCfarOfferSuccessV2,
  UtasPolicy,
  CfarOffer,
  REFUNDABLE_FARES_POINT_ONE,
  REFUNDABLE_FARES_POINT_THREE,
  REFUNDABLE_FARES_BANNER,
  PARTIALLY_REFUNDABLE_BANNER,
  FtcType,
  Slice,
  FiatPrice,
  AppliedPreferencesProperties,
  APPLIED_PREFERENCES,
  CLEAR_ALL_FILTERS,
  AirlineOpenEnum,
  SliceStopCountFilter,
} from "redmond";
import {
  getShowLaunchBanner,
  isCaponeTenant,
  isCorpTenant,
} from "@capone/common";
import {
  IFlightShopParsedQuery,
  parseQueryString,
  pushToPathWithExistingQueryParams,
  updateFlightShopStep,
} from "../utils/parseQueryString";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { ClientContext } from "../../../App";
import {
  getFtcTypeArr,
  getSliceFareDetails,
  secondBulletText,
} from "../utils/helpers";
import {
  getIsRefundableFare,
  getRefundableFareIdFromFareId,
  getBaseFareIdFromRefundableFareId,
  getNumberOfCfarOffers,
  getNumberOfRefundableFlights,
  getCfarChangePolicyFromCfarOfferResponse,
} from "../../ancillary/utils";
import {
  RebookBanner,
  RebookHeader,
  TodayTomorrowToggle,
  RefundableFareOptionsBanner,
} from "../../ancillary/components";
import { FlightShopSearchFilter } from "../../search/components/FlightShopSearchControlV2/components";
import { initialFilterOptions } from "../../search/reducer";
import {
  AVAILABLE,
  getExperimentVariant,
  PRICE_FREEZE,
  SIMILAR_FLIGHTS,
  useExperiments,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT,
  AIR_MULTICITY_EXPERIMENT,
  AIR_CHATBOT_EXPERIMENT,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  RECENTLY_VIEWED_V2_FLIGHTS,
  SEATS_UX_OPTIMIZATION,
  FARE_DETAILS_MOBILE,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_SALE_ACTIVE,
  AIR_CX_V3_1,
  AIR_CX_V3_1_VARIANTS,
  CONTROL,
  AIR_CX_V3_1_VARIANT_1,
  addTrackingProperties,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  AIR_CX_V4,
} from "../../../context/experiments";
import {
  FARE_DETAILS_COMBINATION_FLIGHT_SUBTITLE,
  FARE_DETAILS_COMBINATION_FLIGHT_TITLE,
  FARE_DETAILS_SUBTITLE,
  FARE_DETAILS_TITLE,
  VIRTUAL_INTERLINE_FLIGHT_SUBTITLE,
  VIRTUAL_INTERLINE_FLIGHT_TITLE,
} from "../components/FlightShopReviewItinerary/constants";
import { ContactSupportHeader } from "../../common";
import "./styles.scss";
import { TravelWalletDetailsBanner } from "../../travel-wallet/components/TravelWalletDetailsBanner";
import { TravelWalletDrawer } from "../../travel-wallet/components";
import { disruptionProtectionRefundEntryPoint } from "../components/FlightShopHeader/component";
import { DesktopCalendarPicker } from "../../search/components/FlightSearchControlV2/components";
import { sortOptionsWithoutPrice } from "../../../utils/sortAndFilter";
import { config } from "../../../api/config";
import { isFlightRecommendedBasedOnPreferences } from "./utils";
import { trackEngagementEvent } from "../../../api/v0/engagement-data/trackEngagementEvent";
import { useExperimentIsVariant } from "@capone/experiments";

export interface IFlightShopProps
  extends FlightShopConnectorProps,
    RouteComponentProps {}

export const FlightShopV2 = (props: IFlightShopProps) => {
  const {
    history,
    fetchTripSummaries,
    stopFetchTripSummaries,
    tripSummariesLoading,
    flights,
    fetchTripDetails,
    rewardsKey,
    fareClassFilter,
    flightShopProgress,
    origin,
    destination,
    isRoundTrip,
    setFlightShopProgress,
    tripDetailsById,
    departureDate,
    returnDate,
    setChosenReturnSlice,
    setChosenOutgoingSlice,
    hasFlightsError,
    flightsErrorCode,
    flightList,
    invertedStopsFilterFlightList,
    viewedForecastProperties,
    selectedTrip,
    hasAppliedFareClassFilter,
    hasAppliedNonFareclassFilter,
    setOpenFlightShopCalendarDesktop,
    selectedReturnSliceProperties,
    selectedOutgoingSliceProperties,
    setAirlineFilter,
    setPolicyFilter,
    setStopsOption,
    setFlightNumberFilter,
    setOutboundArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    setMaxPriceFilter,
    setFareclassOptionFilter,
    setAirportFilter,
    populateFlightBookQueryParams,
    flightShopType,
    fetchTripSummariesForPrediction,
    refreshPrediction,
    isMultiTicket,
    setOpenFlightShopCalendarMobile,
    rerunPrediction,
    setSelectedFlightIndex,
    maxFlightPriceFilter,
    populateFlightShopQueryParams,
    priceFreezeOfferCheapestTripAirports,
    priceFreezeOfferCheapestTripTripDetails,
    cheapestFrozenPrice,
    priceFreezeOffer,
    priceFreezeOfferCheapestTripFareId,
    priceFreezeRewards,
    priceFreezeDuration,
    priceFreezeFiat,
    priceFreezeCap,
    priceDropProtectionCandidateId,
    hasPriceFreezeOnOutbound,
    setHasPriceFreezeOnOutbound,
    resetFetchAncillaryOfferState,
    cfarOffers,
    batchCfarOffersCallState,
    resetCfarOffers,
    batchFetchCfarOffers,
    offersByTripId,
    travelOfferProperties,
    isPriceFreezeDurationEnabled,
    isCfarEnabled,
    isChfarEnabled,
    isDisruptionProtectionEnabled,
    isCustomizePageMarketplaceEnabled,
    isInDisruptionProtectionRebook,
    isPartiallyRebooking,
    originalSliceFlightSummaryProps,
    isPartiallyRebookingNotificationProps,
    viewedRebookingFlightListProperties,
    rebookSummaryCallState,
    fetchSingleFlightItineraryCallState,
    isSelectingReturnFlightForRebook,
    isRefundableFaresActive,
    setSelectedCfarId,
    hasActiveRefundableFare,
    hasSelectedRefundableFare,
    hasUpdatedCfarOffersForReturnFlights,
    setHasSelectedRefundableFare,
    showNoFtcOnlyInRefundableFares,
    refundableFaresProperties,
    updateRefundableFaresProperties,
    initializeOfferDataAndCustomOffer,
    fetchTravelWalletDetails,
    fetchRewardsAccounts,
    setOriginalFlightItineraryId,
    fetchRebookSummary,
    fetchSingleFlightItinerary,
    fetchFlightDisruptionsByItineraryId,
    setFlightShopType,
    setDisruptedFlightItinerarySliceAndSegmentIds,
    disruptedFlightDpExerciseFactsProperties,
    rebookingViewedSliceProperties,
    rebookingConfirmedSliceProperties,
    credit,
    rebookDepartureDateTime,
    largestValueAccount,
    sortOption,
    setSortOption,
    hasSetMaxPriceFilter,
    setDepartureDate,
    setReturnDate,
    openCalendarDesktop,
    tripCategory,
    setTripCategory,
    fetchTravelWalletCreditHistory,
    flightShopEntryPoint,
    isRapidRebookRenameEnabled,
    cfarDiscountProperties,
    slicesMap,
    airEntryProperties,
    isThebesHackerFaresV2Cap1ExperimentAvailable,
    airports,
    stopsOption,
    setDurationFilter,
    setPolicyLimit,
    isInPolicyFilter,
    isAirRefundableFareCopyEnabled,
    userFlightPreferencesCallState,
    fetchUserFlightPreferences,
    userFlightPreferences,
    userHasSetFlightPreferences,
    userFlightPreferencesNotAvailable,
    setUserPreferencesNotAvailable,
    shouldApplyUserFlightPreferences,
    resetAll,
    setApplyUserFlightPreferences,
    setFlightShopRecommendedBasedOnPreferences,
    flightShopRecommendedBasedOnPreferences,
    activeFiltersCount,
    setSelectedMarketingAirlineCodes,
    setSelectedOperatingAirlineCodes,
    isSpiritOrFrontierAirlinesSelected,
    minFlightPrice,
    fetchPotentialCrossSellOffers,
    potentialCrossSellOffers,
    listPaymentMethods,
  } = props;
  const clientContext = useContext(ClientContext);
  const { isAgentPortal, searchImage, isAutoApprovalEnabled, sessionInfo } =
    clientContext;
  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();

  const [allFiltersModalOpen, setAllFiltersModalOpen] = useState(false);
  
  const matchesMediumDesktopOnly =
    matchesDesktop && (!matchesLargeDesktop || isInDisruptionProtectionRebook);

  const [isInChooseDepartureStep, setIsChooseDepartureStep] = useState(
    flightShopProgress === FlightShopStep.ChooseDeparture
  );
  const [isInChooseReturnStep, setIsChooseReturnStep] = useState(
    flightShopProgress === FlightShopStep.ChooseReturn
  );

  const [isInReviewStep, setIsInReviewStep] = useState(
    flightShopProgress === FlightShopStep.ReviewItinerary
  );

  const [isInPredictionStep, setIsInPredictionStep] = useState(
    flightShopProgress === FlightShopStep.PricePrediction
  );

  const [isInFareDetailsStep, setIsInFareDetailsStep] = useState(
    flightShopProgress === FlightShopStep.FareDetails
  );

  const [isInCustomizeStep, setIsInCustomizeStep] = useState(
    flightShopProgress === FlightShopStep.Customize
  );
  const [currentUrl, setCurrentUrl] = useState(history.location.search);

  const [currentCustomizeStep, setCurrentCustomizeStep] =
    useState<AddOnCustomizeStepAlias>(AddOnCustomizeStepAlias.AddOn1);

  const [openMobileFlightDetailsModal, setOpenMobileFlightDetailsModal] =
    useState(false);

  const [focusedRefundableFareId, setFocusedRefundableFareId] =
    useState<string>();
  const [onClickRefundableFareBanner, setOnClickRefundableFareBanner] =
    useState<() => void>();

  const [isEditMulticitySearchModalOpen, setIsEditMulticitySearchModalOpen] =
    useState(false);

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const [airlineFtcAmount, setAirlineFtcAmount] = useState<number>();
  const fareIDAmountMap: { [key: string]: number } = {};

  const {
    selectedRefundableFareOffer,
    selectedRefundableFareChangePolicy,
    selectedRefundAmount,
  } = useMemo(() => {
    let selectedRefundableFareOffer: CfarOffer | undefined = undefined;
    let selectedRefundableFareChangePolicy: UtasPolicy[] | undefined =
      undefined;
    let selectedRefundAmount: number | undefined = undefined;
    if (focusedRefundableFareId) {
      Object.values(cfarOffers).forEach((offersByFareId) => {
        const successResponse = offersByFareId[
          focusedRefundableFareId
        ] as FetchCfarOfferSuccessV2;

        const cfarOffer = successResponse?.cfarOffer;

        if (cfarOffer?.id) {
          selectedRefundableFareOffer = cfarOffer;
          selectedRefundableFareChangePolicy =
            getCfarChangePolicyFromCfarOfferResponse(successResponse);
          selectedRefundAmount = successResponse.displayRefundAmount;
        }
      });
    }

    return {
      selectedRefundableFareOffer,
      selectedRefundableFareChangePolicy,
      selectedRefundAmount,
    };
  }, [cfarOffers, focusedRefundableFareId, fareIDAmountMap]);
  const [
    isUpdatingRefundableFaresProperties,
    setIsUpdatingRefundableFaresProperties,
  ] = useState<boolean>(false);
  const [openRefundableFareDetails, setOpenRefundableFareDetails] =
    useState(false);
  const showRefundableFaresOnly = useMemo(
    () =>
      isRefundableFaresActive &&
      hasSelectedRefundableFare &&
      isInChooseReturnStep,
    [isRefundableFaresActive, hasSelectedRefundableFare, isInChooseReturnStep]
  );

  useEffect(() => {
    if (focusedRefundableFareId) {
      setAirlineFtcAmount(fareIDAmountMap[focusedRefundableFareId]);
    }
  }, [focusedRefundableFareId]);

  const {
    trackingEventControl,
    updateTrackingEventControl,
    removeEventFromTrackingEventControl,
  } = useTrackingEventControl();

  useEffect(() => {
    setIsChooseDepartureStep(
      flightShopProgress === FlightShopStep.ChooseDeparture
    );
    setIsChooseReturnStep(flightShopProgress === FlightShopStep.ChooseReturn);
    setIsInReviewStep(flightShopProgress === FlightShopStep.ReviewItinerary);
    setIsInPredictionStep(
      flightShopProgress === FlightShopStep.PricePrediction
    );
    setIsInFareDetailsStep(flightShopProgress === FlightShopStep.FareDetails);
    setIsInCustomizeStep(flightShopProgress === FlightShopStep.Customize);
  }, [flightShopProgress]);

  const [fareTrips, setFareTrips] = useState([]);

  const resetAllFilters = () => {
    setAirlineFilter(initialFilterOptions.airlineFilter);
    setStopsOption(initialFilterOptions.stopsOption);
    setFlightNumberFilter(initialFilterOptions.flightNumberFilter);
    setOutboundArrivalTimeRange(initialFilterOptions.outboundArrivalTimeRange);
    setOutboundDepartureTimeRange(
      initialFilterOptions.outboundDepartureTimeRange
    );
    setReturnDepartureTimeRange(initialFilterOptions.returnDepartureTimeRange);
    setReturnArrivalTimeRange(initialFilterOptions.returnArrivalTimeRange);
    setMaxPriceFilter(initialFilterOptions.maxPriceFilter);
    setFareclassOptionFilter(initialFilterOptions.fareclassOptionFilter);
    setAirportFilter(initialFilterOptions.airportFilter);
    setPolicyFilter(initialFilterOptions.policyFilter);
    setDurationFilter(initialFilterOptions.durationFilter);
    setApplyUserFlightPreferences(false);
    setUserPreferencesNotAvailable(false);
  };

  const expState = useExperiments();

  const priceFreezeGroup = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE
  );
  const similarFlightsGroup = getExperimentVariant(
    expState.experiments,
    SIMILAR_FLIGHTS
  );

  const isPriceFreezeEnabled = useMemo(() => {
    return (
      priceFreezeGroup === AVAILABLE &&
      flightShopType === FlightShopType.PRICE_FREEZE_PURCHASE
    );
  }, [priceFreezeGroup, flightShopType]);
  const isSimilarFlightsEnabled = useMemo(() => {
    return (
      similarFlightsGroup === AVAILABLE &&
      flightShopType === FlightShopType.PRICE_FREEZE_EXERCISE
    );
  }, [similarFlightsGroup, flightShopType]);

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const isFlightListOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const isMultiCityEnabledExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, AIR_MULTICITY_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const isMultiCityEnabled =
    isMultiCityEnabledExperiment &&
    (isCaponeTenant(config.TENANT) ||
      isAutoApprovalEnabled ||
      isApprovalsV2Enabled);

  const isChatbotEnabled = useMemo(
    () =>
      getExperimentVariant(expState.experiments, AIR_CHATBOT_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  const isTravelCreditHistoryExperiment = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        TRAVEL_CREDIT_HISTORY_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const recentlyViewedV2Flights = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_V2_FLIGHTS
  );
  const isRecentlyViewedFlightsV2Experiment = useMemo(() => {
    return recentlyViewedV2Flights === AVAILABLE;
  }, [recentlyViewedV2Flights]);

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );

  const isFareDetailsMobileExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, FARE_DETAILS_MOBILE) ===
      AVAILABLE,
    [expState]
  );

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const airCXV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        AIR_CX_V3_1,
        AIR_CX_V3_1_VARIANTS
      ),
    [expState]
  );

  const isAirPriceFreezeEnabled = useMemo(
    () =>
      getExperimentVariant(expState.experiments, PRICE_FREEZE) === AVAILABLE,
    [expState]
  );

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const hotelCrossSellV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        HOTEL_CROSS_SELL_V3_EXPERIMENT,
        HOTEL_CROSS_SELL_V3_VARIANTS
      ),
    [expState.experiments]
  );

  const isHotelCrossSellV3Experiment = hotelCrossSellV3Variant !== CONTROL;

  const airCXV4ExperimentVariant = getExperimentVariant(
    expState.experiments,
    AIR_CX_V4
  );
  const isAirCXV4Experiment = React.useMemo(
    () => airCXV4ExperimentVariant === AVAILABLE,
    [airCXV4ExperimentVariant]
  );

  const showCorpLaunchBanner = getShowLaunchBanner(sessionInfo);

  useEffect(() => {
    listPaymentMethods();
    return () => {
      // If user navigates away from Flight Shop, we will stop the rest of the FetchTripSummaries action.
      // Reason: If the rest of the fetchTripSummaries saga is not stopped, the state will eventually be populated.
      // Populating flights in the state can cause the next fetchTripSummaries to not run as we only call fetchTripSummaries
      // when flights in the state is set to empty or when we detect changes in the search params URL.
      stopFetchTripSummaries();
    };
  }, []);

  useEffect(() => {
    const {
      origin,
      destination,
      itineraryId = "",
      sliceIndex,
      segmentIndex,
      flightShopType: flightShopTypeFromQuery,
    } = parseQueryString(history) as IFlightShopParsedQuery;

    switch (flightShopTypeFromQuery) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        if (!flights) {
          setOriginalFlightItineraryId(itineraryId);
          // note: given that the /rebookSummary endpoint already validates eligibility, flightDisruption is used for event tracking only
          fetchFlightDisruptionsByItineraryId(itineraryId);
          fetchRebookSummary({
            origin,
            destination,
            itineraryId,
            history,
            isMobile: matchesMobile,
          });
          fetchSingleFlightItinerary(itineraryId);
          if (sliceIndex !== undefined && segmentIndex !== undefined) {
            setDisruptedFlightItinerarySliceAndSegmentIds(
              sliceIndex,
              segmentIndex
            );
          }
        }
        break;
      }
      default: {
        // fetchTripSummaries on every initial render
        if (!flights) {
          fetchTripSummaries(
            history,
            matchesMobile,
            isRecentlyViewedFlightsV2Experiment,
            airCXV3Variant !== AIR_CX_V3_1_VARIANT_1,
            airCXV3Variant !== CONTROL
          );
        }

        break;
      }
    }
  }, [flights, isRecentlyViewedFlightsV2Experiment, airCXV3Variant]);

  useEffect(() => {
    if (isHotelCrossSellV3Experiment) {
      fetchPotentialCrossSellOffers();
    }
  }, [isHotelCrossSellV3Experiment, tripCategory, departureDate, returnDate]);

  useEffect(() => {
    const queryString = parseQueryString(history);
    const flightShopTypeFromQuery = queryString.flightShopType;

    switch (flightShopTypeFromQuery) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        break;
      }
      default: {
        // we fetch trip summaries on new search since the currentUrl won't match (and on back button click)
        if (
          currentUrl !== history.location.search &&
          flightShopProgress === FlightShopStep.ChooseDeparture &&
          (matchesDesktop ||
            !isGlobalMobileNavExperiment ||
            history.location.state === undefined ||
            history.location.state === null ||
            history.location.state["fromPage"] != PATH_SHOP)
        ) {
          fetchTripSummaries(
            history,
            matchesMobile,
            isRecentlyViewedFlightsV2Experiment,
            airCXV3Variant !== AIR_CX_V3_1_VARIANT_1,
            airCXV3Variant !== CONTROL
          );
          setCurrentUrl(history.location.search);
        }
        // workaround when transitioning from cloned multicity funnel
        if (tripCategory !== queryString.tripCategory)
          setTripCategory(queryString.tripCategory);
        break;
      }
    }
  }, [history.location.search, isRecentlyViewedFlightsV2Experiment]);

  //Update state depending on query param
  useEffect(() => {
    const queryString = parseQueryString(history) as IFlightShopParsedQuery;
    if (flights) {
      if (!!queryString.flightShopProgress) {
        setFlightShopProgress(queryString.flightShopProgress);
      }
    }
  }, [flights, history.location.search]);

  useEffect(() => {
    if (!!flights && isInChooseDepartureStep) {
      trackEvent({
        eventName: VIEWED_FLIGHT_LIST,
        properties: {
          ...viewedForecastProperties,
          ...travelOfferProperties?.properties,
          price_freeze_flow: isSimilarFlightsEnabled,
          entry_type: flightShopEntryPoint,
          cross_sell_displayed:
            isHotelCrossSellV3Experiment && potentialCrossSellOffers.length > 0,
        },
        encryptedProperties: [
          ...(travelOfferProperties?.encryptedProperties || []),
        ],
      });
    }
  }, [flights, isInChooseDepartureStep]);

  useEffect(() => {
    if (
      !!flights &&
      isInChooseReturnStep &&
      (!isRefundableFaresActive ||
        !hasSelectedRefundableFare ||
        batchCfarOffersCallState === CallState.Failed ||
        (batchCfarOffersCallState === CallState.Success &&
          hasUpdatedCfarOffersForReturnFlights))
    ) {
      const tripMultiTicketTypes = fareTrips
        .map((f: any) => tripDetailsById[f.trip])
        .flatMap((trip) => trip?.fareDetails.map((f) => f.multiTicketType));

      trackEvent({
        eventName: VIEWED_FLIGHT_LIST,
        properties: {
          ...viewedForecastProperties,
          ...travelOfferProperties?.properties,
          refundable_flights:
            isRefundableFaresActive &&
            hasUpdatedCfarOffersForReturnFlights &&
            Object.keys(cfarOffers).length > 0
              ? getNumberOfRefundableFlights(cfarOffers)
              : undefined,
          cfar_fare_choice: refundableFaresProperties.cfar_fare_choice,
          price_freeze_flow: isSimilarFlightsEnabled,
          entry_type: flightShopEntryPoint,
          multi_ticket_type: tripMultiTicketTypes,
          cross_sell_displayed:
            isHotelCrossSellV3Experiment && potentialCrossSellOffers.length > 0,
        },
        encryptedProperties: [
          ...(travelOfferProperties?.encryptedProperties || []),
        ],
      });
    }
  }, [
    flights,
    isInChooseReturnStep,
    isRefundableFaresActive,
    hasSelectedRefundableFare,
    batchCfarOffersCallState,
    hasUpdatedCfarOffersForReturnFlights,
  ]);

  useEffect(() => {
    if (
      rebookSummaryCallState === CallState.Success &&
      fetchSingleFlightItineraryCallState === CallState.Success
    ) {
      trackEvent({
        eventName: CAP1_VIEWED_REBOOKING_FLIGHT_LIST,
        properties: {
          ...viewedRebookingFlightListProperties,
          ...disruptedFlightDpExerciseFactsProperties,
          rebook_origin: origin?.id.code.code,
          rebook_destination: destination?.id.code.code,
        } as Cap1DpExerciseItineraryFactsProperties &
          Cap1DpExerciseFlightsListFactsProperties,
      });
    }
  }, [
    rebookSummaryCallState,
    fetchSingleFlightItineraryCallState,
    viewedRebookingFlightListProperties,
  ]);

  //Update query sting if flightShopProgress does not match state
  useEffect(() => {
    const queryString = parseQueryString(history) as IFlightShopParsedQuery;
    if (queryString.flightShopProgress !== flightShopProgress) {
      pushToPathWithExistingQueryParams(
        history,
        PATH_SHOP,
        {
          flightShopProgress,
        },
        false
      );
    }
    if (
      matchesMobile &&
      (flightShopProgress === FlightShopStep.ReviewItinerary ||
        flightShopProgress === FlightShopStep.FareDetails ||
        flightShopProgress === FlightShopStep.ChooseDeparture ||
        flightShopProgress === FlightShopStep.ChooseReturn)
    ) {
      // ios does not like scrollTo (it doesn't always scroll to the very top so per stackoverflow, using setTimeout and setting top to a number lower than 0 will scroll it to the top)
      setTimeout(() => {
        window.scrollTo({ top: -1, left: 0 });
      }, 100);
    }
  }, [flightShopProgress]);

  useEffect(() => {
    if (
      flightShopProgress === FlightShopStep.PricePrediction &&
      (isPriceFreezeEnabled ||
        isSimilarFlightsEnabled ||
        isInDisruptionProtectionRebook)
    ) {
      updateFlightShopStep(FlightShopStep.ChooseDeparture, true, history);
      setFlightShopProgress(FlightShopStep.ChooseDeparture);
    }
  }, [
    flightShopProgress,
    isPriceFreezeEnabled,
    isSimilarFlightsEnabled,
    isInDisruptionProtectionRebook,
  ]);

  useEffect(() => {
    const queryString = parseQueryString(history);
    const flightShopTypeFromQuery = queryString.flightShopType;

    switch (flightShopTypeFromQuery) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        break;
      }
      default: {
        if (
          refreshPrediction &&
          flightShopProgress === FlightShopStep.ChooseDeparture
        ) {
          populateFlightShopQueryParams({
            history,
            useHistoryPush: true,
            forceQueryUpdate: false,
          });

          setCurrentUrl(history.location.search);
          fetchTripSummariesForPrediction(history, false);
        }
        break;
      }
    }
  }, [refreshPrediction]);

  useEffect(() => {
    if (airCXV3Variant !== CONTROL) {
      populateFlightShopQueryParams({
        history,
        useHistoryPush: true,
        forceQueryUpdate: false,
      });

      setCurrentUrl(history.location.search);
    }
  }, [stopsOption, airCXV3Variant]);

  useEffect(() => {
    // note: set hasPriceFreezeOnOutbound to false whenever the user comes back to ChooseDeparture
    if (flightShopProgress === FlightShopStep.ChooseDeparture) {
      setHasPriceFreezeOnOutbound(false);
    }
  }, [flightShopProgress]);

  // note: this useEffect helps to sync up flightShopProgress in desktop & mobile flows
  useEffect(() => {
    switch (flightShopProgress) {
      case FlightShopStep.Customize:
        if (matchesDesktop && !isCustomizePageMarketplaceEnabled) {
          setFlightShopProgress(FlightShopStep.ReviewItinerary);
        }
        break;
      case FlightShopStep.FareDetails:
        if (matchesDesktop) {
          setFlightShopProgress(FlightShopStep.ReviewItinerary);
        }
        break;
      default:
        break;
    }
  }, [flightShopProgress, matchesDesktop, isCustomizePageMarketplaceEnabled]);

  useEffect(() => {
    if (matchesMobile) {
      if (flightShopProgress === FlightShopStep.FareDetails) {
        // removes medallia button
        if (document && document.getElementById("nebula_div_btn")) {
          document!.getElementById("nebula_div_btn")!.style.display = "none";
        }
      } else {
        if (document && document.getElementById("nebula_div_btn")) {
          document!.getElementById("nebula_div_btn")!.style.display = "unset";
        }
      }
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, [flightShopProgress, matchesMobile]);

  /*
    TODO: the flight search param is supposed to be ALWAYS populated in the query (while staying in the flight shop funnel) by design; however,
    given the current complexity of fetchTripSummariesV2Saga, it's difficult to reason with why sometimes the query isn't updated, and how we can
    force it to populate the query while keeping everything else unaffected. Here's a workaround (to keep the query updated) until it's reworked.
  */
  useEffect(() => {
    const {
      origin: originFromQuery,
      destination: destinationFromQuery,
      flightShopType: flightShopTypeFromQuery,
    } = parseQueryString(history) as IFlightShopParsedQuery;

    switch (flightShopTypeFromQuery) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        break;
      }
      default: {
        // note: when `origin` and `destination` are not populated in the query, try populateFlightShopQueryParams so the page can be refreshed
        if (
          !!origin &&
          !!destination &&
          !originFromQuery &&
          !destinationFromQuery &&
          flightShopType === FlightShopType.DEFAULT
        ) {
          populateFlightShopQueryParams({
            history,
            forceQueryUpdate: true,
          });
        }
        break;
      }
    }
  }, [origin, destination, history, flightShopType]);

  useEffect(() => {
    if (
      isInChooseReturnStep &&
      isRefundableFaresActive &&
      hasSelectedRefundableFare &&
      flightList.length > 0 &&
      !!flightList[0].fares[0]?.tripId &&
      batchCfarOffersCallState === CallState.NotCalled &&
      !hasUpdatedCfarOffersForReturnFlights
    ) {
      batchFetchCfarOffers(
        flightList.reduce<
          {
            tripId: string;
            fareId: string;
          }[]
        >((result, flight) => {
          result.push.apply(
            result,
            flight.fares.map((fare: any) => {
              return { tripId: fare.tripId, fareId: fare.id };
            })
          );

          return result;
        }, []),
        "return-flights"
      );
    }
  }, [
    isInChooseReturnStep,
    isRefundableFaresActive,
    hasSelectedRefundableFare,
    batchCfarOffersCallState,
    hasUpdatedCfarOffersForReturnFlights,
    flightList,
  ]);

  useEffect(() => {
    const { flightShopType: flightShopTypeFromQuery } =
      parseQueryString(history);

    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });

    fetchTravelWalletDetails();
    fetchRewardsAccounts();
    if (
      flightShopTypeFromQuery !== undefined &&
      flightShopType !== flightShopTypeFromQuery
    ) {
      setFlightShopType(flightShopTypeFromQuery);
    }
  }, []);

  useEffect(() => {
    if (isTravelCreditHistoryExperiment) {
      fetchTravelWalletCreditHistory();
    }
  }, [isTravelCreditHistoryExperiment]);

  const handlePriceFreezeFareSelect = () => {
    populateFlightBookQueryParams({
      history,
      pathname: PATH_FREEZE,
      preserveQuery: true,
    });
  };

  const handleInitializeOfferDataAndCustomOffer = (
    departureDate: string,
    tripId: string,
    fareId: string
  ) => {
    if (isPriceFreezeDurationEnabled && !isInDisruptionProtectionRebook) {
      initializeOfferDataAndCustomOffer({
        departureDate,
        tripId,
        fareId,
        history,
      });
    }
  };

  const handleFareSelect = (
    flight: any,
    fareId: string,
    idx: number,
    limit?: FiatPrice | null
  ) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (isInChooseReturnStep) {
      const tripDetails =
        tripDetailsById[
          flight.fares.find((fare: any) => fare.id == fareId)?.tripId
        ];
      const fareDetails: FareDetails | undefined =
        tripDetails?.fareDetails.find((fare) => fare.id == fareId);

      setChosenReturnSlice({
        returnFareId: fareId,
        returnSliceId: flight.slice,
        returnFareRating: fareDetails?.slices[1].fareShelf?.rating,
        tripId: tripDetails?.id,
      });

      if (isPriceFreezeEnabled) {
        handlePriceFreezeFareSelect();
      } else {
        updateFlightShopStep(FlightShopStep.ReviewItinerary, false, history);
      }
      handleInitializeOfferDataAndCustomOffer(
        tripDetails?.slices[0]?.departureTime,
        tripDetails?.id,
        fareId
      );

      updateTrackingEventControl({
        [SELECTED_FLIGHT]: {
          properties: {
            ...travelOfferProperties?.properties,
            flight_list_ranking: idx,
            multi_ticket_type: fareDetails?.multiTicketType,
          },
          encryptedProperties: [
            ...(travelOfferProperties?.encryptedProperties || []),
          ],
        },
      });

      if (flights) {
        const slice = tripDetails?.slices[1];
        const airline =
          flights.airlines[slice.segmentDetails[0].marketingAirline.code];
        const airlineCode = airline
          ? airline.code
          : slice.segmentDetails[0].marketingAirline.code;

        const returnSlice = flights.fares[fareId].return;

        if (returnSlice) {
          const fareSlice = flights.fareSlices[returnSlice];

          const flightIsRecommendedBasedOnPreferences =
            flightShopRecommendedBasedOnPreferences ||
            (userHasSetFlightPreferences &&
              !!userFlightPreferences &&
              !shouldApplyUserFlightPreferences &&
              isFlightRecommendedBasedOnPreferences(
                fareSlice,
                userFlightPreferences,
                airlineCode as AirlineOpenEnum
              ));

          setFlightShopRecommendedBasedOnPreferences(
            flightIsRecommendedBasedOnPreferences
          );
        }
      }
    } else {
      const selectedFareIndex = flight.fares.findIndex(
        (fare: any) => fare.example.fare === fareId
      );
      if (flights) {
        const tripId = flight.fares[selectedFareIndex].example.trip;
        const tripDetails = tripDetailsById[tripId];
        const multiTicketType = tripDetails?.fareDetails?.find(
          (f) => f.id === fareId
        )?.multiTicketType;

        setChosenOutgoingSlice({
          outgoingFareId: fareId,
          outgoingSliceId: flight.slice,
          outgoingFareRating:
            flights.fareSlices[flights.fares[fareId].outbound].fareShelf.value,
          tripId,
          // whenever selecting a different departure flight, reset return ids
          resetReturnIds: true,
        });

        if (isRoundTrip) {
          setFlightShopProgress(FlightShopStep.ChooseReturn);
          updateFlightShopStep(FlightShopStep.ChooseReturn, false, history);
        } else {
          if (isPriceFreezeEnabled) {
            handlePriceFreezeFareSelect();
          } else {
            setFlightShopProgress(FlightShopStep.ReviewItinerary);
            updateFlightShopStep(
              FlightShopStep.ReviewItinerary,
              false,
              history
            );
          }
          handleInitializeOfferDataAndCustomOffer(
            tripDetails?.slices[0]?.departureTime,
            tripId,
            fareId
          );
        }

        if (airCXV3Variant === CONTROL) {
          resetAllFilters();
        }

        setSelectedFlightIndex(idx + 1);
        updateTrackingEventControl({
          [SELECTED_FLIGHT]: {
            properties: {
              ...travelOfferProperties?.properties,
              flight_list_ranking: idx,
              multi_ticket_type: multiTicketType,
            },
            encryptedProperties: [
              ...(travelOfferProperties?.encryptedProperties || []),
            ],
          },
        });

        const slice = tripDetails?.slices[0];
        const airline =
          flights.airlines[slice.segmentDetails[0].marketingAirline.code];
        const airlineCode = airline
          ? airline.code
          : slice.segmentDetails[0].marketingAirline.code;

        const fareSlice = flights.fareSlices[flights.fares[fareId].outbound];

        const flightIsRecommendedBasedOnPreferences =
          userHasSetFlightPreferences &&
          !!userFlightPreferences &&
          !shouldApplyUserFlightPreferences &&
          isFlightRecommendedBasedOnPreferences(
            fareSlice,
            userFlightPreferences,
            airlineCode as AirlineOpenEnum
          );

        setFlightShopRecommendedBasedOnPreferences(
          flightIsRecommendedBasedOnPreferences
        );
      } else {
        setOpenMobileFlightDetailsModal(false);
      }
    }

    if (isCfarEnabled) {
      resetFetchAncillaryOfferState({
        preserveCfarId: isRefundableFaresActive,
      });
    }

    setPolicyLimit(limit);

    if (isInDisruptionProtectionRebook) {
      trackEvent({
        eventName: CAP1_REBOOKING_CONFIRMED_SLICE,
        properties: {
          ...rebookingConfirmedSliceProperties,
          rebook_origin: origin?.id.code.code,
          rebook_destination: destination?.id.code.code,
        } as Cap1DpExerciseItineraryFactsProperties &
          Cap1DpExerciseFlightsListFactsProperties &
          Cap1DpExerciseFactsProperties,
      });
    }
  };

  const handleFlightSelect = (fareTrips: any, clickedFareClass: string) => {
    const uniqueTripIds: string[] = Array.from(
      new Set(fareTrips.map((fareTrip: any) => fareTrip.trip))
    );
    uniqueTripIds.map((fareTrip: string) => {
      fetchTripDetails({ tripId: fareTrip });
    });

    setFareTrips(fareTrips);
    // note: when it is showRefundableFaresOnly, batchFetchCfarOffers has already been called to fetch RF's for all flights
    if (isRefundableFaresActive && !showRefundableFaresOnly) {
      batchFetchCfarOffers(
        fareTrips.map((fareTrip: any) => ({
          tripId: fareTrip.trip,
          fareId: fareTrip.fare,
        }))
      );
      setIsUpdatingRefundableFaresProperties(true);
    }

    updateTrackingEventControl({
      [VIEWED_SLICE]: {
        properties: {
          ...viewedForecastProperties,
          fare_class: clickedFareClass || "",
        },
      },
    });

    if (isInDisruptionProtectionRebook) {
      trackEvent({
        eventName: CAP1_REBOOKING_VIEWED_SLICE,
        properties: {
          ...rebookingViewedSliceProperties,
          rebook_origin: origin?.id.code.code,
          rebook_destination: destination?.id.code.code,
        } as Cap1DpExerciseItineraryFactsProperties &
          Cap1DpExerciseFlightsListFactsProperties &
          Cap1DpExerciseFactsProperties,
      });
    }
  };

  const handleRefundableFare = (fareId: string, isRefundableFare: boolean) => {
    if (isRefundableFare) {
      let cfarOffer: CfarOffer | undefined;
      Object.values(cfarOffers).forEach((offersByFareId) => {
        const currentCfarOffer = (
          offersByFareId[fareId] as FetchCfarOfferSuccessV2
        )?.cfarOffer;

        if (currentCfarOffer?.id) {
          cfarOffer = currentCfarOffer;
        }
      });

      if (cfarOffer) {
        setSelectedCfarId(cfarOffer.id);

        const basePrices = expandedFareDetails?.fareDetails?.find(
          (fare) => fare.id === fareId
        )?.paxPricings?.[0]?.pricing?.total;
        if (basePrices) {
          updateRefundableFaresProperties({
            rf_adult_price_total_usd:
              basePrices.fiat.value + cfarOffer.premiumPerPax.fiat.value,
          });
        }
      }
    } else if (hasSelectedRefundableFare) {
      setSelectedCfarId(null);
    }
    setHasSelectedRefundableFare(isRefundableFare);

    const { number_of_rf } = refundableFaresProperties;
    const cfarFareOffered = number_of_rf !== undefined && number_of_rf > 0;
    const cfarFareChoice: 0 | 1 | null = cfarFareOffered
      ? (Number(isRefundableFare) as 0 | 1)
      : null;
    updateRefundableFaresProperties({
      cfar_choice: cfarFareChoice,
      cfar_fare_choice: cfarFareChoice,
      cfar_fare_offered: Number(cfarFareOffered) as 0 | 1,
    });

    if (isInChooseDepartureStep && isRoundTrip && isRefundableFare) {
      resetCfarOffers();
    }
  };

  // note: when the user has selected a refundable fare on outbounds, the FE needs to wait for batchFetchCfarOffers to finish before showing return flights
  const isReadyToRenderRefundableFlights =
    !showRefundableFaresOnly ||
    // batchCfarOffersCallState will stay as NotCalled when the flightList doesn't get populated (a bug), let's not show the modal when it happens
    (batchCfarOffersCallState === CallState.NotCalled &&
      flightList.length === 0) ||
    batchCfarOffersCallState === CallState.Failed ||
    (batchCfarOffersCallState === CallState.Success &&
      hasUpdatedCfarOffersForReturnFlights);

  const expandedFareDetails = React.useMemo(() => {
    const fetchedAllFareDetails = !!fareTrips.length
      ? fareTrips.reduce((hasFetchedFareTrip: boolean, fareTrip: any) => {
          return hasFetchedFareTrip && !!tripDetailsById[fareTrip.trip];
        }, true)
      : false;
    const readyToShow =
      fetchedAllFareDetails &&
      // when the refundable fare isn't active, it will be true; otherwise it will wait for batchCfarOffers to terminate
      (!isRefundableFaresActive ||
        batchCfarOffersCallState === CallState.Failed ||
        batchCfarOffersCallState === CallState.Success);

    return readyToShow
      ? getSliceFareDetails({
          tripDetailsById,
          fareTrips,
          cfarOffers:
            isRefundableFaresActive &&
            batchCfarOffersCallState === CallState.Success
              ? cfarOffers
              : undefined,
          flightShopStep: flightShopProgress,
          hasSelectedRefundableFare,
        })
      : null;
  }, [
    fareTrips,
    tripDetailsById,
    isRefundableFaresActive,
    batchCfarOffersCallState,
    flightShopProgress,
    hasSelectedRefundableFare,
  ]);

  const fareCustomizations:
    | { [key in string]: IFareCustomization }
    | undefined = (() => {
    let result: { [key in string]: IFareCustomization } = {};
    if (expandedFareDetails && expandedFareDetails.fareDetails.length > 0) {
      expandedFareDetails.fareDetails.forEach((fare) => {
        const { id, slices } = fare;
        const title =
          slices[isInChooseDepartureStep ? 0 : 1]?.fareShelf?.shortBrandName;
        const isRefundableFare = getIsRefundableFare(id);
        const baseFareId = isRefundableFare
          ? getBaseFareIdFromRefundableFareId(id)
          : id;
        let changePolicy: UtasPolicy[] = [];
        let cashCoveragePercentage: number | undefined = undefined;
        let refundAmount: number | undefined = undefined;
        let baseAirlineFare: number | undefined = undefined;
        Object.values(cfarOffers).forEach((offersByFareId) => {
          const successResponse = offersByFareId[
            baseFareId
          ] as FetchCfarOfferSuccessV2;
          const cfarOffer = successResponse?.cfarOffer;

          if (cfarOffer?.id) {
            cashCoveragePercentage =
              cfarOffer.policyData?.cashCoveragePercentage;
            changePolicy =
              getCfarChangePolicyFromCfarOfferResponse(successResponse);
            refundAmount = successResponse.displayRefundAmount;
            const base = fare.paxPricings?.[0].pricing.total?.fiat.value;
            if (base) {
              baseAirlineFare = base - cfarOffer.premiumPerPax.fiat.value;
              fareIDAmountMap[baseFareId] = baseAirlineFare;
            }
          }
        });
        const ftcTypes =
          changePolicy.length > 0 ? getFtcTypeArr(changePolicy) : [];

        if (isRefundableFare) {
          const subtitle = isAirRefundableFareCopyEnabled
            ? PARTIALLY_REFUNDABLE_BANNER()
            : REFUNDABLE_FARES_BANNER(cashCoveragePercentage);
          const firstBullet = REFUNDABLE_FARES_POINT_ONE({
            variant: "v1",
            useStrong: false,
          });
          const secondBullet = secondBulletText({
            ftcTypes: showNoFtcOnlyInRefundableFares
              ? ftcTypes.map((_) => FtcType.NoFtc)
              : ftcTypes,
            cashCoveragePercentage,
            useStrong: false,
            refundAmount: isAirRefundableFareCopyEnabled
              ? refundAmount
              : undefined,
            airlineFTCAmount: isAirRefundableFareCopyEnabled
              ? baseAirlineFare
              : undefined,
            excludeWithFeesCopy: true,
            concise: true,
            isSpiritOrFrontierAirlinesSelected,
          });
          const thirdBullet = REFUNDABLE_FARES_POINT_THREE({});

          result[id] = {
            title,
            subtitle,
            ctaType: "refundable-fare",
            additionalPolicies: [
              { icon: IconName.CheckCircleTransparent, copy: firstBullet },
              { icon: IconName.CheckCircleTransparent, copy: secondBullet },
              { icon: IconName.CheckCircleTransparent, copy: thirdBullet },
            ],
            onRefundableFaresBannerClick: (onFareClicked: () => void) => {
              setFocusedRefundableFareId(baseFareId);
              /*
                        note: storing a function using useState is tricky, and a simple fix is to wrap the function in an argument-less function;
                        see https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1 for details
                      */
              setOnClickRefundableFareBanner(() => onFareClicked);
              setOpenRefundableFareDetails(true);
            },
            disabled: isAgentPortal,
          };
        }
        // non-refundable fares
        else {
          const hasRelatedRefundableFare =
            !!expandedFareDetails.fareDetails.find(
              (temp) => temp.id === getRefundableFareIdFromFareId(id)
            );
          result[id] = {
            title,
            ctaType: "default",
            isHidden: showRefundableFaresOnly,
          };
          if (hasRelatedRefundableFare) {
            switch (ftcTypes[0]) {
              case FtcType.FtcWithFees:
                result[id].subtitle = <>{CREDIT_AVAILABLE_WITH_FEES_BANNER}</>;
                break;
              case FtcType.FtcNoFees:
                result[id].subtitle = (
                  <>{CREDIT_AVAILABLE_WITH_NO_FEES_BANNER}</>
                );
                break;
              case FtcType.NoFtc:
              default:
                result[id].subtitle = <>{CREDIT_NOT_AVAILABLE_BANNER}</>;
                break;
            }
          }
        }
      });
    }
    return result && Object.keys(result).length > 0 ? result : undefined;
  })();

  // refundable fares have some complicated event tracking scenerios, and here's a series of effects for handling it
  useEffect(() => {
    if (
      isRefundableFaresActive &&
      expandedFareDetails &&
      !isEmpty(cfarOffers) &&
      batchCfarOffersCallState === CallState.Success
    ) {
      let totalNumberOfRf: number | undefined;
      let observedNumberOfRf: number | undefined;

      if (showRefundableFaresOnly) {
        if (hasUpdatedCfarOffersForReturnFlights) {
          // note: rf_knockout_price is technically always 0 when it's displaying RF's only
          totalNumberOfRf = expandedFareDetails.fareDetails.reduce<number>(
            (count, fare) => count + (getIsRefundableFare(fare.id) ? 1 : 0),
            0
          );
          observedNumberOfRf = totalNumberOfRf;
        }
      } else {
        totalNumberOfRf = getNumberOfCfarOffers(cfarOffers);
        observedNumberOfRf = expandedFareDetails.fareDetails.reduce<number>(
          (count, fare) => count + (getIsRefundableFare(fare.id) ? 1 : 0),
          0
        );
      }

      if (totalNumberOfRf !== undefined && observedNumberOfRf !== undefined) {
        updateRefundableFaresProperties({
          cfar_fare_offered: totalNumberOfRf > 0 ? 1 : 0,
          rf_knockout_price: totalNumberOfRf - observedNumberOfRf,
          number_of_rf: observedNumberOfRf,
        });
        setIsUpdatingRefundableFaresProperties(false);
      }
    }
  }, [
    expandedFareDetails,
    cfarOffers,
    isRefundableFaresActive,
    batchCfarOffersCallState,
    showRefundableFaresOnly,
    hasUpdatedCfarOffersForReturnFlights,
  ]);

  // delayed firing of VIEWED_SLICE
  useEffect(() => {
    if (
      !!trackingEventControl[VIEWED_SLICE] &&
      (!isRefundableFaresActive ||
        batchCfarOffersCallState === CallState.Failed ||
        (batchCfarOffersCallState === CallState.Success &&
          !isUpdatingRefundableFaresProperties))
    ) {
      const tripMultiTicketTypes = fareTrips
        .map((f: any) => tripDetailsById[f.trip])
        .flatMap((trip) => trip?.fareDetails.map((f) => f?.multiTicketType));

      trackEvent({
        eventName: VIEWED_SLICE,
        properties: {
          ...trackingEventControl[VIEWED_SLICE]?.properties,
          cfar_fare_offered: refundableFaresProperties.cfar_fare_offered,
          rf_knockout_price: refundableFaresProperties.rf_knockout_price,
          number_of_rf: refundableFaresProperties.number_of_rf,
          multi_ticket_type: tripMultiTicketTypes,
        },
      });
      removeEventFromTrackingEventControl(VIEWED_SLICE);
    }
  }, [
    trackingEventControl[VIEWED_SLICE],
    isRefundableFaresActive,
    batchCfarOffersCallState,
    refundableFaresProperties,
    isUpdatingRefundableFaresProperties,
  ]);

  // delayed firing of SELECTED_FLIGHT
  useEffect(() => {
    if (!!trackingEventControl[SELECTED_FLIGHT] && selectedTrip.tripId) {
      // note: selectedSliceProperties must be added here instead of the handleFareSelect
      // to ensure slice-specific properties are populated in state
      const selectedSliceProperties = isInChooseReturnStep
        ? selectedReturnSliceProperties
        : selectedOutgoingSliceProperties;
      trackEvent({
        eventName: SELECTED_FLIGHT,
        properties: {
          ...trackingEventControl[SELECTED_FLIGHT]?.properties,
          ...selectedSliceProperties,
          cfar_choice: refundableFaresProperties.cfar_choice,
          cfar_fare_choice: refundableFaresProperties.cfar_fare_choice,
          cfar_fare_offered: refundableFaresProperties.cfar_fare_offered,
          rf_adult_price_total_usd:
            refundableFaresProperties.rf_adult_price_total_usd,
          rf_discount_original_premium: cfarDiscountProperties.original_premium,
          rf_discount_percentage: cfarDiscountProperties.discount_percentage,
        },
      });
      removeEventFromTrackingEventControl(SELECTED_FLIGHT);
    }
  }, [
    trackingEventControl[SELECTED_FLIGHT],
    selectedTrip.tripId,
    isInChooseReturnStep,
  ]);

  const renderDesktopFlightShopHeader = () => {
    const renderDesktopFlightShopRewardsHeader = () => {
      const getTitleCopy = () => {
        if (isInReviewStep) {
          return REVIEW_ITINERARY_TITLE_TEXT;
        } else if (isInCustomizeStep) {
          return CUSTOMIZE_TITLE_TEXT;
        } else if (origin && destination) {
          return CHOOSING_FLIGHT_TEXT(
            flightShopProgress,
            origin.label,
            destination.label
          );
        } else {
          return "";
        }
      };
      const getSubtitleCopy = () => {
        if (isInReviewStep) {
          return REVIEW_ITINERARY_SUBTITLE_TEXT;
        } else if (isInCustomizeStep) {
          return CUSTOMIZE_SUBTITLE_TEXT;
        } else {
          return FLIGHT_PRICES_TEXT(tripCategory);
        }
      };

      return (
        <DesktopRewardsHeader
          title={getTitleCopy()}
          subtitle={getSubtitleCopy()}
        />
      );
    };
    const renderDesktopFlightShopRebookHeader = () => {
      const getTitleCopy = () => {
        if (isInReviewStep) {
          return isRapidRebookRenameEnabled
            ? REVIEW_ITINERARY_REBOOK_TITLE_TEXT_NEW
            : REVIEW_ITINERARY_REBOOK_TITLE_TEXT_OLD;
        } else {
          return FLIGHT_SHOP_REBOOK_TITLE_TEXT({
            origin: origin?.label,
            destination: destination?.label,
            isSelectingReturnFlightForRebook,
          });
        }
      };
      const getSubtitleCopy = () => {
        if (isInReviewStep) {
          return REVIEW_ITINERARY_REBOOK_SUBTITLE_TEXT;
        } else {
          return FLIGHT_SHOP_REBOOK_SUBTITLE_TEXT;
        }
      };

      return (
        <ContactSupportHeader
          title={getTitleCopy()}
          subtitle={getSubtitleCopy()}
          history={history}
          isPositionFixed
        />
      );
    };

    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        return renderDesktopFlightShopRebookHeader();
      }
      default: {
        return renderDesktopFlightShopRewardsHeader();
      }
    }
  };

  const flightListProps = {
    tripSummariesLoading,
    flightsToRender: flightList,
    invertedStopsFilterFlightList,
    flights,
    rewardsKey,
    handleFareSelect,
    fareClassFilter,
    handleFlightSelect,
    expandedFareDetails,
    departureDate,
    returnDate,
    isInChooseReturnStep,
    hasFlightsError,
    flightsErrorCode,
    selectedTrip: selectedTrip as ISelectedTrip,
    maxFlightPriceFilter,
    hasAppliedFareClassFilter,
    hasAppliedNonFareclassFilter,
    setOpenFlightShopCalendarDesktop,
    isRoundTrip,
    setOpenFlightShopCalendarMobile,
    resetAllFilters,
    rerunPrediction,
    openMobileFlightDetailsModal,
    setOpenMobileFlightDetailsModal,
    // TODO - PF entry refactor: move price-freeze props to priceFreezeProps, which is of type IPriceFreezeEntryBannerProps
    priceFreezeOfferCheapestTripAirports,
    priceFreezeOfferCheapestTripTripDetails,
    cheapestFrozenPrice,
    priceFreezeOffer,
    priceFreezeOfferCheapestTripFareId,
    priceFreezeRewards,
    priceFreezeDuration,
    priceFreezeFiat,
    priceFreezeCap,
    history,
    populateFlightBookQueryParams,
    hasPriceFreezeOnOutbound,
    setHasPriceFreezeOnOutbound,
    flightShopType,
    flightShopProgress,
    priceDropProtectionCandidateId,
    offersByTripId,
    credit,
    isLoadingIndicatorDisplayed:
      isRefundableFaresActive &&
      batchCfarOffersCallState === CallState.InProcess,
    isReadyToRenderFlights: isReadyToRenderRefundableFlights,
    cfarOffers,
    showRefundableFaresOnly,
    fareCustomizations: isRefundableFaresActive
      ? fareCustomizations
      : undefined,
    handleRefundableFare: isRefundableFaresActive
      ? handleRefundableFare
      : undefined,
    largestValueAccount,
    sortOption,
    setSortOption,
    hasSetMaxPriceFilter,
    isChatbotEnabled,
    isInPolicyFilter,
    isAirPriceFreezeEnabled,
    setSelectedMarketingAirlineCodes,
    setSelectedOperatingAirlineCodes,
    isSpiritOrFrontierAirlinesSelected,
    potentialCrossSellOffers,
  };

  const [showTravelWalletBanner, setShowTravelWalletBanner] = useState(false);

  const prevShouldApplyUserFlightPreferences = usePrevious(
    shouldApplyUserFlightPreferences
  );

  useEffect(() => {
    setShowTravelWalletBanner(
      (!isInReviewStep && credit && credit.amount.amount < 0) || false
    );
  }, [credit]);

  useEffect(() => {
    if (userFlightPreferencesCallState == CallState.NotCalled) {
      fetchUserFlightPreferences();
    }
  }, []);

  useEffect(() => {
    if (
      prevShouldApplyUserFlightPreferences === false &&
      shouldApplyUserFlightPreferences === true
    ) {
      const properties: AppliedPreferencesProperties = {
        preferences_type: "flights",
        location: "flight_list",
      };

      trackEvent({
        eventName: APPLIED_PREFERENCES,
        properties: addTrackingProperties(
          expState.trackingProperties,
          properties
        ),
      });
    }
  }, [shouldApplyUserFlightPreferences, prevShouldApplyUserFlightPreferences]);

  useEffect(() => {
    if (!tripSummariesLoading && flightShopType === FlightShopType.DEFAULT) {
      const selectedFareClasses = Object.keys(fareClassFilter).filter(
        (fareClassKey) => !!fareClassFilter[fareClassKey]
      );

      trackEngagementEvent({
        event: {
          event_type: "flight_search",
          origin_location: origin?.label,
          destination_location: destination?.label,
          trip_type: tripCategory,
          nonstop_only: stopsOption === SliceStopCountFilter.NONE,
          departure_date_timestamp: departureDate?.getTime(),
          return_date_timestamp: returnDate?.getTime(),
          lowest_price: minFlightPrice,
          flight_class: selectedFareClasses.length
            ? selectedFareClasses
            : undefined,
        },
      });
    }
  }, [tripSummariesLoading]);

  const renderDesktopFlightShop = () => {
    let outboundSlice: Slice | undefined = undefined;
    let returnSlice: Slice | undefined = undefined;
    if (isInReviewStep && slicesMap) {
      const trip = selectedTrip as ISelectedTrip;
      outboundSlice = slicesMap[trip?.outgoingSliceId ?? ""];
      returnSlice = slicesMap[trip?.returnSliceId ?? ""];
    }

    return (
      <>
        {renderDesktopFlightShopHeader()}
        {showTravelWalletBanner && (
          <TravelWalletDetailsBanner
            showButton
            variant="full-width"
            screen={SelectedTravelOfferScreen.FLIGHT_SHOP}
            onDismiss={() => setShowTravelWalletBanner(false)}
          />
        )}
        <Box
          className={clsx("flight-shop-result-container", {
            "has-offer": showTravelWalletBanner,
            "use-grey-background":
              [
                FlightShopStep.ReviewItinerary,
                FlightShopStep.Customize,
              ].includes(flightShopProgress) && matchesDesktop,
            "not-live": showCorpLaunchBanner,
          })}
        >
          <LaunchBanner
            isBusinessLive={!showCorpLaunchBanner}
            onClick={() => {
              trackEvent({
                eventName: "complete_setup_clicked",
                properties: { entry: "traveler_activation_banner" },
              });
            }}
          />
          {!isInReviewStep && !isInCustomizeStep && (
            <FlightShopSearchControl
              disabled={isPriceFreezeEnabled || isSimilarFlightsEnabled}
              isFlightListOptimizationExperiment={
                isFlightListOptimizationExperiment
              }
              isMultiCityEnabled={isMultiCityEnabled}
              airCXV3Variant={airCXV3Variant}
              allFiltersModalOpen={allFiltersModalOpen}
              setAllFiltersModalOpen={setAllFiltersModalOpen}
              isEditMulticitySearchModalOpen={isEditMulticitySearchModalOpen}
              setIsEditMulticitySearchModalOpen={
                setIsEditMulticitySearchModalOpen
              }
            />
          )}
          {isInReviewStep && !isInDisruptionProtectionRebook && (
            <Box className="flight-shop-price-freeze-header">
              <Box className="flight-shop-price-freeze-header-details">
                <Box className="flight-shop-price-freeze-header-title">
                  <Typography className="title-text">
                    {outboundSlice?.hasSelfTransferLayover ||
                    returnSlice?.hasSelfTransferLayover
                      ? VIRTUAL_INTERLINE_FLIGHT_TITLE
                      : isMultiTicket
                      ? FARE_DETAILS_COMBINATION_FLIGHT_TITLE
                      : FARE_DETAILS_TITLE}
                  </Typography>
                </Box>
                <Box className="flight-shop-price-freeze-header-subtitle">
                  <Typography className="subtitle-text">
                    {outboundSlice?.hasSelfTransferLayover ||
                    returnSlice?.hasSelfTransferLayover
                      ? VIRTUAL_INTERLINE_FLIGHT_SUBTITLE
                      : isMultiTicket
                      ? FARE_DETAILS_COMBINATION_FLIGHT_SUBTITLE
                      : FARE_DETAILS_SUBTITLE}
                  </Typography>
                </Box>
              </Box>
              <Box
                className={clsx(
                  "flight-shop-price-freeze-header-image",
                  config.TENANT
                )}
              ></Box>
            </Box>
          )}
          {isInCustomizeStep && <AddOnHeader />}
          {!!flights && (
            <FlightShopHeader
              isMobile={false}
              airports={airports}
              isMediumDesktop={matchesMediumDesktopOnly}
              isInPriceFreezePurchase={isPriceFreezeEnabled}
              isInSimilarFlights={isSimilarFlightsEnabled}
              useLockPriceLanguage={false}
              useCustomizeStep={isCustomizePageMarketplaceEnabled}
              history={history}
              isFlightListOptimizationExperiment={
                isFlightListOptimizationExperiment
              }
              {...(isInDisruptionProtectionRebook
                ? { sortOptions: sortOptionsWithoutPrice }
                : {})}
              isTravelSalesEventActive={
                travelSalesEventVariant === TRAVEL_SALE_ACTIVE
              }
              airCXV3Variant={airCXV3Variant}
              showTravelWalletBanner={showTravelWalletBanner}
              isAirCXV4Experiment={isAirCXV4Experiment}
            />
          )}
          {!tripSummariesLoading && !!isInChooseDepartureStep && (
            <FlightList
              {...flightListProps}
              useLockPriceLanguage={false}
              isRefundableFaresEnabled={isRefundableFaresActive}
              airEntryProperties={airEntryProperties}
              stopsOption={stopsOption}
              airCXV3Variant={airCXV3Variant}
              userFlightPreferences={userFlightPreferences}
              userFlightPreferencesNotAvailable={
                userFlightPreferencesNotAvailable
              }
              userHasSetFlightPreferences={userHasSetFlightPreferences}
              shouldApplyUserFlightPreferences={
                shouldApplyUserFlightPreferences
              }
            />
          )}
          {!tripSummariesLoading && !!isInChooseReturnStep && (
            <FlightList
              {...flightListProps}
              useLockPriceLanguage={false}
              isRefundableFaresEnabled={isRefundableFaresActive}
              isThebesHackerFaresV2Cap1ExperimentAvailable={
                isThebesHackerFaresV2Cap1ExperimentAvailable
              }
              stopsOption={stopsOption}
              airCXV3Variant={airCXV3Variant}
              userFlightPreferences={userFlightPreferences}
              userFlightPreferencesNotAvailable={
                userFlightPreferencesNotAvailable
              }
              userHasSetFlightPreferences={userHasSetFlightPreferences}
              shouldApplyUserFlightPreferences={
                shouldApplyUserFlightPreferences
              }
            />
          )}
          {isInReviewStep && (
            <FlightShopReviewItinerary
              isMobile={false}
              useLockPriceLanguage={false}
              useDetailsPrefix={true}
            />
          )}
          {isInCustomizeStep && (
            <FlightCustomize
              isDisruptionProtectionEnabled={isDisruptionProtectionEnabled}
            />
          )}
        </Box>
        {openCalendarDesktop && (
          <DesktopCalendarPicker
            isOpen={openCalendarDesktop}
            closePopup={() => {
              setOpenFlightShopCalendarDesktop(false);
            }}
            setReturnDate={setReturnDate}
            setDepartureDate={setDepartureDate}
            departureDate={departureDate}
            returnDate={returnDate}
            onClickDone={() => {
              populateFlightShopQueryParams({
                history,
                useHistoryPush:
                  flightShopProgress === FlightShopStep.ChooseReturn
                    ? false
                    : true,
                forceQueryUpdate: false,
                newQueryParams: {
                  flightShopProgress: FlightShopStep.ChooseDeparture,
                },
              });
              setChosenOutgoingSlice({
                tripId: "",
                outgoingSliceId: "",
                outgoingFareId: "",
                outgoingFareRating: undefined,
                resetReturnIds: true,
              });
            }}
            tripCategory={tripCategory}
          />
        )}
      </>
    );
  };

  const renderMobileFlightShop = () => {
    return (
      <>
        <Box
          className={clsx("progress-header-menu-wrapper", {
            scrolled: scrollTrigger,
          })}
        >
          <FlightShopProgressHeader
            onFiltersClick={() => setAllFiltersModalOpen(true)}
            onGoBackCustomize={() =>
              setCurrentCustomizeStep((step) => Math.max(step - 1, 0))
            }
            className={
              isInPredictionStep
                ? "mobile-prediction-header"
                : "mobile-shop-header"
            }
            isEditHidden={isPriceFreezeEnabled || isSimilarFlightsEnabled}
            isFlightListOptimizationExperiment={
              isFlightListOptimizationExperiment
            }
            isMultiCityEnabled={isMultiCityEnabled}
            allFiltersModalOpen={allFiltersModalOpen}
            setAllFiltersModalOpen={setAllFiltersModalOpen}
          />
        </Box>
        {!(
          isInReviewStep ||
          isInPredictionStep ||
          isInFareDetailsStep ||
          isInCustomizeStep ||
          isInDisruptionProtectionRebook
        ) &&
          flightList.length > 0 && (
            <Box className="mobile-flight-shop-rewards-account-contents">
              <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
            </Box>
          )}
        <LaunchBanner
          isBusinessLive={!showCorpLaunchBanner}
          onClick={() => {
            trackEvent({
              eventName: "complete_setup_clicked",
              properties: { entry: "traveler_activation_banner" },
            });
          }}
        />
        {travelSalesEventVariant === TRAVEL_SALE_ACTIVE &&
          isInChooseDepartureStep && (
            <TravelSalesEventBanner
              variant="default"
              onClick={() => {
                const path = `${PATH_TRAVEL_SALE}?entryType=air_list_component`;
                matchesMobile
                  ? history.push(path)
                  : window.open(path, "_blank");
              }}
              subtitle={TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE}
              buttonText={TRAVEL_SALES_EVENT_ACTIVE_CTA}
            />
          )}
        {(isInChooseDepartureStep || isInChooseReturnStep) && (
          <>
            {flightList.length > 0 && !isFlightListOptimizationExperiment && (
              <Box className="mobile-flight-shop-search-filters-and-sort-section">
                <FlightShopSearchFilter
                  hideAirportFilter={
                    origin?.id.code.regionType === RegionType.Airport
                  }
                />
                <SortOptionSelection
                  sortOption={sortOption}
                  setSortOption={setSortOption}
                  {...(isInDisruptionProtectionRebook
                    ? { sortOptions: sortOptionsWithoutPrice }
                    : {})}
                />
              </Box>
            )}
            {showTravelWalletBanner && (
              <TravelWalletDetailsBanner
                variant="default"
                screen={SelectedTravelOfferScreen.FLIGHT_SHOP}
              />
            )}
            {shouldApplyUserFlightPreferences && (
              <Box className="flight-preferences-applied-banner-container">
                <UserPreferencesAppliedBanner
                  resetFilters={() => {
                    resetAll(true);
                    setApplyUserFlightPreferences(false);

                    trackEvent({
                      eventName: CLEAR_ALL_FILTERS,
                      properties: addTrackingProperties(
                        expState.trackingProperties
                      ),
                    });
                  }}
                  type={"flight"}
                />
              </Box>
            )}
            {(flightList.length > 0 || hasAppliedFareClassFilter) &&
              !isInDisruptionProtectionRebook && (
                <FlightShopHeader
                  isMobile={true}
                  airports={airports}
                  useLockPriceLanguage={false}
                  history={history}
                />
              )}
            {flightShopType === FlightShopType.DEFAULT &&
              isInChooseDepartureStep &&
              flightList.length > 0 && (
                <Box>
                  <MobilePricePredictionSection
                    filtersOpen={allFiltersModalOpen}
                    setFiltersOpen={setAllFiltersModalOpen}
                    history={history}
                    highlightPriceFreezeBox={false}
                    showFreezeIconPriceFreeze={false}
                    useChevronButtonPriceFreeze={false}
                    useDetailsPrefixPriceFreeze={false}
                    useLockPriceLanguage={false}
                    airCXV3Variant={airCXV3Variant}
                  />
                </Box>
              )}

            {(flightList.length > 0 || hasAppliedFareClassFilter) &&
              !isAirCXV4Experiment && <FareClassOptionSelection />}
            {isInChooseReturnStep && hasActiveRefundableFare && (
              <RefundableFareOptionsBanner />
            )}
            {isInDisruptionProtectionRebook && (
              <>
                <Box className="disruption-protection-rebook-component-wrapper">
                  {isPartiallyRebooking && (
                    <Box
                      className={clsx(
                        "disruption-protection-rebook-component",
                        "partial-rebook-banner"
                      )}
                    >
                      <RebookBanner />
                    </Box>
                  )}
                  {originalSliceFlightSummaryProps.tripSlice.segments.length >
                    0 && (
                    <Box
                      className={clsx(
                        "disruption-protection-rebook-component",
                        "rebook-flight-summary"
                      )}
                    >
                      <GenericFlightSummary
                        {...originalSliceFlightSummaryProps}
                        headerType={
                          isSelectingReturnFlightForRebook
                            ? "original-return"
                            : "original-outbound"
                        }
                        isMobile={true}
                        notification={isPartiallyRebookingNotificationProps}
                        airlineProps={{
                          WN: {
                            hideFlightDetailsSummaryBanner: true,
                          },
                        }}
                      />
                    </Box>
                  )}
                  {disruptionProtectionRefundEntryPoint(history)}
                  <Box
                    className={clsx(
                      "disruption-protection-rebook-component",
                      "rebook-header"
                    )}
                  >
                    <RebookHeader
                      isSelectingReturnFlightForRebook={
                        isSelectingReturnFlightForRebook
                      }
                      departureDate={
                        rebookDepartureDateTime
                          ? getTimeWithUtcOffset(rebookDepartureDateTime)
                          : null
                      }
                    />
                  </Box>
                </Box>
                <Box className="disruption-protection-today-tomorrow-toggle-wrapper">
                  <TodayTomorrowToggle size="medium" />
                </Box>
              </>
            )}
            <FlightList
              {...flightListProps}
              useLockPriceLanguage={false}
              isRefundableFaresEnabled={isRefundableFaresActive}
              airCXV3Variant={airCXV3Variant}
              userFlightPreferences={userFlightPreferences}
              userHasSetFlightPreferences={userHasSetFlightPreferences}
              userFlightPreferencesNotAvailable={
                userFlightPreferencesNotAvailable
              }
              shouldApplyUserFlightPreferences={
                shouldApplyUserFlightPreferences
              }
            />
          </>
        )}
        {isInReviewStep && (
          <>
            {/* note: it doesn't need to render FlightShopHeader in the rebook-variant because the RebookReviewItinerary component includes everything. */}
            {!isInDisruptionProtectionRebook && (
              <FlightShopHeader
                isMobile={true}
                airports={airports}
                useLockPriceLanguage={false}
                history={history}
              />
            )}
            <FlightShopReviewItinerary
              isMobile
              useLockPriceLanguage={false}
              useDetailsPrefix={true}
            />
          </>
        )}
        {isInFareDetailsStep && (
          <MobileFareDetails
            hasActiveRefundableFare={hasActiveRefundableFare}
            isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
          />
        )}
        {isCfarEnabled && isInCustomizeStep && (
          <MobileAddOnCustomize
            goBack={() =>
              isFareDetailsMobileExperiment
                ? setFlightShopProgress(FlightShopStep.ReviewItinerary)
                : setFlightShopProgress(FlightShopStep.FareDetails)
            }
            currentStep={currentCustomizeStep}
            setCurrentStep={setCurrentCustomizeStep}
            isCfarEnabled={isCfarEnabled}
            isChfarEnabled={isChfarEnabled}
            isDisruptionProtectionEnabled={isDisruptionProtectionEnabled}
          />
        )}

        {isGlobalMobileNavExperiment &&
        (isInChooseDepartureStep || isInChooseReturnStep) &&
        !isInDisruptionProtectionRebook ? (
          <>
            <FloatingMenuPill
              items={[
                {
                  label: "Sort & filter",
                  onClick: () => setAllFiltersModalOpen(true),
                  icon: IconName.Settings,
                  badge: activeFiltersCount
                    ? String(activeFiltersCount)
                    : undefined,
                },
              ]}
            />
            <BackToTopButton />
          </>
        ) : undefined}

        {!isGlobalMobileNavExperiment && isTravelWalletCreditsExperiment ? (
          <TravelWalletDrawer
            className={clsx({ hidden: isInReviewStep || isInFareDetailsStep })}
          />
        ) : undefined}
      </>
    );
  };

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.flightsMultiplier &&
    !isCorpTenant(config.TENANT);

  return (
    <>
      <Box
        className={clsx(
          "flight-shop-root",
          {
            mobile: matchesMobile,
          },
          "v2"
        )}
      >
        <Box
          className={clsx("flight-shop-container", {
            "global-mobile-nav": isGlobalMobileNavExperiment,
            list: isInChooseDepartureStep || isInChooseReturnStep,
          })}
        >
          {matchesDesktop && renderDesktopFlightShop()}
          {matchesMobile && renderMobileFlightShop()}
          {(tripSummariesLoading || !isReadyToRenderRefundableFlights) && (
            <B2BLoadingPopup
              open={tripSummariesLoading || !isReadyToRenderRefundableFlights}
              message={SEARCHING_FOR_FLIGHTS}
              secondaryMessage={
                // We do NOT offer price drops for Disruption Protection exercise, so we'll hide the price drop offer message.
                !isInDisruptionProtectionRebook && largestValueAccount ? (
                  showEarnEnhancement
                  ? EARN_ENHANCEMENT_SUBTITLE(
                      largestValueAccount.earn.flightsMultiplier,
                      largestValueAccount.productDisplayName
                    )
                  : largestValueAccount.productDisplayName
                      .toLowerCase()
                      .includes("paradise")
                  ? PARADISE_SUBTITLE
                  : SEARCHING_FOR_FLIGHTS_SECONDARY_MESSAGE

                ) : (
                  undefined
                )
              }
              image={searchImage ?? ""}
              className="flight-search-loading-popup"
              popupSize={matchesMobile ? "mobile" : "desktop"}
            />
          )}
        </Box>
      </Box>
      <CfarDetails
        openCfarDetails={openRefundableFareDetails}
        onClose={() => setOpenRefundableFareDetails(false)}
        onContinue={() => {
          if (onClickRefundableFareBanner) {
            onClickRefundableFareBanner();
          }
          setOpenRefundableFareDetails(false);
        }}
        selectedCfarOffer={selectedRefundableFareOffer}
        selectedChangePolicy={selectedRefundableFareChangePolicy}
        selectedRefundAmount={selectedRefundAmount}
        airlineFTCAmount={airlineFtcAmount}
        cardContentProps={{ hideRadioButtons: true }}
        contentOnly={false}
        modalType="refundable-fare"
        contentClassName="refundable-fare-details-modal"
        topBannerEnabled={true}
      />
    </>
  );
};
