import { put, putResolve, select } from "redux-saga/effects";
import * as H from "history";
import { actions } from "../../actions";
import { fetchTripDetails as fetchDetails } from "../../../../api/v0/shop/fetchTripDetails";
import { getPriceFreeze } from "../../../../api/v0/price-freeze/getPriceFreeze";
import { actions as shopActions } from "../../../shop/actions";
import { disruptionProtectionOfferSelector } from "../../../shop/reducer/selectors";
import { hasActiveRefundableFareInFlightBookSelector } from "../../reducer/selectors";
import { actions as freezeActions } from "../../../freeze/actions";
import { actions as searchActions } from "../../../search/actions";
import {
  getExistingStateVariables,
  IFlightBookParsedQuery,
  IMulticityFlightBookParsedQuery,
  parseQueryString,
} from "../populateBookQueryParamsSaga";
import {
  TripCategory,
  GetPriceFreezeRequestEnum,
  GetPriceFreezeResponse,
  CallState,
  TripDetails,
  FlightBookType,
} from "redmond";
import Logger from "../../../../helpers/Logger";
import { IStoreState } from "../../../../reducers/types";
import { PATH_HOME } from "../../../../utils/urlPaths";
import { ISelectedMulticityTrip } from "../../../shop/reducer";

const isMulticity = (tripCategory: TripCategory) =>
  tripCategory === TripCategory.MULTI_CITY;

const shouldRedirect = ({
  outgoingFareId,
  tripCategory,
  returnFareId,
  tripId,
  tripDetails,
  multicityDepartureFareIds,
  selectedMulticityTrips,
}: {
  tripId: string;
  outgoingFareId?: string;
  returnFareId?: string;
  multicityDepartureFareIds?: string[];
  selectedMulticityTrips?: ISelectedMulticityTrip[];
  tripCategory: TripCategory;
  tripDetails: TripDetails;
}) => {
  if (isMulticity(tripCategory)) {
    const numSearchedRoutes = selectedMulticityTrips?.length;
    const numDepartureFareIds = multicityDepartureFareIds?.length;
    return !tripDetails || !tripId || numSearchedRoutes !== numDepartureFareIds;
  } else {
    return (
      !tripDetails ||
      !outgoingFareId ||
      !tripId ||
      (tripCategory === TripCategory.ROUND_TRIP && !returnFareId)
    );
  }
};
export function* setUpFlightBookParamsSaga({
  history,
  flightBookType,
}: actions.ISetupFlightBookParams) {
  try {
    const state: IStoreState = yield select();

    let { tripDetails, tripCategory, selectedMulticityTrips, outgoingSliceId } =
      getExistingStateVariables(state);

    if (isMulticity(tripCategory)) {
      const parsedMcQueryString = parseQueryString(
        history
      ) as IMulticityFlightBookParsedQuery;

      let {
        tripId,
        tripCategory: currentTripCategory,
        departureFareId0,
        departureFareId1,
        departureFareId2,
        departureFareId3,
        departureFareId4,
        disruptionProtectionPolicyId,
        disruptionProtectionProductId,
      } = parsedMcQueryString;

      const multicityDepartureFareIds = [
        departureFareId0,
        departureFareId1,
        departureFareId2 || "",
        departureFareId3 || "",
        departureFareId4 || "",
      ].filter(Boolean);

      if (tripId) {
        yield putResolve(actions.setFlightBookType(FlightBookType.DEFAULT));

        if (!tripDetails || !tripCategory || tripDetails.id != tripId) {
          tripDetails = yield fetchDetails(tripId);
        }
      }

      yield setUpTripDetails({
        tripId,
        multicityDepartureFareIds,
        selectedMulticityTrips,
        tripCategory: currentTripCategory,
        tripDetails,
        history,
      });

      // note: handle ancillaries (only disruption for multi-city) after tripDetails are set
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_PURCHASE:
          break;
        case FlightBookType.PRICE_FREEZE_EXERCISE:
        case FlightBookType.DEFAULT:
        default: {
          if (disruptionProtectionPolicyId && disruptionProtectionProductId) {
            const disruptionProtectionOffer =
              disruptionProtectionOfferSelector(state);
            const hasActiveRefundableFare =
              hasActiveRefundableFareInFlightBookSelector(state);

            if (
              !disruptionProtectionOffer ||
              disruptionProtectionOffer.length === 0
            ) {
              yield putResolve(
                shopActions.fetchAncillaryOffer({
                  preserveCfarId: hasActiveRefundableFare,
                })
              );
            }

            yield putResolve(
              shopActions.setSelectedDisruptionProtectionId({
                policyId: disruptionProtectionPolicyId,
                productId: disruptionProtectionProductId,
              })
            );
          }
          break;
        }
      }
    } else {
      const parsedOWRTQueryString = parseQueryString(
        history
      ) as IFlightBookParsedQuery;

      let {
        priceFreezeId,
        tripId,
        outgoingFareId,
        returnFareId,
        tripCategory: currentTripCategory,
        priceDropCandidateId,
        cfarPolicyId,
        cfarQuoteId,
        cfarProductId,
        cfarChoice,
        cfarFareChoice,
        isRefundableFare: hasSelectedRefundableFare,
        disruptionProtectionPolicyId,
        disruptionProtectionProductId,
        disruptionProtectionQuoteId,
      } = parsedOWRTQueryString;

      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_PURCHASE: {
          yield putResolve(
            actions.setFlightBookType(FlightBookType.PRICE_FREEZE_PURCHASE)
          );

          if (
            tripId &&
            (!tripDetails || !tripCategory || tripDetails.id != tripId)
          ) {
            tripDetails = yield fetchDetails(tripId);
          }
          if (priceDropCandidateId) {
            yield put(actions.fetchPriceDropEligibility(priceDropCandidateId));
          }
          break;
        }
        case FlightBookType.PRICE_FREEZE_EXERCISE:
        case FlightBookType.DEFAULT:
        default: {
          if (tripId) {
            yield putResolve(actions.setFlightBookType(FlightBookType.DEFAULT));
            yield put(
              shopActions.updateRefundableFaresProperties({
                cfar_choice: cfarChoice,
                cfar_fare_choice: cfarFareChoice,
              })
            );

            if (!tripDetails || !tripCategory || tripDetails.id != tripId) {
              tripDetails = yield fetchDetails(tripId);
            }
            if (priceDropCandidateId) {
              yield put(
                actions.fetchPriceDropEligibility(priceDropCandidateId)
              );
            }
          } else if (priceFreezeId) {
            yield putResolve(
              actions.setFlightBookType(FlightBookType.PRICE_FREEZE_EXERCISE)
            );

            const priceFreeze: GetPriceFreezeResponse = yield getPriceFreeze({
              GetPriceFreezeRequest: GetPriceFreezeRequestEnum.ByPriceFreezeId,
              id: priceFreezeId,
            });

            const { tripDetails: priceFreezeTripDetails, frozenFare } =
              priceFreeze.priceFreeze;
            const { slices, id } = priceFreezeTripDetails;

            tripDetails = priceFreezeTripDetails;
            currentTripCategory =
              slices.length > 1
                ? TripCategory.ROUND_TRIP
                : TripCategory.ONE_WAY;

            yield putResolve(
              searchActions.setTripCategory(currentTripCategory, true)
            );

            tripId = id;
            outgoingFareId = frozenFare.id;
            if (slices.length > 1) {
              returnFareId = frozenFare.id;
            }

            yield putResolve(
              freezeActions.setPriceFreeze({
                priceFreeze,
                priceFreezeCallState: CallState.Success,
              })
            );
          }
          break;
        }
      }

      yield setUpTripDetails({
        tripId,
        outgoingFareId,
        outgoingSliceId: outgoingSliceId || undefined,
        returnFareId,
        tripCategory: currentTripCategory,
        tripDetails,
        history,
      });

      // note: handle ancillaries after tripDetails are set
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_PURCHASE:
          break;
        case FlightBookType.PRICE_FREEZE_EXERCISE:
        case FlightBookType.DEFAULT:
        default: {
          if (disruptionProtectionPolicyId && disruptionProtectionProductId) {
            const disruptionProtectionOffer =
              disruptionProtectionOfferSelector(state);
            const hasActiveRefundableFare =
              hasActiveRefundableFareInFlightBookSelector(state);

            if (
              !disruptionProtectionOffer ||
              disruptionProtectionOffer.length === 0
            ) {
              yield putResolve(
                shopActions.fetchAncillaryOffer({
                  isPriceFreezeOverview:
                    flightBookType === FlightBookType.PRICE_FREEZE_EXERCISE,
                  preserveCfarId: hasActiveRefundableFare,
                })
              );
            }

            yield putResolve(
              shopActions.setSelectedDisruptionProtectionId({
                policyId: disruptionProtectionPolicyId,
                productId: disruptionProtectionProductId,
                quoteId: disruptionProtectionQuoteId,
              })
            );
          }
          if (cfarPolicyId && cfarProductId) {
            yield putResolve(
              shopActions.setSelectedCfarId({
                policyId: cfarPolicyId,
                productId: cfarProductId,
                quoteId: cfarQuoteId,
              })
            );

            if (
              hasSelectedRefundableFare &&
              (flightBookType === FlightBookType.DEFAULT ||
                flightBookType === undefined)
            ) {
              yield putResolve(shopActions.setHasSelectedRefundableFare(true));
            }
          }
          break;
        }
      }
    }
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setUpFlightBookParamsFailed());
  }
}

function* setUpTripDetails(args: {
  tripId: string;
  outgoingFareId?: string;
  outgoingSliceId?: string;
  returnFareId?: string;
  multicityDepartureFareIds?: string[];
  selectedMulticityTrips?: ISelectedMulticityTrip[];
  tripCategory: TripCategory;
  tripDetails: TripDetails;
  history: H.History;
}) {
  const {
    tripId,
    outgoingFareId,
    outgoingSliceId,
    returnFareId,
    multicityDepartureFareIds,
    selectedMulticityTrips,
    tripCategory,
    tripDetails,
    history,
  } = args;

  // If we are missing the data we need from both the state and the query params
  // we should redirect the user home.
  if (
    shouldRedirect({
      tripId,
      outgoingFareId,
      returnFareId,
      multicityDepartureFareIds,
      selectedMulticityTrips,
      tripCategory,
      tripDetails,
    })
  ) {
    return history.push(PATH_HOME);
  }

  // Trip Details must be populated last as the UI waits to display until trip is set
  if (isMulticity(tripCategory)) {
    for (const [idx, mcTrip] of selectedMulticityTrips!.entries()) {
      const { tripId, departureSliceId, departureFareId, departureFareRating } =
        mcTrip;
      if (!!tripId && !!departureSliceId && !!departureFareId) {
        yield putResolve(
          shopActions.setChosenDepartureSlice({
            departureSliceId: tripDetails.slices[0].id,
            departureFareId: departureFareId!,
            departureFareRating,
            departureSliceIndex: idx,
            tripId,
          })
        );
      }
    }
  } else {
    if (!outgoingSliceId) {
      yield putResolve(
        shopActions.setChosenOutgoingSlice({
          outgoingSliceId: tripDetails.slices[0].id,
          outgoingFareId: outgoingFareId!,
          outgoingFareRating: undefined,
          tripId,
        })
      );
    }
    if (tripCategory === TripCategory.ROUND_TRIP) {
      yield putResolve(
        shopActions.setChosenReturnSlice({
          returnSliceId: tripDetails.slices[1].id,
          returnFareId: returnFareId!,
          returnFareRating: tripDetails.fareDetails.find(
            (f) => f.id === returnFareId
          )?.slices[1].fareShelf?.rating,
          tripId,
        })
      );
    }
  }

  yield put(shopActions.setTripDetails(tripDetails));
  yield put(actions.setUpFlightBookParamsSuccess());
}
